import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { orderActionCreators } from '../store/orders/actionCreators';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { UserState } from '../store/users';
import { OrderState } from '../store/orders';
import { OrganisationState } from '../store/organisation';
import { Loading } from '../components/loading';
import { TaskItem } from '../models/inProgressOrder';
import { NoList } from '../components/nolist';
import { TaskCheckbox } from '../components/newtask/taskCheckbox';
import { TaskSettings } from '../models/organisation';

const SevenDays = 1000 * 60 * 60 * 24 * 7;

class OrderTaskPage extends React.Component<PageProps, PageState> {
    render() {
        return (
            <div className='order-task page'>
                {(!this.state.initialLoading && this.props.orgStore.organisation && !this.props.orgStore.loadingOrganisation) ? this.props.orgStore.organisation.taskSettings?. length > 0 ?
                    !this.state.loading ?
                    <div className='fluid-container form'>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <h2>Please select required task</h2>
                                <h5>Select all required tasks you would like your recipients to complete.</h5>
                            </div>
                            <div className='col-xs-12' style={{ margin: '0 0 30px 0' }}></div>
                            {this.props.orgStore.organisation.taskSettings
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .sort((a, b) => {
                                    // Sort the coming soon things to the bottom
                                    if (a.type === b.type && a.status === b.status)
                                        return 0;
                                    if (a.type === 'ComingSoon' || a.status === 'Pending')
                                        return 1;
                                    if (b.type === 'ComingSoon' || b.status === 'Pending')
                                        return -1;
                                    return 0;
                                })
                                .map(t => {
                                    let comingSoon = t.type === 'ComingSoon';
                                    const pending = t.status === 'Pending';
                                    const isNew = Date.parse(t.createdUtc) + SevenDays > Date.now(); // less than 7 days
                                    return <div
                                        style={{marginBottom: '10px'}}
                                        className='col-xs-12'
                                        key={`task-checkbox-${t.id}`}>
                                        <TaskCheckbox
                                            title={t.name}
                                            price={t.price}
                                            checked={this.state.taskItems.find(ti => ti.id === t.id)?.checked ?? false}
                                            onChange={() => this.handleTaskSelect(t)}
                                            tooltip={t.htmlDescription}
                                            isNew={isNew}
                                            disabledText={comingSoon ? 'Coming Soon' : 'Pending Approval'}
                                            disabled={comingSoon || pending}
                                        />
                                    </div>
                                })
                            }
                            <div className='col-xs-12'>
                                <a href="/store" className='add-more'><img src="/images/icon-add.svg" alt="" /> Add more</a>
                            </div>
                            <div className='col-xs-12' style={{ marginTop: '10px', color: '#707783', textAlign: 'right' }}>
                                <small>*Prices exclude GST</small>
                            </div>
                            <div className='col-xs-12' style={{ margin: '10px 0 10px 0' }}>
                                <hr />
                            </div>
                            <div className='col-xs-12'>
                                {this.state.errors.map(e => {
                                    return <p key={e} className='form-error-message'>{e}</p>
                                })}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-offset-9 col-xs-3' style={{ marginTop: '10px' }}>
                                <button
                                    className='button informational'
                                    disabled={this.state.taskItems.filter(x => x.checked).length === 0}
                                    style={{ width: '100%' }}
                                    onClick={this.nextSingleClick}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </div> : <Loading text='Submitting order' />
                :
                <NoList
                    title='You haven’t added any tasks yet'
                    description='Start collecting data now by heading to the Smart Task Store and selecting which smart tasks you would like to use.'
                    link='/store'
                    linkText='Setup Smart Tasks'
                    linkColor='setup'
                    ></NoList>
                 : <Loading text='Loading...' />}
            </div>
        );
    }

    constructor(props: PageProps) {
        super(props);

        let order = this.props.orderStore.inProgressOrder;

        this.state = {
            taskItems: [],
            asset: {
                rego: '',
                description: ''
            },
            initialLoading: true,
            assetIdValid: true,
            assetDescValid: true,
            errors: [],
            loading: false,
            reason: order ? order.reason : '',
            showVehicle: true
        };

        // Bindings
        this.handleTaskSelect = this.handleTaskSelect.bind(this);
        this.nextSingleClick = this.nextSingleClick.bind(this);
        this.assetChange = this.assetChange.bind(this);
    }

    handleTaskSelect(t: TaskSettings) {
        // TODO: there's mutation here, needs fixing, and the state structure could be better
        const checkedState = this.state.taskItems;
        const temp = checkedState.filter(cs => cs.id === t.id)[0];
        temp.checked = !temp.checked;
        const showVehicle = checkedState.filter(cs => cs.type === 'Car' && cs.checked).length > 0;

        this.setState({
            taskItems: checkedState,
            showVehicle: showVehicle
        });
    }

    assetChange(id: string, desc: string) {
        this.setState({
            asset: {
                rego: id,
                description: desc
            }
        });
    }

    nextSingleClick() {
        this.progressForm(false);
    }

    progressForm(multiple: boolean) {
        let taskItems = this.state.taskItems.filter(ti => ti.checked);

        this.props.orderActions.saveTaskListAndProgress(taskItems, multiple);
    }

    componentDidMount() {
        this.props.orgActions.requestOrganisation();
    }

    static getDerivedStateFromProps(nextProps: PageProps, prevState: PageState) {
        const state = {...prevState};

        // If we got the org, but haven't used it to setup the tasks yet
        if (nextProps.orgStore.organisation && (!prevState.taskItems || prevState.taskItems.length === 0)) {
            state.taskItems = nextProps.orgStore.organisation.taskSettings.map(ts => {
                return {
                    id: ts.id,
                    surveyId: ts.surveyId,
                    type: ts.type,
                    price: ts.price,
                    description: ts.name,
                    descriptionText: ts.descriptionText || `${ts.name} description`,
                    taskDescriptionFields: ts.taskDescriptionFields,
                    checked: ts.type !== 'ComingSoon' ?
                        nextProps.orderStore.inProgressOrder ?
                            nextProps.orderStore.inProgressOrder.taskItems
                                .filter(ti => ti.id === ts.id).length > 0 :
                            false :
                        false
                };
            });
            state.initialLoading = false;
        }

        return state;
    }
}

interface PageProps {
    userStore: UserState;
    userActions: typeof userActionCreators;
    orderStore: OrderState;
    orderActions: typeof orderActionCreators;
    orgStore: OrganisationState;
    orgActions: typeof organisationActionCreators;
}

interface PageState {
    taskItems: (TaskItem & {
        checked: boolean;
    })[];
    asset: {
        rego: string;
        description: string;
    };
    assetIdValid: boolean;
    assetDescValid: boolean;
    errors: string[];
    loading: boolean;
    initialLoading: boolean;
    reason: string;
    showVehicle: boolean;
}

export default connect(
    (state: ApplicationState) => ({
        userStore: state.userState,
        orderStore: state.orderState,
        orgStore: state.orgState
    }),
    (dispatch: Dispatch) => {
        return {
            userActions: bindActionCreators(userActionCreators, dispatch),
            orderActions: bindActionCreators(orderActionCreators, dispatch),
            orgActions: bindActionCreators(organisationActionCreators, dispatch)
        };
    }
)(OrderTaskPage);
