import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import { getMarketplaceTaskDetails, addMarketplaceTask, removeMarketplaceTask } from '../store/marketplace';
import styles from './marketplaceTaskDetails.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Toast } from '../components/toast';
import { PriceTile } from '../components/marketplace/priceTile';
import { TaskTile } from '../components/marketplace/taskTile';
import { _reportHost } from '../settings';
import { usePrevious } from '../helpers/hooks';
import { modalActionCreators } from '../store/modal';
import { RemoveTaskModal } from '../components/marketplace/removeTaskModal';
import { getMarketplaceTaskSuggestions } from '../store/marketplace';
import { NccTermsModal } from '../components/marketplace/nccTermsModal';

export const MarketplaceTaskDetailsPage: React.FC<RouteComponentProps<RouteProps>> = ({ match }) => {
    const taskId = parseInt(match.params.taskId);
    const task = useSelector((state: ApplicationState) => state.marketplaceState.taskDetails);

    const dispatch = useDispatch();

    useEffect(() => {
        if (Number.isInteger(taskId)) {
            dispatch(getMarketplaceTaskDetails(taskId));
        } else {
            dispatch(push('/404'));
        }
    }, [dispatch, taskId]);

    const suggestedTasks = useSelector((state: ApplicationState) => state.marketplaceState.taskSuggestions).slice(0, 3);
    useEffect(() => {
        dispatch(getMarketplaceTaskSuggestions(taskId));
    }, [dispatch, taskId]);

    const onBack = () => dispatch(push('/store'));

    const handleAdd = () => {
        if (!task) return;
        if (task.externalTermsType)  {
            switch (task.externalTermsType) {
                case 'NCC':
                    dispatch(modalActionCreators.showModal(<NccTermsModal
                        onConfirm={() => {
                            try {
                                dispatch(modalActionCreators.hideModal());
                                dispatch(addMarketplaceTask(taskId));
                            }
                            catch (err) {
                                console.log("err", err);
                            }
                        } }
                        taskId = {taskId}
                        />, 'Accept the Terms & Conditions'));
                    break;
            }
        }
        else
            dispatch(addMarketplaceTask(taskId));
    };
    const handleRemove = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(modalActionCreators.showModal(
            <RemoveTaskModal onConfirm={() => dispatch(removeMarketplaceTask(taskId))} />,
            'Are you sure you want to remove this task?'
        ));
    };

    const [notification, setNotification] = useState<JSX.Element | null>(null);

    const prevTask = usePrevious(task);
    useEffect(() => {
        // condition for showing:
        // - previous task is not null
        // - previous task does not have `addedUtc`
        // - current task does have `addedUtc`
        if (prevTask && !prevTask?.addedUtc && task?.addedUtc) {
            setNotification(<span>"<strong>{task.name}</strong>" has been added to your task list</span>);
        }
        if (prevTask && prevTask?.addedUtc && !task?.addedUtc) {
            setNotification(<span>"<strong>{task!.name}</strong>" has been removed from your task list</span>);
        }
    }, [prevTask, task]);

    const handleAddSuggestion = (name: string) =>
        setNotification(<span>"<strong>{name}</strong>" has been added to your task list</span>);
    const handleRemoveSuggestion = (name: string) =>
        setNotification(<span>"<strong>{name}</strong>" has been removed from your task list</span>);

    if (!task) {
        return <div>Loading...</div>;
    }

    const sampleReportUrl = `${_reportHost}/report/${task.sampleReportGuid}/Sample ${task.defaultReportName}`;

    return (
        <>
            <Toast message={notification} onDismiss={() => setNotification(null)} />
            <div className={styles.page}>
                <div className={styles.nav}>
                    <button onClick={onBack}>← Back</button>
                </div>

                <div className={styles.content}>
                    <div className={styles.details}>
                        <h1>{task.name}</h1>
                        <p>{task.description}</p>
                        <a
                            className={styles.sampleBtn}
                            href={sampleReportUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src='/images/icon_report.svg' alt="" />
                            View sample report
                        </a>
                        {!!task.features && task.features.length > 0 && (
                            <>
                                <hr />
                                <h2>Smart features:</h2>
                                <ul className={styles.features}>
                                    {task.features.map(feature => (
                                        <li key={feature.text}>
                                            <img src={feature.iconUrl} alt="" />
                                            <div className={styles.feature}>
                                                <span className={styles.featureGroup}>{feature.groupName}</span>
                                                <span className={styles.featureText}>{feature.text}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {!!task.includes && task.includes.length > 0 && (
                            <>
                                <hr />
                                <h2>Information captured:</h2>
                                {task.includes && (
                                    <ul className={styles.includes}>
                                        {task.includes.map(item => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                )}
                            </>
                        )}
                    </div>

                    <div className={styles.aside}>
                        {task.addedUtc ? (
                            <div className={styles.removeTile}>
                                <button onClick={handleRemove}>Remove task</button>
                                <span>Added on {new Date(Date.parse(task.addedUtc)).toLocaleDateString()}</span>
                            </div>
                        ) : (
                            <PriceTile priceToAdd={task.priceToAdd} pricePerUse={task.pricePerUse} onAdd={handleAdd} />
                        )}
                        <div className={styles.sampleBlock}>
                            <iframe title={`Sample ${task.defaultReportName}`} className={styles.sampleReport} src={sampleReportUrl} />
                            <a className={styles.sampleReportButton} href={sampleReportUrl} target="_blank" rel="noopener noreferrer">
                                <img src='/images/icon_report.svg' alt="" />
                                View sample report
                            </a>
                        </div>
                    </div>
                </div>

                <div className={styles.suggestions}>
                    <h2>People added this also added:</h2>
                    <div className={styles.tasks}>
                        {suggestedTasks.map(task => (
                            <div key={task.id}>
                                <TaskTile
                                    task={task}
                                    fixedSize
                                    onAdd={() => handleAddSuggestion(task.name)}
                                    onRemove={() => handleRemoveSuggestion(task.name)}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.stickyFooter}>
                    {task.addedUtc ? (
                        <div className={styles.removeTile}>
                            <button onClick={handleRemove}>Remove task</button>
                            <span>Added on {new Date(Date.parse(task.addedUtc)).toLocaleDateString()}</span>
                        </div>
                    ) : (
                        <PriceTile priceToAdd={task.priceToAdd} pricePerUse={task.pricePerUse} onAdd={handleAdd} borderless />
                    )}
                </div>
            </div>
        </>
    );
}

interface RouteProps {
    taskId: string;
}
