import * as React from 'react';
import { Order } from '../../models/order';
import { getNiceDateString, convertDate, convertStringToDate } from '../../helpers/dates';
import { connect, useSelector } from 'react-redux';
import { modalActionCreators } from '../../store/modal';
import { Dispatch, bindActionCreators } from 'redux';
import { orderActionCreators } from '../../store/orders/actionCreators';
import { Loading } from '../loading';
import { Link } from 'react-router-dom';
import { Tooltip } from '../tooltip';
import { ShareReportModal } from './shareReportModal';
import { ActionResultModal } from './actionResultModal';
import backArrow from '../../assets/back-arrow-sm.svg';
import chevronDown from '../../assets/chevron-down.svg';
import { ApplicationState } from '../../store';
import { unique } from '../../helpers/array';

const OrderRow: React.FC<RowProps> = (props) => {
    const [active, setActive] = React.useState(false);
    const dateRequested = getNiceDateString(convertDate(props.order.createdUtc));
    const reportCount = props.order.tasks.length;
    const doneCount = props.order.tasks.filter(t => !!t.reportUrl).length;
    const readyCount = props.order.tasks.filter(t => !!t.reportUrl && !t.isExpired).length;
    let status = props.order.status === 'Confirmed' ? 'Sent' : props.order.status;
    const owner = props.order.recipient;
    const organisation = useSelector((state: ApplicationState) => state.orgState.organisation);
    const organisationFilter = organisation?.keyValueSettings?.filter(x => x.key.toLocaleLowerCase() === 'financeurl');
    const organisationUrl = organisationFilter && organisationFilter.length > 0 ? organisationFilter[0].value : '';
    const [showMore, setShowMore] = React.useState(false); // mobile tooltip
    const tags = props.order.tasks.filter(t => !!t.taskTags.length).length;
    const summedProgress = props.order.tasks
        .map(t => {
            if (t.reportUrl)
                return 1;
            
            if (!t.taskSurveyItems || t.taskSurveyItems.length <= 0)
                return 0;
            
            return t.taskSurveyItems.filter(tsi => tsi.completed).length / t.taskSurveyItems.length;
        })
        .reduce((total, val) => total + val);

    if (status === 'Sent' && summedProgress > 0) {
        status = 'InProgress'
    }
    const statusClass = status.toLowerCase().replace(' ', '');

    const showNotifyModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.modalActions.showModal(<NotifyModal {...props} />, 'Send reminder');
    };

    const showCancelModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.modalActions.showModal(<CancelModal {...props} />, 'Do you want to cancel this request?');
    };

    const showEditModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.modalActions.showModal(<EditContactModal {...props} />, 'Edit contact details');
    };

    const showReIssueTaskModal = (e: React.MouseEvent, taskId: number) => {
        e.stopPropagation();
        props.modalActions.showModal(<ReIssueTaskModal {...props} taskId={taskId} />, 'Reissue task');
    };

    const showArchiveModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.modalActions.showModal(<ArchiveModal {...props} />, 'Do you want to archive this task?');
    };

    const showShareModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.modalActions.showModal(<ShareReportModal order={props.order} />, 'Report sharing');
    };

    const openReport = (url: string) => {
        var split = url.split('/');
        if (split && split[4]) {
            var guid = split[4].split('?');
            if (guid && guid.length > 0)
                window.open("/report/" + guid[0]);
        }
    };

    return (
        <div className="order-row-media-wrapper">
            {/* MOBILE */}
            <div className='order-row-sm'>
                <div className='order-row-sm-header'>
                    <div className={`order-row-sm-dot ${statusClass}`} />
                    <div className={`order-row-sm-status ${statusClass}`}>
                        {status === 'Sent' ?
                            'Sent to customer' :
                            status === 'InProgress' ?
                                `In progress` :
                                `${status} (${doneCount} / ${reportCount})`
                        }
                    </div>
                </div>
                <div className='order-row-sm-info'>
                    <h3>{owner && owner.name}</h3>
                    <div className='info-contact'>
                        <div>Email: <span>{owner && owner.email}</span></div>
                        <div>Contact number: <span>{owner && owner.phone}</span></div>
                    </div>
                    <div className='info-ref'>
                        <div>Reference: <span>{props.order.externalReference || props.order.referenceId}</span></div>
                        <div>Requested {dateRequested} by {props.order.createdByName}</div>
                    </div>
                </div>
                <div className='order-row-sm-actions'>
                    <button className='left-action-btn' onClick={() => window.open(organisationUrl)}>
                        <img src='/images/inspector.svg' alt='' />
                    </button>
                    <div className='right-actions'>
                        <button className='right-action-btn' onClick={showNotifyModal}>
                            <img src='/images/icon-reminder.svg' alt='Notify' />
                        </button>
                        <button className='right-action-btn' onClick={() => setShowMore(true)}>
                            <img src='/images/dots.svg' alt='More' />
                        </button>
                        <Tooltip
                            closeCallback={() => setShowMore(false)}
                            top={false}
                            active={showMore}
                            align='left'
                            light
                        >
                            <div className='tooltip-action-list'>
                                {status === 'Completed' ? <>
                                    {readyCount > 0 ? <button className='link-button' onClick={showShareModal}>Share Report</button> : null}
                                    <button className='link-button' onClick={showArchiveModal}>Archive</button>
                                </> : <>
                                    <button className='link-button' onClick={showEditModal}>Edit</button>
                                    {status !== 'InProgress' && <button className='link-button' onClick={showCancelModal}>Cancel</button> }
                                </>
                                }
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className='order-row-sm-details'>
                    <div className='show-more'>
                        {active ?
                            <button onClick={() => setActive(false)}>Hide details <img alt="" src='/images/up.svg' /></button> :
                            <button onClick={() => setActive(true)}>See details <img alt="" src={chevronDown} /></button>
                        }
                    </div>
                    {active ?
                        <div className='details-list'>
                            {props.order.tasks.map(s => {
                                const completed = !!s.reportUrl;
                                const expiryDate = getNiceDateString(convertStringToDate(s.reportExpiryUtc || ''));
                                return <button
                                    className={`list-item ${s.taskTags.length ? 'tag' : ''}`}
                                    key={s.id}
                                    disabled={!completed || s.isExpired}
                                    onClick={() => {
                                        if (s.reportUrl)
                                            openReport(`${s.reportUrl}`);
                                    }}>
                                    <div className={`order-row-sm-dot ${completed ? 'completed' : ''}`} />
                                    <div>
                                        <div className='description'>
                                            {`${s.name}`} {s.reportExpiryUtc ?
                                                s.isExpired
                                                ? `(expired on ${expiryDate})`
                                                : `(expires on ${expiryDate})`
                                                : ''}
                                            {s.fullDescription && <div className='fullDescription'>{s.fullDescription}</div>}
                                        </div>
                                        {s.taskTags
                                            .map(t => t.outcome)
                                            .filter(unique)
                                            .map(outcome => {
                                            return <div key={outcome} className='tagdescription'>{outcome}</div>
                                        })}
                                    </div>
                                    {
                                        completed && !s.isExpired ?
                                        <div className='report-block'>
                                            <div
                                                className='reissue-link'
                                                onClick={(e) => showReIssueTaskModal(e, s.id)}>
                                                Re-issue
                                            </div>
                                            <div className='open-report'>
                                                <img src={backArrow} alt='Open report' />
                                            </div>
                                        </div>: ''
                                    }
                                </button>
                            })}
                        </div>
                        : null
                    }
                </div>
                {tags ? <img src='/images/icon-warning.svg' alt='Warning' className='order-row-attention'/> : ''}
            </div>
    {/* DESKTOP */}
    <div
        className={`order-row ${active ? 'active' : ''} ${statusClass}`}>
        <div className='order-row-info'>
            <h4 className={statusClass}>Reference: <span>{props.order.externalReference || props.order.referenceId}</span></h4>
            <div className='info-items'>
                <div className='info-date'>
                    Requested {dateRequested} by {props.order.createdByName}
                </div>
            </div>
        </div>
        <div className='order-row-info'>
            <div className='info-items'>
                <div className='info-item name'>
                    <span>{owner && owner.name}</span>
                </div>
                <div className='info-item email'>
                    Email: <span>{owner && owner.email}</span>
                </div>
                <div className='info-item'>
                    Contact number: <span>{owner && owner.phone}</span>
                </div>
            </div>
            <div className={`order-row-status ${statusClass}`}>
                {status === 'Sent' ?
                    'Sent to customer' :
                    status === 'InProgress' ?
                        `In progress` :
                        `${status} (${doneCount} / ${reportCount})`
                }
            </div>
        </div>
        <div className='order-row-separator'></div>
        <div
            onClick={() => setActive(!active)}
            className={`order-row-actions ${active ? 'active' : ''} ${statusClass}`}>
            <div className='primary-actions'>
                {status === 'Archived' ?
                    <Link className='button default row-button' to='/new-task' onClick={() => {
                        props.orderActions.startNewOrder();
                    }}>
                        Re-Order This Task
                        </Link> : organisationUrl !== '' ?
                        <button className='button row-button image-caption-button' onClick={() => window.open(organisationUrl)}>
                            <img src='/images/inspector.svg' alt='' />
                            Book Inspection
                        </button> : ''
                }
            </div>
            <div className='secondary-actions'>
                {status !== 'Archived' ? <>
                    {status !== 'Completed' ?
                        <>
                            <button className='icon-button' onClick={showNotifyModal}>
                                <div className="tooltip">
                                    <img src='/images/icon-reminder.svg' alt='Notify' />
                                    <span className="tooltiptext">Notify</span>
                                </div>
                            </button>
                            <button className='icon-button' onClick={showEditModal}>
                                <div className="tooltip">
                                    <img src='/images/icon-edit.svg' alt='Edit' />
                                    <span className="tooltiptext">Edit</span>
                                </div>
                            </button>
                            {status !== 'InProgress' && <button className='icon-button' onClick={showCancelModal}>
                                <div className="tooltip">
                                    <img src='/images/icon-cancel.svg' alt='Cancel' />
                                    <span className="tooltiptext">Cancel</span>
                                </div>
                            </button> }
                        </> :
                        <button className='icon-button' onClick={showArchiveModal}>
                            <div className="tooltip">
                                <img src='/images/icon-archive.svg' alt='Archive' />
                                <span className="tooltiptext">Archive</span>
                            </div>
                        </button>
                    }
                </> : ''}
                {readyCount > 0 &&
                    <button className='icon-button' onClick={showShareModal}>
                        <div className="tooltip">
                            <img src='/images/icon-share.svg' alt='Share Reports' />
                            <span className="tooltiptext">Share</span>
                        </div>
                    </button>
                }
                {active ?
                    <button className='text-button'>Hide details <img alt="" src='/images/up.svg' /></button> :
                    <button className='text-button'>See details <img alt="" src={chevronDown} /></button>
                }
            </div>
        </div>
        {active ? <div className='subject-list' onClick={(e) => e.stopPropagation()}>
            {props.order.tasks.map(s => {
                let progress = 0;
                if (s.reportUrl)
                    progress = 1;
                else
                    progress = s.taskSurveyItems.filter(tsi => tsi.completed).length / s.taskSurveyItems.length;
                
                progress = Math.round(progress * 100);

                let reportStatus = s.reportUrl ? 'completed' : progress > 0 ? 'inprogress' : 'notcompleted';

                return <div className={`subject-item ${s.reportUrl ? '' : 'inactive'} ${s.taskTags.length ? 'tag' : ''}`} key={s.id}>
                    <div>
                        <div className='description'>
                            <i className={`${reportStatus}`} />
                            <span>
                            {s.name} {s.reportExpiryUtc ?
                                <>
                                    {s.isExpired ? '' : `(expires on  ${getNiceDateString(convertStringToDate(s.reportExpiryUtc || ''))})`}
                                </>
                                : ''}
                                {s.fullDescription && <div className='fullDescription'>{s.fullDescription}</div>}
                            </span>
                        </div>
                        {s.taskTags
                            .map(t => t.outcome)
                            .filter(unique)
                            .map(outcome => {
                            return <div key={outcome} className='tagdescription'>{outcome}</div>
                        })}
                    </div>
                    {
                        reportStatus === 'completed' && !s.isExpired ?
                        <div className='report-block'>
                            <div className='reissue-link'  onClick={(e) => showReIssueTaskModal(e, s.id) }>Reissue</div>
                            <button
                                className='button boring'
                                disabled={!s.reportUrl}
                                onClick={() => {
                                    if (s.reportUrl)
                                        openReport(`${s.reportUrl}`);
                                }}>
                                View report
                            </button>
                        </div> :
                            reportStatus === 'completed' && s.isExpired ?
                                <span>Expired on <strong>{getNiceDateString(convertStringToDate(s.reportExpiryUtc || ''))}</strong></span> :
                                <span className='subject-item'>{
                                    s.submissionGuid && s.name?.toUpperCase() == 'IDENTITY VERIFICATION' ? 'Awaiting ID provider' : //survey id for NCC
                                    s.submissionGuid ? 'Generating' : (reportStatus == 'inprogress' ? 'In progress' : 'Sent to customer')}</span>
                    }
                </div>
            })}
        </div> : ''}
        {tags ? <img src='/images/icon-warning.svg' alt='Warning' className='order-row-attention'/> : ''}
    </div>

        </div>
    );
}

const ArchiveModal: React.FC<RowProps> = (props) => {
    return <>
        <p>
            This task will be archived, all reports and sensitive
            information will be deleted once report expired.
        </p>
        <p>
            Task information will still be available, you will be able
            to find task under Archived.
        </p>
        <footer>
            <button className='button boring' onClick={() => props.modalActions.hideModal()}>Cancel</button>
            <button className='button default' onClick={() => {
                props.orderActions.archiveOrder(
                    props.order.id,
                    props.filter ? props.filter : '',
                    props.offset,
                    props.pageSize,
                    props.search ? props.search : '',
                    props.sort);
                props.modalActions.hideModal();
            }}>Confirm</button>
        </footer>
    </>;
}

const CancelModal: React.FC<RowProps> = (props) => {
    return <>
        <p>
            Once your request has been cancelled, it cannot be undone.
        </p>
        {props.order.status === 'Confirmed' ? '' : <p>
            No refund will be issued for this request.
        </p>}
        <footer>
            <button className='button boring' onClick={() => props.modalActions.hideModal()}>No</button>
            <button className='button cancel' onClick={() => {
                props.orderActions.cancelOrder(
                    props.order.id,
                    props.filter ? props.filter : '',
                    props.offset,
                    props.pageSize,
                    props.search ? props.search : '',
                    props.sort);
                props.modalActions.hideModal();
            }}>Yes</button>
        </footer>
    </>
}

const NotifyModal: React.FC<RowProps> = (props) => {
    const owner = props.order.recipient;
    const [sending, setSending] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [error, setError] = React.useState(false);

    if (error) {
        return <ActionResultModal
            type="failure"
            message="Something went wrong, please try again later."
            onConfirm={() => props.modalActions.hideModal()}
        />;
    }

    if (done) {
        return <ActionResultModal
            type="success"
            message="Your invitation has been sent successfully."
            onConfirm={() => props.modalActions.hideModal()}
        />;
    }
    else {
        return <>
            {sending ? <Loading text='Sending...' /> :
                <div className='form modal-form'>
                    <p>Are you sure you want to send <strong>{owner.email}</strong> a reminder to complete the following?</p>
                    <ul>
                        {props.order.tasks
                          .filter(task => !task.reportGuid)
                          .map(task => <li>{task.name}</li>)
                        }
                    </ul>
                </div>}
            <footer>
                <button className='button boring' onClick={() => props.modalActions.hideModal()}>Cancel</button>
                <button className='button default' onClick={() => {
                    setSending(true);
                    if (owner.email) {
                        props.orderActions.notifyUser(
                            props.order.id,
                            owner.name,
                            owner.email,
                            'This is a friendly reminder that you still have outstanding tasks to complete.',
                            () => {
                                setSending(false);
                                setDone(true);
                                setTimeout(() => {
                                    props.modalActions.hideModal();
                                }, 1500);
                            },
                            () => {
                                setSending(false);
                                setError(true);
                            });
                    }
                    else
                        setSending(false);
                }}>
                    Send
            </button>
            </footer>
        </>
    }
}

const EditContactModal: React.FC<RowProps> = (props) => {
    const owner = props.order.recipient;
    const [editing, setEditing] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [name, setName] = React.useState(owner ? owner.name : '');
    const [email, setEmail] = React.useState(owner ? owner.email : '');
    const [phone, setPhone] = React.useState(owner ? owner.phone : '');

    const handleUndo = () => {
        setName(owner.name);
        setEmail(owner.email);
        setPhone(owner.phone);
    };

    if (done) {
        return <h3>Sent!</h3>;
    }
    else {
        return <>
            {editing ?
                <Loading text='Update...' /> :
                <div className='operation'>
                    <span onClick={handleUndo}>Undo</span>
                    <div className='form modal-form'>
                        <label>
                            <span>Contact person</span>
                            <input value={name} onChange={(e) => setName(e.target.value)}/>
                        </label>
                        <label>
                            <span>Email address</span>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} />
                        </label>
                        <label>
                            <span>Contact number</span>
                            <input value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </label>
                    </div>
                </div>
            }
            <footer>
                <button className='button boring' onClick={() => props.modalActions.hideModal()}>Cancel</button>
                <button className='button default' onClick={() => {
                    setEditing(true);
                    if (name && email && phone) {
                        props.orderActions.editContact(
                            props.order.id,
                            name,
                            email,
                            phone,
                            () => {
                                setEditing(false);
                                setDone(true);
                                setTimeout(() => {
                                    props.modalActions.hideModal();
                                }, 1500);
                                props.orderActions.requestOrders(
                                    props.filter || 'All',
                                    props.offset,
                                    props.pageSize,
                                    props.search || '',
                                    props.sort);
                            });
                    }
                    else
                        setEditing(false);
                }}>
                    Send
                </button>
            </footer>
        </>
    }
}

const ReIssueTaskModal: React.FC<RowProps & { taskId: number }> = (props) => {
    const defaultReason = "";
    const [editing, setReIssue] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [reason, setReason] = React.useState(defaultReason ? defaultReason : '');

    if (error) {
        return <ActionResultModal
            type="failure"
            message="Something went wrong, please try again later."
            onConfirm={() => props.modalActions.hideModal()}
        />;
    }

    if (done) {
        return <ActionResultModal
            type="success"
            message="Your invitation has been sent successfully."
            onConfirm={() => props.modalActions.hideModal()}
        />;
    }

    else {
        return <>
            {editing ?
                <Loading text='Update...' /> :
                <div className='operation'>
                    <div className='form modal-form'>
                        <label>
                            <span>Please provide a message to your recipient explaining why you are reissuing this task</span>
                            <textarea rows={4} value={reason} onChange={(e) => setReason(e.target.value)}/>
                        </label>
                    </div>
                    <div className='reissue-instruction'>
                        Please note by reissuing this task, you will no longer have access to this completed report. A new request to perform this task will be sent to the recipient. Would you like to continue?
                    </div>
                </div>
            }
            <footer>
                <button className='button boring' onClick={() => props.modalActions.hideModal()}>Cancel</button>
                <button className='button default' onClick={() => {
                    setReIssue(true);
                    if (reason) {
                        props.orderActions.reIssueTask(
                            props.order.id,
                            props.taskId? props.taskId : 0,
                            reason,
                            () => {
                                setReIssue(false);
                                setDone(true);
                                setTimeout(() => {
                                    props.modalActions.hideModal();
                                }, 1500);
                                props.orderActions.requestOrders(props.filter || 'All', props.offset, props.pageSize, props.search || '', props.sort)
                            },
                            () => {
                                setReIssue(false);
                                setError(true);
                            });
                    }
                    else
                        setReIssue(false);
                }}>
                    Yes
                </button>
            </footer>
        </>
    }
}

interface RowProps {
    order: Order;
    filter?: string;
    offset: number;
    search?: string;
    sort: string;
    pageSize: number
    modalActions: typeof modalActionCreators;
    orderActions: typeof orderActionCreators;
}

interface Props {
    order: Order;
    taskId?: number;
    orderActions: typeof orderActionCreators;
}

export default connect(
    null,
    (dispatch: Dispatch) => {
        return {
            modalActions: bindActionCreators(modalActionCreators, dispatch),
            orderActions: bindActionCreators(orderActionCreators, dispatch),
        };
    }
)(OrderRow);
