import { Order } from '../../models/order';
import { InProgressOrder } from '../../models/inProgressOrder';
import { RequestOrders, ReceiveOrders, RemoveOrder, ReceiveArchive, ReceiveNewOrders, ReceiveInProgressOrder, ClearInProgressOrder, RequestArchive, StartShareOrder, FinishShareOrder, StartNotifyUser, FinishNotifyUser, StartEditContact, FinishEditContact, OrderError, StartReIssueTask, FinishReIssueTask, RequestInvoice, ReceiveInvoice } from './actions';
import { Reducer } from 'redux';
import { OrderInvoice } from '../../models/orderInvoice';

export interface OrderState {
    orders?: Order[];
    completedCount: number;
    inProgressCount: number;
    archivedCount: number;
    inactiveCount: number;
    expiringCount: number;
    recentCount: number;
    taggedCount: number;

    createdOrders?: Order[];

    inProgressOrder?: InProgressOrder;

    loadingPayment?: boolean;
    confirmedCount: number;
    totalCount: number;

    orderError?: string;
    orderInvoice? : OrderInvoice
}

export type OrderStateKnownAction =
    RequestOrders |
    ReceiveOrders |
    RemoveOrder |
    ReceiveArchive |
    ReceiveNewOrders |
    ReceiveInProgressOrder |
    ClearInProgressOrder |
    RequestArchive |
    StartShareOrder |
    FinishShareOrder |
    StartNotifyUser |
    FinishNotifyUser |
    StartEditContact |
    FinishEditContact |
    OrderError |
    StartReIssueTask |
    FinishReIssueTask |
    RequestInvoice |
    ReceiveInvoice;

const defaultState: OrderState = {
    completedCount: 0,
    inProgressCount: 0,
    archivedCount: 0,
    inactiveCount: 0,
    expiringCount: 0,
    recentCount: 0,
    loadingPayment: false,
    confirmedCount: 0,
    totalCount: 0,
    taggedCount: 0
}

export const orderStateReducer: Reducer<OrderState> = (state, incomingAction) => {
    const action = incomingAction as OrderStateKnownAction;
    let result = Object.assign({}, state || defaultState);

    switch (action.type) {
        case 'REQUEST_ORDERS':
            // Nothing to do here, tbh
            break;
        case 'RECEIVE_ORDERS':
            result.orders = action.orders.orders;//.filter(o => o.status !== 'PendingConfirmation' && o.status !== 'Unknown');
            result.archivedCount = action.orders.archivedCount;
            result.inProgressCount = action.orders.inProgressCount; //action.orders.orders.filter(o => o.status === 'InProgress').length;
            result.completedCount = action.orders.completedCount; //action.orders.orders.filter(o => o.status === 'Completed').length;
            result.taggedCount = action.orders.taggedCount;

            // Special sauce filters
            result.expiringCount = action.orders.expiringCount; //action.orders.expiringOrders.length;
            result.inactiveCount = action.orders.inactiveCount; //action.orders.inactiveOrders.length;
            result.recentCount = action.orders.recentCount; //action.orders.recentOrders.length;
            result.confirmedCount = action.orders.confirmedCount;
            result.totalCount = action.orders.totalCount;
            break;
        case 'REMOVE_ORDER':
            result.orders = result.orders ? result.orders.filter(o => o.id !== action.orderId) : undefined;
            break;
        case 'RECEIVE_ARCHIVE':
            result.archivedCount = action.orders.length;
            break;
        case 'RECEIVE_NEW_ORDERS':
            result.createdOrders = action.orders;
            result.orderError = undefined;
            break;
        case 'RECEIVE_ORDER_INPROGRESS':
            result.inProgressOrder = action.order;
            break;
        case 'CLEAR_ORDER_INPROGRESS':
            result.inProgressOrder = undefined;
            break;
        case 'REQUEST_ARCHIVE':
            break;
        case 'ORDER_ERROR':
            result.orderError = action.message;
            break;
        case 'REQUEST_INVOICE':
            break;
        case 'RECEIVE_INVOICE':
            result.orderInvoice = action.orderInvoice;
            break;
    }

    return result;
}
