import { Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import * as OrderState from './orders';
import * as UserState from './users';
import * as ModalState from './modal';
import * as OrganisationState from './organisation';
import MarketplaceReducer, { MarketplaceState } from './marketplace';

export interface ApplicationState {
    orderState: OrderState.OrderState;
    userState: UserState.UserState;
    modalState: ModalState.ModalState;
    orgState: OrganisationState.OrganisationState;
    marketplaceState: MarketplaceState;
}

export const reducers = {
    orderState: OrderState.orderStateReducer,
    userState: UserState.userStateReducer,
    modalState: ModalState.modalStateReducer,
    orgState: OrganisationState.organisationStateReducer,
    marketplaceState: MarketplaceReducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export type AppThunk = ThunkAction<void, ApplicationState, unknown, Action<string>>;
