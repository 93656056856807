import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { UserState } from '../store/users';
import './login.scss';
import { Loading } from '../components/loading';

class ForgotPasswordPage extends React.Component<PageProps, PageState> {
    render() {
        return (
            <div className='login-page'>
                <div className="landing-body">
                    <div className="banner-image">
                        <img src="/images/olasio-logo-lg.svg" alt="Olasio Source" />
                    </div>
                    <div className="main-content">
                        <div className="content-container">
                            {(this.props.store.passwordResetResult && this.props.store.passwordResetResult.result) ?
                                <div>
                                    <p>
                                        An email has been sent to {<strong>{this.state.sentToEmail}</strong> || 'the email you provided'}. Follow the instructions provided in that email to reset your password
                                    </p>
                                    <p>
                                        <a href='/login'>Back to login</a>
                                    </p>
                                </div> :
                                <form className='form' onSubmit={this.submit}>
                                    <p style={{ padding: '20px 0' }}>
                                        Enter your email and we'll send you a link to reset your password.
                                    </p>
                                    <label>
                                        <span>Email Address</span>
                                        <input
                                            type='email'
                                            placeholder='Email Address'
                                            name='email'
                                            ref={this.email}
                                            disabled={this.props.store.resettingPassword}
                                            />
                                    </label>
                                    {this.props.store.resettingPassword ?
                                        <Loading text='Just a moment...' /> :
                                        <div className='login-options'>
                                            <button className='button default' type='submit'>
                                                Reset Password
                                            </button>
                                            <a href='/login'>I remembered it</a>
                                        </div>
                                    }
                                    {(this.props.store.passwordResetResult && !this.props.store.passwordResetResult.result) ?
                                        <div className='errors' style={{ color: '#d00', marginTop: '20px' }}>
                                            {this.props.store.passwordResetResult.message}
                                        </div>: ''
                                    }
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    constructor(props: PageProps) {
        super(props);

        // Refs
        this.email = React.createRef();

        // Bindings
        this.submit = this.submit.bind(this);
    }

    email: React.RefObject<HTMLInputElement>;
    submit(e: React.FormEvent<HTMLElement>) {
        e.preventDefault();

        if (this.email.current) {
            const emailAddress = this.email.current.value;
            this.setState({ sentToEmail: emailAddress });

            if (emailAddress) {
                this.props.actions.requestPasswordReset(emailAddress);
            }
        }
    }

    componentWillMount() {
        if (this.props.store.user)
            this.props.actions.clearReset();
    }
}

interface PageProps {
    store: UserState;
    actions: typeof userActionCreators;
}

interface PageState {
    sentToEmail?: string;
}

export default connect(
    (state: ApplicationState) => ({
        store: state.userState
    }),
    (dispatch: Dispatch) => {
        return {
            actions: bindActionCreators(userActionCreators, dispatch)
        };
    }
)(ForgotPasswordPage);
