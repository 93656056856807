import { RequestOrganisation, ReceiveOrganisation } from './actions';
import { Reducer } from 'redux';
import { Organisation } from '../../models/organisation';

export interface OrganisationState {
    organisation?: Organisation;
    loadingOrganisation?: boolean;
}

export type OrganisationStateKnownAction =
    RequestOrganisation |
    ReceiveOrganisation;

const defaultState: OrganisationState = {
    loadingOrganisation: false
}

export const organisationStateReducer: Reducer<OrganisationState> = (state, incomingAction) => {
    const action = incomingAction as OrganisationStateKnownAction;
    let result = Object.assign({}, state || defaultState);

    switch (action.type) {
        case 'RECEIVE_ORGANISATION':
            result.loadingOrganisation = false;
            result.organisation = action.organisation;
            break;
        case 'REQUEST_ORGANISATION':
            // Maybe we'll get multiple request calls, but one was honestly just enough
            if (!result.loadingOrganisation) {
                result.loadingOrganisation = true;
                result.organisation = undefined;
            }
            break;
    }

    return result;
}
