import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './toast.module.scss';

type Props = {
    message: string | JSX.Element | null;
    onDismiss: () => void;
};

const Duration = 8000;

export const Toast = ({ message, onDismiss }: Props) => {
    const timeoutIdRef = useRef<number>();

    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        clearTimeout(timeoutIdRef.current);
        if (message) {
            timeoutIdRef.current = window.setTimeout(onDismiss, Duration);
            // restart animation by re-inserting the element
            setShowProgress(false);
            setTimeout(() => setShowProgress(true), 10);
        }
    }, [message, onDismiss]);

    return (
        <div className={cn(styles.toast, { [styles.hide]: !message })}>
            <img src="/images/checked-alt.svg" alt="" />
            <div className={styles.content}>{message}</div>
            <button onClick={onDismiss}>&times;</button>
            {showProgress && <div className={styles.progress} />}
        </div>
    );
};
