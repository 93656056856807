import { AppThunkAction } from "..";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { UserStateKnownAction } from ".";
import { User } from '../../models/user';
import { getUrlParamByName } from '../../helpers/url';

type KnownAction = UserStateKnownAction | CallHistoryMethodAction;

export const userActionCreators = {
    requestLogin: (emailAddress: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_USER' });

        const url = `/v1/users/authenticate`;
        fetch(url, {
            method: 'post',
            body: JSON.stringify({
                email: emailAddress,
                password: password
            }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(response => {
            if (response.ok)
                return response.json() as Promise<User>;
            else
                return response.text().then(text => {
                    if (text.indexOf('Forbidden') === -1)
                        throw new Error('Oops. Something went wrong. Please try again.');
                    else
                        throw new Error('Incorrect email address or password.');
                });
        })
        .then((user: User) => {
            dispatch({
                type: 'RECEIVE_USER',
                user: user
            });

            const redirectUrl = getUrlParamByName('redirect');

            if (user.onboard) {
                dispatch(push('/onboarding'));
            } else if (redirectUrl) {
                dispatch(push(redirectUrl));
            } else {
                dispatch(push('/tasks'));
            }
        })
        .catch((err: Error) => {
            dispatch({
                type: 'FAILED_LOGIN',
                details: err.toString()
            });
        });
    },

    requestPasswordReset: (emailAddress: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_RESET' });

        const url = `/v1/users/resetpassword?email=${emailAddress}`;
        fetch(url, {
            method: 'post',
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(response => {
            if (response.ok) {
                dispatch({
                    type: 'RESET_SUCCESS'
                })
            }
            else {
                return response.text().then(text => {
                    if (text.indexOf('Forbidden') === -1)
                        dispatch({
                            type: 'FAILED_RESET',
                            details: 'Oops. Something went wrong. Check your email address and try again.'
                        });
                    else
                        dispatch({
                            type: 'FAILED_RESET',
                            details: 'Incorrect email address or password.'
                        });
                });
            }
        })
        .catch((err: Error) => {
            dispatch({
                type: 'FAILED_RESET',
                details: 'Oops. Something went wrong. Check your email address and try again.'
            });
        });
    },

    alreadyLoggedIn: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(push('/tasks'));
    },

    clearReset: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'CLEAR_RESET'
        });
    },

    requestUserList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            const url = `/v1/users/userlist`;
            fetch(url, {
                headers: {
                    'authorization': `bearer ${user.token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<User[]>;
                }
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text });
            })
            .then(users => {
                if (users) {
                    dispatch({
                        type: 'RECEIVE_USERLIST',
                        users: users
                    });
                }
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    requestRemoveUser: (userId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        dispatch({
            type: 'REQUEST_REMOVE_USER'
        });

        if (user && user.token) {
            const url = `/v1/users/${userId}/remove`;
            fetch(url, {
                method: 'delete',
                headers: {
                    'authorization': `bearer ${user.token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<User[]>;
                }
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text });
            })
            .then(users => {
                if (users) {
                    dispatch({
                        type: 'RECEIVE_USERLIST',
                        users: users
                    });
                }
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    requestSetUserRole: (userId: string, role: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        dispatch({
            type: 'REQUEST_REMOVE_USER'
        });

        if (user && user.token) {
            const url = `/v1/users/${userId}/userrole?role=${role}`;

            fetch(url, {
                method: 'put',
                headers: {
                    'authorization': `bearer ${user.token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<User[]>;
                }
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text });
            })
            .then(users => {
                if (users) {
                    dispatch({
                        type: 'RECEIVE_USERLIST',
                        users: users
                    });
                }
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    requestInviteUser: (name: string, email: string, onDone: (result: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            const url = `/v1/users/inviteuser`;
            fetch(url, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify({
                    name: name,
                    email: email
                })
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text });
            })
            .then(result => {
                onDone(true);
            })
            .catch(err => {
                console.log(err);
                alert(err);
                onDone(false);
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    logout: (redirectUrl?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOG_OUT'
        });
        if (redirectUrl) {
            dispatch(push(`/login?redirect=${encodeURIComponent(redirectUrl)}`));
        } else {
            dispatch(push('/login'));
        }
    },

    returnHome: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(push('/tasks'));
    },

    checkAuth: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            const url = `/v1/users/checkauth`;
            fetch(url, {
                headers: {
                    'authorization': `bearer ${user.token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<boolean>;
                }
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text });
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    checkReportAuth: (reportGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            const url = `/v1/users/checkreportauth?reportGuid=${reportGuid}`;
            fetch(url, {
                headers: {
                    'authorization': `bearer ${user.token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<boolean>;
                }
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push(`/login?redirecturl=report/${reportGuid}`));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text });
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
        }
        else {
            dispatch(push('/login'));
        }
    }
}
