import OrderRow from './orderRow';
import { Order } from '../../models/order';
import { NoList } from '../../components/nolist';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

// TODO: let's not use nested ternary here, confusing af
export const OrderList = (props: ListProps) => {
    const totalCount = useSelector((state: ApplicationState) => state.orderState.totalCount);
        return (
            <div className='order-list'>
                {props.orders ?
                    props.orders.length > 0 ?
                        props.orders.map(o => <OrderRow
                            order={o} key={o.id}
                            filter = {props.filter}
                            offset = {props.offset}
                            search = {props.search}
                            sort   = {props.sort}
                            pageSize = {props.pageSize}/>) :
                            props.taskSettings && !props.search && !totalCount ? <NoList
                            title='You haven’t sent any tasks yet'
                            description='Start collecting data now by sending out a smart task.'
                            link='/new-task'
                            linkText='Send Smart Task'></NoList> :
                            totalCount || !!props.search ? <NoList
                            title='No task found'
                            description=''
                            link=''
                            linkText=''
                            linkColor=''></NoList> :
                            <NoList
                            title='You haven’t added any tasks yet'
                            description='Start collecting data now by heading to the Smart Task Store and selecting which smart tasks you would like to use.'
                            link='/store'
                            linkText='Setup Smart Tasks'
                            linkColor='setup'
                            ></NoList> :
                    'Loading...'}
            </div>
        );
};

interface ListProps {
    orders?: Order[];
    filter?: string;
    offset: number;
    search?: string;
    pageSize: number;
    sort: string;
    taskSettings?: boolean;
}
