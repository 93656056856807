import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import styles from './orderPaymentMethod.module.scss';
import { PaymentSelect } from '../components/newtask/paymentSelect';
import { ApplicationState } from '../store';
import { Loading } from '../components/loading';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { userActionCreators } from '../store/users/actionCreators';
import { orderActionCreators } from '../store/orders/actionCreators';
import chevronLeft from '../assets/chevron-left.svg';

export const OrderPaymentMethod = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.userState.user);
    const order = useSelector((state: ApplicationState) => state.orderState.inProgressOrder);
    const org = useSelector((state: ApplicationState) => state.orgState.organisation);
    const loadingOrg = useSelector((state: ApplicationState) => state.orgState.loadingOrganisation);
    const orderError = useSelector((state: ApplicationState) => state.orderState.orderError);

    const [paymentSelections, setPaymentSelections] = useState(
        order
            ? order.taskItems.map(item => {
                  if (item.paymentMethod) {
                      return typeof item.paymentMethod === 'number'
                          ? item.paymentMethod.toString()
                          : item.paymentMethod;
                  }
                  return 'cc';
              })
            : []
    );

    useEffect(() => {
        if (!user) dispatch(userActionCreators.logout());
        if (!order) dispatch(userActionCreators.returnHome());
        if (!org && !loadingOrg) {
            dispatch(organisationActionCreators.requestOrganisation());
        }
    }, [org, order, user]);

    if (!org || loadingOrg || !order) return <Loading text="Loading..." />;

    const handleNext = () => {
        dispatch(orderActionCreators.savePaymentMethodsAndProgress(paymentSelections));
    };

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <h2>Please select your payment method</h2>
                <p>Select your preferred payment method for each task.</p>
                <div className={styles.optionsContainer}>
                    {order.taskItems.map((item, i) => {
                        const invoiceOptions = org.invoiceOptions.filter(
                            opt => !opt.limitedToSurveyId || opt.limitedToSurveyId === item.surveyId
                        );

                        return (
                            <div key={item.id} className={styles.option}>
                                <PaymentSelect
                                    label={item.description}
                                    invoiceOptions={invoiceOptions}
                                    value={paymentSelections[i]}
                                    onChange={value => {
                                        const newSelections = paymentSelections.slice();
                                        newSelections[i] = value;
                                        setPaymentSelections(newSelections);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <footer className={styles.nav}>
                    <button className={`button default ${styles.next}`} onClick={handleNext}>
                        Next
                    </button>
                    <button className={`button disguise ${styles.back}`} onClick={() => dispatch(goBack())}>
                        <img src={chevronLeft} alt="" />
                        Back
                    </button>
                </footer>
            </div>
        </div>
    );
};
