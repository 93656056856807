import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../loading';
import { Multiselect } from '../multiselect';
import { Order } from '../../models/order';
import { ApplicationState } from '../../store';

export const ShareReportModalEdit = (props: Props) => {
    const [reportsToUnshare, setReportsToUnshare] = useState<string[]>([]);
    const [unsharing, setUnsharing] = useState(false);

    const user = useSelector((state: ApplicationState) => state.userState.user);

    const unshareReports = async () => {
        setUnsharing(true);

        const shareIds = reportsToUnshare.map(guid => props.reports.find(r => r.value === guid)!.shareId);
        const url = `/v1/reports/${props.order.id}/unshare`;

        try {
            const res = await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${user!.token}`,
                },
                body: JSON.stringify(shareIds),
            });

            if (!res.ok) {
                const text = await res.text();
                throw text;
            }

            // TODO: partial fail is reflected in the data
            // const data = res.json();
            props.onDone();
        } catch (err) {
            console.error(err);
            alert(err);
        }
    }

    return (
        <>
            {unsharing
                ? <Loading text='Updating...' />
                : (
                    <div className='form modal-form'>
                        <label>
                            <p>You can revoke access on reports shared to <span className='email-color'>{props.email}</span></p>
                            <Multiselect
                                items={props.reports}
                                selected={reportsToUnshare}
                                onChange={selected => setReportsToUnshare(selected)}
                            />
                        </label>
                    </div>
                )
            }
            <footer>
                <button className='button disguise' onClick={props.onBack}>
                    &lt; Back
                </button>
                <button
                    className='button default'
                    onClick={unshareReports}
                    disabled={unsharing || reportsToUnshare.length === 0}
                >
                    Confirm
                </button>
            </footer>
        </>
    );
}

interface Props {
    order: Order;
    email: string;
    reports: ReportOption[];
    onBack: () => void;
    onDone: () => void;
}

interface ReportOption {
    label: string;
    value: string;
    shareId: number;
}
