import React from 'react';
import './app.scss';
import NavBar from './components/navBar';
import NavBarPublic from './components/navBarPublic';
import { Store } from 'redux';
import { History } from 'history';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ModalRoot from './components/modals/modalRoot';
import OrdersPage from './pages/orders';
import { MarketplaceHome } from './pages/marketplaceHome';
import { MarketplaceHomePublic } from './pages/marketplaceHomePublic';
import { MarketplaceTaskDetailsPage } from './pages/marketplaceTaskDetails';
import { Onboarding } from './pages/onboarding';
import SignUpPage from './pages/signup';
import LoginPage from './pages/login';
import ForgotPasswordPage from './pages/forgotPassword';
import NotFound from './pages/notFound';
import ManageTeamPage from './pages/manageTeam';
import SettingsPage from './pages/settings';
import OrderTaskPage from './pages/orderTask';
import OrderConfirmationPage from './pages/orderConfirmation';
import { OrderPaymentMethod } from './pages/orderPaymentMethod';
import { OrderPaymentSingle } from './pages/orderPaymentSingle';
import { OrderPaymentPage } from './pages/orderPayment';
import { OrderSuccess } from './pages/orderSuccess';
import OrderBulkPage from './pages/orderBulk';
import { OrderSinglePage } from './pages/orderSingle';
import ReportPage from './pages/report'
import 'flexboxgrid';
import { ApiDocs } from './pages/apiDocs';
import { OrderInvoice } from './pages/orderInvoice';

interface AppProps {
    history: History;
    store: Store;
}

const App: React.FC<AppProps> = (props) => {
    return (
        <Provider store={props.store}>
            <ConnectedRouter history={props.history}>
                <Switch>
                    <Route exact path='/' component={LoginPage} />
                    <Route exact path='/login' component={LoginPage} />
                    <Route exact path='/forgotpassword' component={ForgotPasswordPage} />
                    <Route exact path='/report/:guid' component={ReportPage} />
                    <Route exact path='/invoice/:invoiceId' component={OrderInvoice} />
                    <Route>
                        <div className="app">
                            <Switch>
                                <Route exact path='/new-task' component={() => <NavBar special />} />
                                <Route exact path='/new-task/payment-method' component={() => <NavBar special />} />
                                <Route exact path='/new-task/payment-single' component={() => <NavBar special />} />
                                <Route exact path='/new-task/success' component={() => <NavBar special />} />
                                <Route exact path='/new-task/payment' component={() => <NavBar special />} />
                                <Route exact path='/new-task/bulk' component={() => <NavBar special />} />
                                <Route exact path='/new-task/single' component={() => <NavBar special />} />
                                <Route exact path='/task/:referenceId' component={() => <NavBar special />} />
                                <Route exact path='/public/store' component={NavBarPublic} />
                                <Route component={() => <NavBar />} />
                            </Switch>
                            <div className='app-body-container'>
                                <Switch>
                                    <Route exact path='/public/store' component={MarketplaceHomePublic} />
                                    <Route exact path='/tasks' component={OrdersPage} />
                                    <Route exact path='/dashboard' component={OrdersPage} />
                                    <Route exact path='/store/task/:taskId' component={MarketplaceTaskDetailsPage} />
                                    <Route exact path='/store' component={MarketplaceHome} />
                                    <Route exact path='/onboarding' component={Onboarding} />
                                    <Route exact path='/signup' component={SignUpPage} />
                                    <Route exact path='/manageteam' component={ManageTeamPage} />
                                    <Route exact path='/settings' component={SettingsPage} />
                                    <Route exact path='/new-task' component={OrderTaskPage} />
                                    <Route exact path='/new-task/bulk' component={OrderBulkPage} />
                                    <Route exact path='/new-task/single' component={OrderSinglePage} />
                                    <Route exact path='/new-task/payment-method' component={OrderPaymentMethod} />
                                    <Route exact path='/new-task/payment-single' component={OrderPaymentSingle} />
                                    <Route exact path='/new-task/success' component={OrderSuccess} />
                                    <Route exact path='/new-task/payment' component={OrderPaymentPage} />
                                    <Route exact path='/task/:referenceId' component={OrderConfirmationPage} />
                                    <Route exact path='/apireference' component={ApiDocs} />
                                    <Route component={NotFound} />
                                </Switch>
                            </div>
                            <ModalRoot />
                        </div>
                    </Route>
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;
