import * as React from 'react';
import './checkbox.scss';
import checkboxUnchecked from '../assets/checkbox-unchecked.svg';
import checkboxChecked from '../assets/checkbox-checked.svg';

export class Checkbox extends React.PureComponent<CheckboxProps, CheckboxState> {
    render() {
        return <div className='checkbox-block'>
            <button
                type='button'
                ref={this.props.ref}
                onClick={this.toggle}
                disabled={this.props.disabled}
                className={
                    `checkbox ${this.state.active ? 'active': ''} ` +
                    `${this.props.light ? 'light' : ''}` +
                    `${this.props.full ? 'full' : ''}`
                }
                >
                {this.state.active ?
                    <img src={checkboxChecked} alt='ticked' /> :
                    <img src={checkboxUnchecked} alt='unticked' />
                }
            </button>
            <label onClick={this.toggle}>{this.props.children}</label>
        </div>
    }

    constructor(props: CheckboxProps) {
        super(props);

        this.state = {
            active: !!props.defaultValue
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle(e: React.MouseEvent) {
        e.preventDefault();
        const newValue = !this.state.active;
        this.setState({
            active: newValue
        });
        if (this.props.onChange)
            this.props.onChange(newValue);
    }
}

interface CheckboxProps {
    defaultValue?: boolean;
    name?: string;
    onChange?: (value: boolean) => void;
    ref?: React.RefObject<HTMLButtonElement>;
    light?: boolean;
    full?: boolean;
    disabled?: boolean;
}

interface CheckboxState {
    active: boolean;
}
