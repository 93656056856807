import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../store';
import { orderActionCreators } from '../store/orders/actionCreators';
import { OrderState } from '../store/orders';
import { OrderList } from '../components/orders';
import { InfoTile } from '../components/infoTile';
import { SpecialDropdown } from '../components/specialDropdown';
import './orders.scss';
import ReactPaginate from 'react-paginate';
import '../components/pagination.scss';
import { OrganisationState } from '../store/organisation';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { modalActionCreators } from '../store/modal/actionCreators';
import { RouteComponentProps } from "react-router-dom";

export class OrdersPage extends React.PureComponent<PageProps, PageState> {
    render() {
        const { filter } = this.state;
        const pageCount = this.getPageCount();
        const taskSettings = this.props.orgStore && this.props.orgStore.organisation && this.props.orgStore.organisation.taskSettings.length > 0;
        return (
            <div className='orders-page-container'>
                <div className='banner-section'>
                    <div className='container-fluid'>
                        <div className='row'>
                            {filter === 'recent' || filter === 'inactive' || filter === 'expiring' ?
                                <button
                                    className='col-md-3 col-sm-6 col-xs-12 special-back active'
                                    onClick={this.clearActiveFilter}>
                                    Back to<br />
                                    Dashboard
                                </button> :
                                <h2 className='col-md-3 col-sm-6 col-xs-12'>Request<br />Status</h2>
                            }
                            <div className='col-md-3 col-sm-6 col-xs-12'>
                                <InfoTile
                                    active={filter !== 'inactive' && filter !== 'expiring'}
                                    counter={this.props.store.recentCount}
                                    caption='Recently completed requests'
                                    icon='/images/recently-completed.svg'
                                    onclick={this.showRecent}
                                    helpTitle='What are recently completed requests?'
                                    help='This will show requests that have been completed by your customers in the past 24 hours that have not yet been actioned.'
                                    />
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12'>
                                <InfoTile
                                    active={filter !== 'recent' && filter !== 'expiring'}
                                    counter={this.props.store.inactiveCount}
                                    caption='Inactive requests'
                                    icon='/images/inactive-tasks.svg'
                                    onclick={this.showInactive}
                                    helpTitle='What are inactive requests?'
                                    help='Inactive requests are those which have not received any updates from the customer for one week or longer.'
                                    />
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12'>
                                <InfoTile
                                    active={filter !== 'recent' && filter !== 'inactive'}
                                    counter={this.props.store.expiringCount} // used to be pendingCount (which was always 0) but this is still always 0?
                                    caption='Expiring requests'
                                    icon='/images/pending-tasks.svg'
                                    onclick={this.showExpiring}
                                    helpTitle='What are expiring requests?'
                                    help="Reports will expire 45 days after they have been generated."
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                {(this.props.store.orders && this.props.store.orders?.length > 0) || this.state.search ?
                <div className='container-fluid'>
                    {filter === 'recent' || filter === 'inactive' || filter === 'expiring' ?
                        <div className='active-filter'>
                            {this.state.activeFilterDescription}
                        </div> :
                        <div className='search-bar row'>
                            <div className='search-field-container col-lg-6 col-sm-5 col-xs-12'>
                                {this.state.search ?
                                    <div className='search-result-clearer'>
                                        <button
                                            className='button disguise'
                                            onClick={() => this.clearSearch() }>
                                                Search results for "<strong>{this.state.search}</strong>" <span>&times;</span>
                                        </button>
                                    </div> :
                                    <>
                                        <input
                                            className='search-field'
                                            type='search'
                                            autoComplete='off'
                                            placeholder='Search...'
                                            name='search'
                                            ref={this.filterRef}
                                            onKeyPress={this.keyPressed}
                                            />
                                        <img src='/images/icon-search.svg' alt='' onClick={() => this.search()}/>
                                    </>
                                }
                            </div>
                            <div className='filter-selector col-lg-6 col-sm-7 col-xs-12'>
                                <div>
                                    <SpecialDropdown
                                        label='Filter'
                                        options={[
                                            { label: `All • ${this.props.store.totalCount}`, value: 'active' },
                                            { label: `Sent • ${this.props.store.confirmedCount}`, value: 'confirmed' },
                                            { label: `Completed • ${this.props.store.completedCount}`, value: 'completed' },
                                            { label: `Attention Required • ${this.props.store.taggedCount}`, value: 'tagged' },
                                            { label: `Archived • ${this.props.store.archivedCount}`, value: 'archived' }
                                        ]}
                                        value={this.state.filter}
                                        onChange={(o) => {
                                            this.setFilter(o.value);
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: window.innerWidth >= 420 ? '10px' : '0' }}>
                                    <SpecialDropdown
                                        noborder
                                        label=''
                                        options={[
                                            { label: `Created (desc)`, value: 'createdDesc' },
                                            { label: `Created (asc)`, value: 'created' },
                                            { label: `Updated (desc)`, value: 'lastActivityDesc' },
                                            { label: `Updated (asc)`, value: 'lastActivity' }
                                        ]}
                                        value={this.state.sort}
                                        onChange={(o) => {
                                            this.setSort(o.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div> : ''}
                <div className='container-fluid'>
                    <OrderList
                        orders = {this.props.store.orders}
                        filter = {this.state.filter}
                        offset = {this.state.offset + 1}
                        search = {this.state.search}
                        sort = {this.state.sort}
                        pageSize = {PageSize}
                        taskSettings = {taskSettings}
                        />
                    {pageCount > PageSize ?
                    <div className="orders-pagination">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(pageCount / PageSize)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            forcePage = {this.state.offset}
                        />
                    </div> : ''}
                </div>
            </div>
        );
    }

    keyPressed(event: { key: any; }) {
        if (event.key === "Enter") {
         this.search();
        }
      }

    handlePageClick = (data: { selected: any; }) => {
        let selected = data.selected;
        let offset = Math.ceil(selected); //this.props.perPage
        let search = this.filterRef?.current?.value;

        this.setState({ offset: offset }, () => {
            this.props.store.orders = undefined;
            this.props.orderActions.requestOrders(this.state.filter, offset + 1, PageSize, this.state.search || '', this.state.sort);
        });
      };

    componentDidMount() {
        this.props.orderActions.requestOrders(this.state.filter, this.state.offset + 1, PageSize, this.state.search || '', this.state.sort);
        this.props.orgActions.requestOrganisation();

        if (this.props.location.search === '?onboarding')
                    this.props.modalActions.showModal(<this.OnboardModal />, '');
    }

    OnboardModal: React.FC<{}> = () => {
        return (
                <div className="onboard-popup">
                    <img src="/images/welcome.svg" alt=''/>
                        <p className="title">Welcome to Olasio</p>
                        <p className="description">Thanks for choosing Olasio for your business.</p>
                        <p className="description">Do you know we offer invoice payment option, you can smiply set up via payment option setting in your setting page.</p>
                        <div className="link"><a className="button setup" href='/tasks'>Got it</a></div>
                </div>
                );}

    getPageCount() {
        switch (this.state.filter) {
            case 'confirmed':
                return this.props.store.confirmedCount;
            case 'inprogress':
                return this.props.store.inProgressCount;
            case 'completed':
                return this.props.store.completedCount;
            case 'expiring':
                return this.props.store.expiringCount;
            case 'inactive':
                return this.props.store.inactiveCount;
            case 'recent':
                return this.props.store.recentCount;
            case 'archived':
                return this.props.store.archivedCount;
            case 'tagged':
                return this.props.store.taggedCount;
            default:
                return this.props.store.totalCount;
        }
    }

    setFilter(filter: Filter, search?: string) {
        this.props.store.orders = undefined;
        this.props.orderActions.requestOrders(filter, 1, PageSize, search? search : this.state.search || '', this.state.sort);
        this.setState({
            filter,
            offset: 0
        });
    }

    setSort(sort: Sort) {
        this.props.store.orders = undefined;
        this.props.orderActions.requestOrders(this.state.filter, 1, PageSize, this.state.search || '', sort);
        this.setState({
            sort,
            offset: 0
        });
    }

    viewArchive() {
        this.props.orderActions.loadArchive();
        this.setState({
            filter: 'archived',
        });
    }

    clearActiveFilter() {
        this.props.store.orders = undefined;
        this.props.orderActions.requestOrders('active', this.state.offset + 1, PageSize, this.state.search || '', this.state.sort);
        this.setState({
            activeFilterDescription: '',
            filter: 'active'
        });
    }

    showInactive() {
        if (this.state.filter !== 'inactive') {
            this.props.store.orders = undefined;
            this.props.orderActions.requestOrders('inactive', 1, PageSize, this.state.search || '', this.state.sort);
            this.setState({
                filter: 'inactive',
                activeFilterDescription: 'Tasks inactive for more than 7 days',
                offset: 0
            });
        }
        else
            this.clearActiveFilter();
    }

    showExpiring() {
        if (this.state.filter !== 'expiring') {
            this.props.store.orders = undefined;
            this.props.orderActions.requestOrders('expiring', 1, PageSize, this.state.search || '', this.state.sort);
            this.setState({
                filter: 'expiring',
                activeFilterDescription: 'Tasks with reports expiring in the next 3 days',
                offset: 0
            });
        }
        else
            this.clearActiveFilter();
    }

    showRecent() {
        if (this.state.filter !== 'recent') {
            this.props.store.orders = undefined;
            this.props.orderActions.requestOrders('recent', 1, PageSize, this.state.search || '', this.state.sort);
            this.setState({
                filter: 'recent',
                activeFilterDescription: 'Tasks completed in the past 24 hours',
                offset: 0
            });
        }
        else
            this.clearActiveFilter();
    }

    private filterRef: React.RefObject<HTMLInputElement>;
    search() {
        if (this.filterRef.current && this.filterRef.current.value !== this.state.search) {
            const search = this.filterRef.current.value;
            this.props.store.orders = undefined;
            this.props.orderActions.requestOrders(this.state.filter, 1, PageSize, search, this.state.sort);
            this.setState({
                search
            });
        }
    }

    clearSearch() {
        this.props.store.orders = undefined;
        this.props.orderActions.requestOrders(this.state.filter, 1, PageSize, '', this.state.sort);
        this.setState({
            search: undefined
        });
    }

    constructor(props: PageProps) {
        super(props);

        this.state = {
            filter: 'active',
            sort: 'createdDesc',
            activeFilterDescription: '',
            offset: 0
        };

        // Bindings
        this.search = this.search.bind(this);
        this.clearActiveFilter = this.clearActiveFilter.bind(this);
        this.showRecent = this.showRecent.bind(this);
        this.showExpiring = this.showExpiring.bind(this);
        this.showInactive = this.showInactive.bind(this);
        this.viewArchive = this.viewArchive.bind(this);
        this.keyPressed = this.keyPressed.bind(this)
        // Refs
        this.filterRef = React.createRef();
    }
}

const PageSize = 10;

interface PageProps extends RouteComponentProps<any> {
    store: OrderState;
    orderActions: typeof orderActionCreators;
    orgStore: OrganisationState;
    orgActions: typeof organisationActionCreators;
    modalActions: typeof modalActionCreators;
}

type Filter = 'active' | 'confirmed' | 'inprogress' | 'completed' | 'expiring' | 'inactive' | 'recent' | 'search' | 'archived' | 'tagged';
type Sort = 'created' | 'createdDesc' | 'lastActivity' | 'lastActivityDesc';

interface PageState {
    filter: Filter;
    sort: Sort;
    search?: string;
    activeFilterDescription: string;
    offset: number;
}

export default connect(
    (state: ApplicationState) => ({
        store: state.orderState,
        orgStore: state.orgState
    }),
    (dispatch: Dispatch) => {
        return {
            orderActions: bindActionCreators(orderActionCreators, dispatch),
            orgActions: bindActionCreators(organisationActionCreators, dispatch),
            modalActions: bindActionCreators(modalActionCreators, dispatch)
        };
    }
)(OrdersPage);
