import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { UserState } from '../store/users';

class NotFoundPage extends React.Component<PageProps, PageState> {
    render() {
        return (
            <div className='404 page'>
                <h1>404</h1>
                <h2>This is the wrong place. How even?</h2>
            </div>
        );
    }
    // eslint-disable-next-line
    constructor(props: PageProps) {
        super(props);
    }
}

interface PageProps {
    store: UserState;
    actions: typeof userActionCreators;
}

interface PageState {

}

export default connect(
    (state: ApplicationState) => ({
        store: state.userState
    }),
    (dispatch: Dispatch) => {
        return {
            actions: bindActionCreators(userActionCreators, dispatch)
        };
    }
)(NotFoundPage);
