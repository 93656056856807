import * as React from 'react';
import { userActionCreators } from '../store/users/actionCreators';
import { orderActionCreators } from '../store/orders/actionCreators';
import { UserState } from '../store/users';
import { OrderState } from '../store/orders';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import './orderConfirmation.scss';
import { _supportEmail } from '../settings';
import { Summary } from '../components/summary';
import { OrganisationState } from '../store/organisation';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { Loading } from '../components/loading';

class OrderConfirmationPage extends React.Component<PageProps, PageState> {
    render() {
        if (this.props.orderStore.inProgressOrder && this.props.userStore.user) {
            if (this.props.orgStore.organisation && !this.props.orgStore.loadingOrganisation) {
                const org = this.props.orgStore.organisation;

                return (
                    <div className='order-task page'>
                        <div className='fluid-container'>
                            <div className='row'>
                                <div className='col-lg-3 col-md-2 col-sm-1 hidden-xs'></div>
                                <div className='col-lg-6 col-md-8 col-sm-6 col-xs-12'>
                                    <div className='order-confirmation'>
                                        <header>
                                            <h1>Confirmed</h1>
                                            {this.props.orderStore.inProgressOrder.recipients.length === 1 ?
                                                <h5>Your Source Request has been successfully created.</h5> :
                                                <h5>Your Source Requests have been successfully created.</h5>
                                            }
                                        </header>
                                        <div className='order-confirmation-body'>
                                            <Summary
                                                borderOff
                                                groups={[
                                                    {
                                                        items: [
                                                            ...this.props.orderStore.inProgressOrder.taskItems.map((x,i) => {
                                                                return { label: i === 0 ? 'Task type' : '', value: x.description };
                                                            }),
                                                            { label: 'Recipients', value: `x${this.props.orderStore.inProgressOrder.recipients.length}` },
                                                            { label: 'Message to recipient(s)', value: this.props.orderStore.inProgressOrder.reason }
                                                        ]
                                                    }
                                                ]}
                                                final={{
                                                    label: 'TOTAL',
                                                    value: `$${this.props.orderStore.inProgressOrder.taskItems.map(t => org.taskSettings.filter(ts => ts.id === t.id)[0].price).reduce((a,b) => a + b, 0) * this.props.orderStore.inProgressOrder.recipients.length}`
                                                }}
                                            />
                                        </div>
                                        <footer>
                                            <p>
                                                Invoice will be sent to <strong>{this.props.userStore.user.email}</strong>.
                                            </p>
                                            <div className='confirmation-actions'>
                                                <button onClick={this.gotoDashboard} className='button boring'>
                                                    GO TO DASHBOARD
                                                </button>
                                                <button onClick={this.orderAgain} className='button default'>
                                                    ORDER ANOTHER TASK
                                                </button>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-2 col-sm-1 hidden-xs'></div>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return <Loading text='Loading...' />
            }
        }
        else {
            this.props.userActions.returnHome();
            return <div>Oops, something went wrong.</div>
        }
    }

    constructor(props: PageProps) {
        super(props);

        this.state = {
            loading: false
        };

        // Bindings
        this.orderAgain = this.orderAgain.bind(this);
        this.gotoDashboard = this.gotoDashboard.bind(this);

        // Refs

    }

    orderAgain() {
        this.props.orderActions.startNewOrder();
    }

    gotoDashboard() {
        this.props.userActions.returnHome();
    }

    componentDidMount() {
        if (!this.props.orgStore.organisation)
            this.props.orgActions.requestOrganisation();
    }
}

interface PageProps {
    userStore: UserState;
    userActions: typeof userActionCreators;
    orderStore: OrderState;
    orderActions: typeof orderActionCreators;
    orgStore: OrganisationState;
    orgActions: typeof organisationActionCreators;
}

interface PageState {
    loading: boolean;
}

export default connect(
    (state: ApplicationState) => ({
        userStore: state.userState,
        orderStore: state.orderState,
        orgStore: state.orgState
    }),
    (dispatch: Dispatch) => {
        return {
            userActions: bindActionCreators(userActionCreators, dispatch),
            orderActions: bindActionCreators(orderActionCreators, dispatch),
            orgActions: bindActionCreators(organisationActionCreators, dispatch)
        };
    }
)(OrderConfirmationPage);
