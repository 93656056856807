import * as React from 'react';
import { ApplicationState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from '../components/checkbox';
import { Link } from 'react-router-dom';
import './orderPayment.scss';
import { RadioButton } from '../components/radioButton';
import { SubmitOrder } from '../models/inProgressOrder';
import { Loading } from '../components/loading';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { userActionCreators } from '../store/users/actionCreators';
import { orderActionCreators } from '../store/orders/actionCreators';

export const OrderPaymentPage: React.FC<PageProps> = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.userState.user);
    const order = useSelector((state: ApplicationState) => state.orderState.inProgressOrder);
    const org = useSelector((state: ApplicationState) => state.orgState.organisation);
    const loadingOrg = useSelector((state: ApplicationState) => state.orgState.loadingOrganisation);
    const orderError = useSelector((state: ApplicationState) => state.orderState.orderError);
    let price = useSelector((state: ApplicationState) => {
        if (!order || !org)
            return 0;
        return order.taskItems
            .map(t => org.taskSettings.filter(ts => ts.id === t.id)[0].price)
            .reduce((a, b) => a + b, 0) * order.recipients.length;
    });
    let tax = Math.round(100 * price / 11) / 100;

    const [loading, setLoading] = React.useState(false);
    const [paymentType, setPaymentType] = React.useState(org ? (org.invoiceOptions.length === 0 ? 'credit-card' : '') : '');
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [paymentProcessed, setPaymentProcessed] = React.useState(true);
    const [ccType, setCCType] = React.useState('');
    const [ccNumber, setCCNumber] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [year, setYear] = React.useState('');
    const [cvc, setCVC] = React.useState('');
    const [name, setName] = React.useState('');
    const [invoiceOption, setInvoiceOption] = React.useState(0);

    // hide pricing if paying by invoice and invoice option isn't `Invoice me`
    const hidePricing =
        paymentType === 'invoice'
        && invoiceOption !== 0
        && org?.invoiceOptions.find(option => option.id === invoiceOption)?.name !== 'Invoice me';

    const thisYear = new Date().getFullYear();
    const years =
        [...(new Array(24).keys() as unknown as Array<any>)]
        .map((x, i) => thisYear + i);
    const page = order?.recipients?.length === 1 ? 'single' : 'bulk';
    React.useEffect(() => {
        if (!user)
            dispatch(userActionCreators.logout());
        if (!order)
            dispatch(userActionCreators.returnHome());
        if (!org && !loadingOrg) {
            dispatch(organisationActionCreators.requestOrganisation());
        }
    }, [org, order, user]);

    const confirmClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (order && user) {
            let submittingOrder = order as SubmitOrder;

            if (paymentType === 'credit-card') {
                submittingOrder.creditCardPayment = {
                    cVV: cvc,
                    ccNumber: ccNumber,
                    ccExpiryMonth: month,
                    ccExpiryYear: year,
                    ccName: name
                };
                submittingOrder.invoicePayment = undefined;
            }
            else if (paymentType === 'invoice') {
                submittingOrder.invoicePayment = {
                    invoiceTo: invoiceOption
                };
                submittingOrder.creditCardPayment = undefined;
            }
            setLoading(true);
            dispatch(orderActionCreators.requestNewOrder(submittingOrder));
        }
    }

    const creditCardOnInput = (ev: React.FormEvent<HTMLInputElement>) => {
        let target = ev.target as HTMLInputElement;
        let value = target.value;
        let newVal = value.replace(/[^\d]/g, '');
        let length = value.length;
        let rem = length % 4;
        let sections = ((length - rem) / 4);

        for (let i = 0; i < sections; i++) {
            let arr = newVal.split('');
            let index = ((i + 1) * 4) + i;
            arr.splice(index, 0, ' ');
            newVal = arr.join('');
        }
        newVal = newVal.trim();
        if (newVal.length > 23) {
            newVal = newVal.substr(0, 23);
        }
        target.value = newVal;

        let type = getCCType(newVal);
        setCCType(type);
        setCCNumber(newVal);
    }

    if (!org || loadingOrg || !order)
        return <Loading text='Loading...' />;

    return (
        <div className={`order-task page ${(loading && orderError === undefined) ? 'submitting' : ''}`}>
            <form className='fluid-container form final'>
                <div className='row'>
                    <div className='col-md-7 col-xs-12'>
                        <div className='show-when-loading'><Loading text='Submitting' /></div>
                        <div className='fluid-container hide-when-loading'>
                            <div className='row final-container-row'>
                                <div className='col-lg-1 hidden-md'></div>
                                <div className='col-lg-11 col-xs-12'>
                                    <div className='row'>
                                        <div className={`col-xs-12 ${org.invoiceOptions.length === 0 ? 'hidden' : ''}`}>
                                            <h2>How would you like to be billed</h2>
                                            <h5>Choose between pay by card or invoice your selected organisation.</h5>
                                            <label>
                                                <span>Billing Options</span>
                                                <div className="select">
                                                    <select onChange={(e) => setPaymentType(e.target.value)} defaultValue={org.invoiceOptions.length === 0 ? 'credit-card' : ''}>
                                                        <option value='' disabled>Please select...</option>
                                                        <option value='credit-card'>Pay with Credit Card</option>
                                                        {org.invoiceOptions.length !== 0 ?
                                                            <option value='invoice'>Invoice</option> : '' }
                                                    </select>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-12'>
                                            <p style={{ color: '#f00', fontSize: '18px'}}>{orderError}</p>
                                        </div>
                                    </div>
                                    {paymentType === 'credit-card' ?
                                        <div className='row payment'>
                                            <div className='col-xs-6'>
                                                <div className='header-center'>
                                                    <h2>Payment</h2>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='credit-card-type'>
                                                    <img
                                                        src='/images/thumbnail_visa.svg' alt='Visa'
                                                        className={(!ccType || ccType === 'visa') ? '' : 'inactive'} />
                                                    <img
                                                        src='/images/thumbnail_master.svg' alt='Mastercard'
                                                        className={(!ccType || ccType === 'master') ? '' : 'inactive'} />
                                                    <img
                                                        src='/images/thumbnail_amex.svg' alt='Amex'
                                                        className={(!ccType || ccType === 'amex') ? '' : 'inactive'} />
                                                </div>
                                            </div>
                                            <div className='col-xs-12'>
                                                <hr />
                                            </div>
                                            <div className='col-xs-12'>
                                                <label>
                                                    <span>Credit card number</span>
                                                    <input name='ccNumber' inputMode='numeric' type='text' onInput={creditCardOnInput} placeholder='Enter your credit card number...' />
                                                </label>
                                            </div>
                                            <div className='col-xs-4'>
                                                <label>
                                                    <span>Month</span>
                                                    <div className="select">
                                                        <select defaultValue='MM' name='ccExpiryMonth' onChange={(e) => setMonth(e.target.value)}>
                                                            <option disabled>MM</option>
                                                            <option>01</option>
                                                            <option>02</option>
                                                            <option>03</option>
                                                            <option>04</option>
                                                            <option>05</option>
                                                            <option>06</option>
                                                            <option>07</option>
                                                            <option>08</option>
                                                            <option>09</option>
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                        </select>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-xs-4'>
                                                <label>
                                                    <span>Year</span>
                                                    <div className="select">
                                                        <select defaultValue='YYYY' name='ccExpiryYear'onChange={(e) => setYear(e.target.value)}>
                                                            <option disabled>YYYY</option>
                                                            {years.map((n) => <option key={n}>{n}</option>)}
                                                        </select>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-xs-4'>
                                                <label>
                                                    <span>CVC</span>
                                                    <input name= 'cVV' type='text' maxLength={4} placeholder='CVC number...' onChange={(e) => setCVC(e.target.value)} />
                                                </label>
                                            </div>
                                            <div className='col-xs-12'>
                                                <label>
                                                    <span>Name on card</span>
                                                    <input name='ccName' type='text' placeholder='Enter your name as it appears on the card...' onChange={(e) => setName(e.target.value)} />
                                                </label>
                                            </div>
                                            {!paymentProcessed ?
                                            <div className='col-xs-12' style={{ color: 'red' }}>We are experiencing difficulties processing your payment. Please retry.</div> : ''}
                                        </div>
                                        : paymentType === 'invoice' ?
                                        <div className='row'>
                                            <div className='col-xs-12'>
                                                <label>
                                                    <span>Who do you want this invoice sent to?</span>
                                                </label>
                                                {org.invoiceOptions.map(o => {
                                                    return <RadioButton
                                                        key={o.id}
                                                        name='invoiceOption'
                                                        value={`${o.id}`}
                                                        label={o.name}
                                                        onChange={(value) => setInvoiceOption(parseInt(value))}
                                                        />
                                                })}
                                            </div>
                                        </div> : ''
                                    }
                                    {!!paymentType &&
                                        <div className='row' style={{ marginTop: '40px' }}>
                                            <div className='col-xs-12'>
                                                <p className='small'>
                                                    Please view the <a href="https://www.olasio.com/source/privacypolicy" target="_blank">Olasio Personal Information Collection Statement</a>
                                                </p>
                                                <Checkbox defaultValue={false} light onChange={(v) => setTermsAccepted(v)}>
                                                    <p className='small'>
                                                        I confirm that I am authorised to order these services on behalf of this organization
                                                        and agree to the <a href="https://www.olasio.com/source/termsofservice" onClick={(e) => { e.stopPropagation() }} target="_blank">Service Terms</a>&nbsp;
                                                        and <a href="https://www.olasio.com/source/privacypolicy" onClick={(e) => { e.stopPropagation() }} target="_blank">Privacy Policy</a>
                                                    </p>
                                                </Checkbox>
                                            </div>
                                        </div>
                                    }
                                    <div className='row' style={{ marginTop: '60px' }}>
                                        <div className='col-xs-6'>
                                            <Link className='button boring' to={`/new-task/${page}`} style={{ display: 'inline-block' }}>&lt; Back</Link>
                                        </div>
                                        <div className='col-xs-6' style={{ textAlign: 'right' }}>
                                            <button
                                                className='button default'
                                                disabled={
                                                    !paymentType ||
                                                    !termsAccepted ||
                                                    (paymentType == 'invoice' ?
                                                        (!invoiceOption) :
                                                        !(ccNumber && ccNumber.length > 12 && name && month && year && cvc && ccType))
                                                    }
                                                onClick={confirmClick}>
                                                    Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5 col-xs-12 hidden-xs' style={{ background: '#F6F6F6', minHeight: '100%' }}>
                        <div className='fluid-container'>
                            <div className='row final-container-row'>
                                <div className='col-md-11 col-xs-12'>
                                    <h2>Order summary</h2>
                                    <div className='payment-summary'>
                                        <div className='sub-heading'>
                                            Tasks
                                        </div>
                                        {order!.taskItems.map(t => <div key={t.id} className='line-item-row'>
                                            <div className='line-value'>{t.description}</div>
                                            {!hidePricing && <div className='line-value'>${t.price}</div>}
                                        </div>)}
                                        <hr />
                                        {order.recipients.length > 0 ?
                                            <>
                                                <div className='line-item-row'>
                                                    <div className='sub-heading'>Recipients</div>
                                                    <div className='line-value'>x{order.recipients.length}</div>
                                                </div>
                                            </> :
                                            <>

                                            </>
                                        }
                                        <hr />
                                        {hidePricing ?
                                            <div className='line-item-note'>
                                                Invoice will be sent to
                                                <strong>
                                                    <em>{org.invoiceOptions.find(o => o.id === invoiceOption)?.invoiceEntity.name}</em>
                                                </strong>
                                            </div>
                                        :
                                            <>
                                                <div className='line-item-row'>
                                                    <div className='sub-heading'>Sub-total</div>
                                                    <div className='line-value'>${Math.round(100 * (price - tax)) / 100}</div>
                                                </div>
                                                <div className='line-item-row'>
                                                    <div className='sub-heading'>GST</div>
                                                    <div className='line-value'>${tax}</div>
                                                </div>
                                                <hr />
                                                <div className='line-item-row'>
                                                    <div className='total-label'>TOTAL</div>
                                                    <div className='total-value'>${price}</div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-1 hidden-xs'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

const getCCType = (number: string) => {
    // strip it
    number = number.replace(/[^\d]/g, '');
    if (number[0] === '3') return 'amex';       // Actually Travel & Entertainment
    if (number[0] === '4') return 'visa';       // Visa, Switch & Electron
    if (number[0] === '5') return 'master';     // Mastercard & Bankcard
    if (number[0] === '6') return 'discover';   // Actually Merchandising & Finance
    if (number[0] === '7') return 'petroleum';
    if (number[0] === '8') return 'telecommunications';
    return '';
}

interface PageProps {

}
