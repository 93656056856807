import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loading } from '../loading';
import { Multiselect } from '../multiselect';
import { orderActionCreators } from '../../store/orders/actionCreators';
import { Order } from '../../models/order';
import { ReportShare } from '../../models/reportShare';

export const ShareReportModalAdd = (props: Props) => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [selectedReports, setSelectedReports] = useState<string[]>(
        props.shares ?
            props.shares.map(x => x.reportGuid) :
            props.reports.map(x => x.value)
        );
    const [sending, setSending] = useState(false);

    const dispatch = useDispatch();

    const shareReport = () => {
        if (!props.shares) return;

        setSending(true);

        // get reports' corresponding task IDs
        const taskIds = selectedReports.map(guid => props.order.tasks.find(t => t.reportGuid === guid)!.id);

        dispatch(orderActionCreators.shareOrder(
            props.order.id,
            props.email,
            taskIds,
            password,
            '', // message, TODO: this doesn't exist anymore
            props.onDone
        ));
    }

    return (
        <>
            {sending
                ? <Loading text='Sending...' />
                : (
                    <div className='form modal-form'>
                        <label>
                            <span>Please confirm reports you would like to share</span>
                            <Multiselect
                                items={props.reports}
                                selected={selectedReports}
                                onChange={selected => setSelectedReports(selected)}
                            />
                        </label>
                        <hr className='modal-separator' />
                        <label>
                            <span>Report access password</span>
                            <div className='password-field'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete='new-password'
                                    placeholder='xxxxxxxx'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <button onClick={() => setShowPassword(!showPassword)}>
                                    <img
                                        src={showPassword ? '/images/password-hide.svg' : '/images/password-show.svg'}
                                        alt={showPassword ? 'Hide password' : 'Show password'}
                                    />
                                </button>
                            </div>
                        </label>
                        <p className='modal-explanation'>
                            This will be required to view the reports you are sharing. You will need to communicate 
                            this password with your recipient as Olasio will not send this out.
                        </p>
                    </div>
                )
            }
            <footer>
                <button className='button disguise' onClick={props.onBack}>
                    &lt; Back
                </button>
                <button
                    className='button default'
                    onClick={shareReport}
                    disabled={sending || !password || selectedReports.length === 0}
                >
                    Share
                </button>
            </footer>
        </>
    );
}

interface Props {
    order: Order;
    reports: ReportOption[];
    shares?: ReportShare[]; // undefined = fetching
    onBack: () => void;
    onDone: () => void;
    email: string;
}

interface ReportOption {
    label: string;
    value: string;
}
