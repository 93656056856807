import { AppThunkAction } from "..";
import { ModalStateKnownAction } from ".";
import { CallHistoryMethodAction } from "connected-react-router";
import * as React from 'react';

type KnownAction = ModalStateKnownAction | CallHistoryMethodAction;

export const modalActionCreators = {
    showModal: (content: React.ReactElement, title?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SHOW_MODAL',
            body: content,
            title: title
        });
    },

    hideModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'HIDE_MODAL'
        });
    }
}
