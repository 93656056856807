import * as React from 'react';
import './multiselect.scss';

export const Multiselect: React.FC<MultiselectProps> = (props) => {
    const toggleSelect = (value: string) => {
        let nowSelected;

        if (props.selected.includes(value)) {
            nowSelected = props.selected.filter(v => {
                if (v === value) return false;
                return true;
            });
        } else {
            nowSelected = [...props.selected, value];
        }

        props.onChange(nowSelected);
    }

    return <div className='multiselect'>
        {props.items.map(item => {
            const selected = props.selected.includes(item.value);
            return <div
                key={item.value}
                className={`multiselect-item ${selected ? 'selected' : ''}`}
                onClick={() => toggleSelect(item.value)}>
                <span>{item.label}</span>
                <img src={selected ? '/images/icon-checked.svg' : '/images/icon-add.svg'} alt="" />
            </div>;
        })}
    </div>
}

interface MultiselectProps {
    items: {
        label: string;
        value: string;
    }[];
    selected: string[];
    onChange: (selected: string[]) => void;
}
