import React from 'react';
import styles from './paymentSummary.module.scss';
import { Task } from '../../models/order';
import { getPaymentDescription, cardNumberWithSpace } from '../../helpers/format';

type Props = {
    items: Task[];
    cardNumber?: string;
    paymentDate: string;
};

export const PaymentSummary: React.FC<Props> = ({ items, cardNumber, paymentDate }) => {
    const price = items.reduce((acc, curr) => curr.paymentDescription === getPaymentDescription('creditcard') ? acc + curr.price : acc, 0);
    const tax = Math.round((100 * price) / 11) / 100;

    return (
        <div className={styles.container}>
            {items.map(item => (
                 item.paymentDescription === getPaymentDescription('creditcard') && (
                <div key={item.id} className={styles.itemRow}>
                    <span>{item.name}</span>
                    <span className={styles.price}>${(Math.round(100 * item.price) / 100).toFixed(2)}</span>
                </div>)
            ))}
                    <hr />
                    <div className={styles.priceRow}>
                        <span>Sub total</span>
                        <span>${(Math.round(100 * (price - tax)) / 100).toFixed(2)}</span>
                    </div>
                    <div className={styles.priceRow}>
                        <span>GST</span>
                        <span>${(Math.round(100 * tax) / 100).toFixed(2)}</span>
                    </div>
                    <hr />
                    <div className={styles.priceRow}>
                        <span className={styles.total}>Total</span>
                        <span className={styles.total}>${(Math.round(100 * (price)) / 100).toFixed(2)}</span>
                    </div>
                    <hr />
                    {
                        cardNumber &&  
                        <div className={styles.priceRow}>
                            <span>Credit card</span>
                        <span>{cardNumberWithSpace(cardNumber)}</span>
                    </div>
                    }
                    <div className={styles.priceRow}>
                        <span>Payment received</span>
                        <span>{paymentDate}</span>
                    </div>
        </div>
    );
};
