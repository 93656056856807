import React from 'react';
import { useDispatch } from 'react-redux';
import { modalActionCreators } from '../../store/modal';

type Props = {
    onConfirm: () => void;
};

export const RemoveTaskModal = ({ onConfirm }: Props) => {
    const dispatch = useDispatch();

    return (
        <>
            <p>
                Once this task is removed, you and your organisation will not be able to select this task from the task list
                anymore.
            </p>
            <p>
                You can add this task back from the Smart Task Store whenever you need it.
            </p>
            <footer>
                <button className="button boring" onClick={() => dispatch(modalActionCreators.hideModal())}>
                    Cancel
                </button>
                <button
                    className="button cancel"
                    onClick={() => {
                        onConfirm();
                        dispatch(modalActionCreators.hideModal());
                    }}
                >
                    Confirm
                </button>
            </footer>
        </>
    );
};
