import * as React from 'react';
import './nolist.scss';

export const NoList: React.FC<NoListstProps> = (props) => {
    return <div className="empty-list">
        <img src={`${props.imgLink ? props.imgLink : '/images/empty-list.svg'}`} alt=''/>
        {props.title ? <p className="title">{props.title}</p>: ''}
        {props.description ? <p className="description">{props.description}</p> : ''}
        {props.link && props.linkText ? <a className={`button ${props.linkColor ? props.linkColor : 'primary'}`} href={props.link}>{props.linkText}</a> : ''}
    </div>;
}

interface NoListstProps {
    title?: string;
    description?: string;
    link?: string;
    linkText? : string;
    linkColor? : string;
    imgLink? : string;
}
