import * as React from 'react';
import './navBar.scss';
import { Tooltip } from './tooltip';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { UserState } from '../store/users';
import { modalActionCreators, ModalState } from '../store/modal';
import { PublicNav } from './nav';

class NavBar extends React.PureComponent<NavProps, NavState> {
    render() {
        return (
            <div className='navbar public'>
                <Link className='logo' to='/tasks'>
                    <img src='/images/olasio-logo.svg' alt='Olasio Source' />
                </Link>
                <nav>
                    <PublicNav />
                </nav>
                <div className='button-panel'>
                    <Link to='/tasks' className='button default nav-button'>
                        <img src='/images/icon-menu.svg' width='28' alt='' />
                        Login
                    </Link>
                    <a href='/signup' target="_self" className='button primary nav-button'>
                        Sign up
                    </a>
                </div>
            </div>
        );
    }

    constructor(props: NavProps) {
        super(props);

        this.state = {
            dropdown: false
        };

        this.tooltipRef = React.createRef();
    }

    tooltipRef: React.RefObject<HTMLImageElement>;
}

interface NavProps {
    userStore: UserState;
    userActions: typeof userActionCreators;
    modalStore: ModalState;
    modalActions: typeof modalActionCreators;
    special?: boolean;
}

interface NavState {
    dropdown: boolean;
}

interface InviteModalProps {
    cancel: () => void;
    confirm: (name: string, email: string, onDone: (success: boolean) => void) => void;
}

export default connect(
    (state: ApplicationState) => ({
        userStore: state.userState,
        modalStore: state.modalState
    }),
    (dispatch: Dispatch) => {
        return {
            userActions: bindActionCreators(userActionCreators, dispatch),
            modalActions: bindActionCreators(modalActionCreators, dispatch)
        };
    }
)(NavBar);
