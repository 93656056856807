import * as React from 'react';
import { ShowModal, HideModal } from './actions';
import { Reducer } from 'redux';


export interface ModalState {
    current: null | {
        body: React.ReactElement,
        title?: string
    }
}

export type ModalStateKnownAction =
    ShowModal |
    HideModal;

const defaultState: ModalState = {
    current: null
}

export const modalStateReducer: Reducer<ModalState> = (state, incomingAction) => {
    const action = incomingAction as ModalStateKnownAction;
    let result = Object.assign({}, state || defaultState);

    switch (action.type) {
        case 'HIDE_MODAL':
            result.current = null;
            break;
        case 'SHOW_MODAL':
            result.current = {
                body: action.body,
                title: action.title
            };
            break;
    }

    return result;
}

export * from './actionCreators';