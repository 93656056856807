import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketplaceTasksForOnboarding } from '../../api/marketplace';
import { MarketplaceTask } from '../../models/marketplaceTask';
import { ApplicationState } from '../../store';
import { userActionCreators } from '../../store/users/actionCreators';
import styles from './onboardingTasksStep.module.scss';
import { TaskTile } from './taskTile';

type Props = {
    selectedTaskIds: number[];
    selectedTags: string[];
    onSelect: (taskId: number) => void;
};

export const OnboardingTaskStep = ({ selectedTaskIds, selectedTags, onSelect }: Props) => {
    const token = useSelector((state: ApplicationState) => state.userState.user?.token);
    const dispatch = useDispatch();
    const [tasks, setTasks] = useState<MarketplaceTask[]>([]);
    useEffect(() => {
        if (!token) {
            dispatch(userActionCreators.logout());
            return;
        }

        const getTasks = async () => {
            try {
                const res = await getMarketplaceTasksForOnboarding(token, selectedTags);
                setTasks(res?.data?.filter(t => !t.comingSoon));
            } catch (e) {
                // todo
            }
        };

        getTasks();
    }, [dispatch, token, selectedTags]);

    return (
        <>
            <div className={styles.headingBlock}>
                <h1 className={styles.heading}>Select tasks you would like to add</h1>
                <p className={styles.text}>You can select a few from the following:</p>
            </div>
            <div className={styles.body}>
                <div className={styles.container}>
                    {tasks.map(task => {
                        const selected = selectedTaskIds.includes(task.id);
                        return (
                            <div className={styles.tile} key={task.id} onClick={() => onSelect(task.id)}>
                                <TaskTile task={task} hideActions showBorder={selected} />
                                <img
                                    className={styles.icon}
                                    src={selected ? '/images/c-checked.svg' : '/images/c-add.svg'}
                                    alt={selected ? 'Selected' : 'Add'}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
