import { RequestUser, ReceiveUser, LogOut, ReceiveUserList, RequestRemoveUser, FailedLogin, FailedReset, RequestReset, ReceiveReset, ClearReset } from './actions';
import { Reducer } from 'redux';
import { User } from '../../models/user';
import { Organisation } from '../../models/organisation';

export interface UserState {
    user?: User;
    userList?: User[];
    loginResult?: {
        result: boolean;
        message: string;
    };
    resettingPassword?: boolean;
    passwordResetResult?: {
        result: boolean;
        message: string;
    };
    loggingIn?: boolean;
    organisation?: Organisation;
    loadingOrganisation?: boolean;
}

export type UserStateKnownAction =
    RequestUser |
    ReceiveUser |
    ReceiveUserList |
    RequestRemoveUser |
    LogOut |
    FailedLogin |
    RequestReset |
    FailedReset |
    ReceiveReset |
    ClearReset;

const defaultState: UserState = {
    user: JSON.parse(localStorage.getItem('user') || 'null')
}

export const userStateReducer: Reducer<UserState> = (state, incomingAction) => {
    const action = incomingAction as UserStateKnownAction;
    let result = Object.assign({}, state || defaultState);

    switch (action.type) {
        case 'REQUEST_USER':
            result.loginResult = undefined;
            result.loggingIn = true;
            break;
        case 'FAILED_LOGIN':
            result.loginResult = {
                result: false,
                message: action.details
            };
            result.loggingIn = false;
            break;
        case 'RECEIVE_USER':
            result.loggingIn = false;
            result.user = action.user;
            window.localStorage.setItem('user', JSON.stringify(action.user));
            break;
        case 'LOG_OUT':
            result.user = undefined;
            window.localStorage.removeItem('user');
            break;
        case 'RECEIVE_USERLIST':
            result.userList = action.users;
            break;
        case 'REQUEST_REMOVE_USER':
            result.userList = undefined;
            break;
        case 'FAILED_RESET':
            result.passwordResetResult = {
                message: action.details,
                result: false
            };
            result.resettingPassword = false;
            break;
        case 'REQUEST_RESET':
            result.passwordResetResult = undefined;
            result.resettingPassword = true;
            break;
        case 'RESET_SUCCESS':
            result.passwordResetResult = {
                result: true,
                message: ''
            };
            result.resettingPassword = false;
            break;
        case 'CLEAR_RESET':
            result.passwordResetResult = undefined;
            result.resettingPassword = false;
            break;
    }

    return result;
}
