import React from 'react';
import cn from 'classnames';
import styles from './spinner.module.scss';

type Props = {
  small?: boolean;
};

export const Spinner = (props: Props) => (
  <div className={cn(styles.spinner, { [styles.small]: props.small })}>
    <svg viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" />
    </svg>
  </div>
);
