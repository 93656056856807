import React, { useState, useEffect } from 'react';
import { modalActionCreators } from '../../store/modal';
import { Order } from '../../models/order';
import { useDispatch, useSelector } from 'react-redux';
import './shareReportModal.scss';
import { ApplicationState } from '../../store';
import { ReportShare } from '../../models/reportShare';
import { ShareReportModalMain } from './shareReportModalMain';
import { ShareReportModalAdd } from './shareReportModalAdd';
import { ShareReportModalEdit } from './shareReportModalEdit';

enum Steps {
    Main,
    Add,
    Edit,
    Done,
};

export const ShareReportModal: React.FC<Props> = (props) => {
    const [step, setStep] = useState<Steps>(Steps.Main);
    const [shares, setShares] = useState<ReportShare[]>();
    const [editEmail, setEditEmail] = useState(''); // selected email during edit
    const [doneMessage, setDoneMessage] = useState('');
    const [email, setEmail] = useState('');
    const [unsharing, setUnsharing] = useState('');

    const reports = props.order.tasks
        .filter(t => t.reportUrl && !t.isExpired)
        .map(t => ({ value: t.reportGuid, label: t.name }));

    const unshareReports = async (email: string) => {
        setUnsharing(email);

        const shareIds = shares?.filter(x => x.email === email).map(x => x.id);
        const url = `/v1/reports/${props.order.id}/unshare`;

        try {
            const res = await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${user!.token}`,
                },
                body: JSON.stringify(shareIds),
            });

            if (!res.ok) {
                const text = await res.text();
                throw text;
            }

            // TODO: partial fail is reflected in the data
            // const data = res.json();
            setUnsharing('');
            setShares(shares?.filter(x => x.email !== email));
        } catch (err) {
            console.error(err);
            alert(err);
        }
    }

    const dispatch = useDispatch();

    const user = useSelector((state: ApplicationState) => state.userState.user);

    useEffect(() => {
        const url = `/v1/reports/${props.order.id}/share`;
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'authorization': `bearer ${user!.token}`, // user is definitely defined here
            },
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return res.text().then(text => { throw text; });
            }
        }).then(data => {
            setShares(data);
        }).catch(err => {
            console.error(err);
            alert(err);
        });
    }, [props.order.id, user]);

    switch (step) {
        case Steps.Main:
            return (
                <ShareReportModalMain
                    reports={reports}
                    shares={shares?.map(x => x.email === unsharing ? { ...x, pending: true } : x)}
                    onUnshareClick={email => {
                        unshareReports(email);
                    }}
                    onAddClick={(e) => {
                        setEmail(e);
                        setStep(Steps.Add);
                    }}
                />
            );
        case Steps.Add:
            return (
                <ShareReportModalAdd
                    order={props.order}
                    reports={reports}
                    shares={shares}
                    email={email}
                    onBack={() => {
                        setStep(Steps.Main);
                    }}
                    onDone={() => {
                        setStep(Steps.Done);
                        setDoneMessage('Sent!');
                        setTimeout(() => dispatch(modalActionCreators.hideModal()), 1500);
                    }}
                />
            );
        case Steps.Edit:
            // filter shares by the edit email, then map shareId to the report selection
            const sharedReports = shares!
                .filter(s => s.email === editEmail)
                .map(s => {
                    const report = reports.find(r => r.value === s.reportGuid)!;
                    return { ...report, shareId: s.id };
                });
            return (
                <ShareReportModalEdit
                    order={props.order}
                    email={editEmail}
                    reports={sharedReports}
                    onBack={() => {
                        setStep(Steps.Main);
                    }}
                    onDone={() => {
                        setStep(Steps.Done);
                        setDoneMessage('Reports are no longer being shared!');
                        setTimeout(() => dispatch(modalActionCreators.hideModal()), 1500);
                    }}
                />
            );
        case Steps.Done:
            return <h3>{doneMessage}</h3>;
        default:
            return <h3>Something went wrong here.</h3>;
    }
}

interface Props {
    order: Order;
}
