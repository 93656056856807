import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styles from './report.module.scss';
import { Loading } from '../components/loading';
import { userActionCreators } from '../store/users/actionCreators';
import { ApplicationState } from '../store';

type Params = {
    guid: string;
};

type Props = RouteComponentProps<Params>;

const ReportPage = (props: Props) => {
    const [error, setError] = useState<string | null>(null);

    const user = useSelector((state: ApplicationState) => state.userState.user);
    const dispatch = useDispatch();

    useEffect(() => {
        redirectToReport();
    });

    const redirectToReport = async () => {
        const { guid } = props.match.params;

        if (!user?.token) {
            // should redirect to report after login
            dispatch(userActionCreators.logout(`/report/${guid}`));
            return;
        }

        try {
            const res = await axios.get(`/v1/reports/temporarylink/${guid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `bearer ${user.token}`,
                },
            });

            document.location = res.data.url;
        } catch (e: any) {
            if (e.response?.status === 401) {
                dispatch(userActionCreators.logout(`/report/${guid}`));
            } else {
                setError('Oops, something went wrong.');
            }
            console.log(e);
        }
    };

    return (
        <div className={styles.page}>
            {error ? (
                <>
                    <img src="/images/error.svg" alt="" />
                    <h3>{error}</h3>
                </>
            ) : (
                <Loading text="Authenticating..." />
            )}
        </div>
    );
};

export default ReportPage;
