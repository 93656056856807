import * as React from 'react';

export class Tooltip extends React.Component<TooltipProps> {
    constructor(props: TooltipProps) {
        super(props);

        this.escape = this.escape.bind(this);
        this.clickOff = this.clickOff.bind(this);
        this.setupEventListeners = this.setupEventListeners.bind(this);
        this.removeEventListencers = this.removeEventListencers.bind(this);
    }

    render() {
        const align = this.props.align || 'center'
        return <div
            //onClick={(e) => e.stopPropagation()}
            className={`tooltip-container ${align} ${this.props.active ? 'active' : ''} ${this.props.top ? 'top' : ''} ${this.props.light ? 'light' : ''}`}>
            <div className='tooltip-body'>{this.props.children}</div>
        </div>
    }

    componentDidMount() {
        if (this.props.active)
            this.setupEventListeners();
        else
            this.removeEventListencers();
    }

    componentDidUpdate() {
        if (this.props.active)
            this.setupEventListeners();
        else
            this.removeEventListencers();
    }

    setupEventListeners() {
        window.setTimeout(() => {
            window.addEventListener('click', this.clickOff);
            window.addEventListener('keyup', this.escape);
        }, 200);
    }

    removeEventListencers() {
        window.removeEventListener('click', this.clickOff);
        window.removeEventListener('keyup', this.escape);
    }

    escape(e: KeyboardEvent) {
        if (e.key === 'Esc' || e.key === 'Escape' || e.keyCode === 27) {
            this.clickOff();
        }
    }

    clickOff(e?: MouseEvent) {
        if (this.props.active) {
            this.props.closeCallback(e);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickOff);
        window.removeEventListener('keypress', this.escape);
    }
}

export const TooltipTarget: React.FC = (props) => {
    return <button className='tooltip-target'>

    </button>
}

// TODO: Create new wrapper for the target and the tooltip content

interface TooltipProps {
    active: boolean;
    top: boolean;
    align?: string;
    light?: boolean;
    closeCallback: (e?: MouseEvent) => void;
}
