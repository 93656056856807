import * as React from 'react';
import { User } from '../../models/user';
import './userList.scss';
import { Tooltip } from '../tooltip';

export const UserList: React.FC<UserListProps> = (props) => {
    return <div className='user-list'>
        {props.users.map(u =>
            <UserRow
                key={u.id}
                user={u}
                hideActions={u.id === props.viewingUser}
                onRemove={() => props.onRemoveUser(u.id)}
                onSetRole={(role) => props.onSetUserRole(u.id, role)}
            />
        )}
    </div>
}

export const UserRow: React.FC<UserRowProps> = (props) => {
    const [showOptions, toggleOptions] = React.useState(false);

    return <div className='user-list-item' key={props.user.id}>
        <div className='user-info'>
            <div className='user-name'>{props.user.name}</div>
            <div className='user-email'>{props.user.email}</div>
        </div>
        {!props.hideActions ? <div
            className='user-actions button disguise tooltip-target'
            onClick={() => toggleOptions(!showOptions)}>
            {props.user.role ? props.user.role : 'Team member'} &#9660;
            <Tooltip closeCallback={() => toggleOptions(false)} active={showOptions} top={false} light>
                <div className='tooltip-action-list'>
                    <button
                        onClick={() => { if (props.onSetRole) props.onSetRole('Admin') }}
                        disabled={props.user.role === 'Admin'}>
                            Admin
                    </button>
                    <button
                        onClick={() => { if (props.onSetRole) props.onSetRole('TeamMember') }}
                        disabled={!props.user.role || props.user.role === 'TeamMember'}>
                            Team member
                    </button>
                    <button className='danger' onClick={props.onRemove}>Remove user</button>
                </div>
            </Tooltip>
        </div> : <div className='user-actions button disguise'>You</div>}
    </div>
}

interface UserListProps {
    users: User[];
    viewingUser: string;
    onRemoveUser: (userId: string) => void;
    onSetUserRole: (userId: string, role: string) => void;
}

interface UserRowProps {
    user: User;
    onRemove: () => void;
    onSetRole: (role: string) => void;
    hideActions?: boolean;
}
