import React, { useState } from 'react';
import Select from 'react-select';
import { ReportShare } from '../../models/reportShare';
import { Tooltip } from '../tooltip';

export const ShareReportModalMain = (props: Props) => {
    // TODO: only select a single report when opening from 'per-report' button
    const [selectedReports, setSelectedReports] = useState(props.reports);
    const [email, setEmail] = useState('');
    const [dropdown, setDropdown] = useState<null | number>(null);

    const getSharesByEmail = () => {
        let count: SharesByEmail[] = [];

        if (!props.shares) return count;

        props.shares.forEach(s => {
            // check if matching report is selected
            if (!selectedReports.some(r => r.value === s.reportGuid)) return;

            const match = count.find(r => r.email === s.email);
            if (match) {
                match.count++;
            } else {
                count.push({ email: s.email, count: 1, pending: s.pending });
            }
        });

        return count.sort((a, b) => a.email > b.email ? 1 : -1);
    };

    const sharesByEmail = getSharesByEmail();

    return (
        <div className='form modal-form'>
            <input type='email' placeholder='Share report by email address' onChange={(e) => setEmail(e.target.value)} />
            <button className='button default share-report-add-btn' onClick={() => props.onAddClick(email)} disabled={!email || email.indexOf('@') <= 0}>
                Share
            </button>
            <h5 className='titled-modal-separator'>
                <span>Currently shared</span>
            </h5>
            <div className='shared-list'>
                {props.shares ? 
                    sharesByEmail.length > 0 ?
                        sharesByEmail.map((s, i) => (
                            <div key={s.email} className={`shared-row ${s.pending ? 'pending' : ''}`}>
                                <div>
                                    <h5>{s.email}</h5>
                                    <p>{s.count} shared {s.count > 1 ? 'reports' : 'report'}</p>
                                </div>
                                <div className={`tooltip-target ${dropdown ? 'active' : ''}`}>
                                    <button onClick={() => setDropdown(dropdown === i ? null : i)} disabled={s.pending}>
                                        <img src='/images/ellipsis.svg' alt='Edit shared reports' />
                                    </button>
                                    <Tooltip
                                        closeCallback={() => { setDropdown(null) }}
                                        top={false}
                                        active={dropdown === i}
                                        align='left'
                                        light>
                                        <div className='tooltip-action-list'>
                                            <button className='link-button' onClick={() => props.onAddClick(s.email)}>Manage share</button>
                                            <button className='link-button' onClick={() => props.onUnshareClick(s.email)}>Unshare all</button>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                        <div className='no-shared-reports'>
                            <img src='/images/empty-report.svg' />
                            <p>No reports are being shared</p>
                        </div> :
                        <p>Loading...</p>
                }
            </div>
        </div>
    );
}

interface Props {
    reports: ReportOption[];
    shares?: ReportShareExtended[]; // undefined = fetching
    onUnshareClick: (email: string) => void;
    onAddClick: (email: string) => void;
}

interface ReportOption {
    label: string;
    value: string;
}

interface SharesByEmail {
    email: string;
    count: number;
    pending?: boolean;
}

interface ReportShareExtended extends ReportShare {
    pending?: boolean;
}
