import React, { useState } from 'react';
import cn from 'classnames';
import styles from './taskCheckbox.module.scss';
import { Tooltip } from '../tooltip';
import checkboxUnchecked from '../../assets/checkbox-unchecked.svg';
import checkboxChecked from '../../assets/checkbox-checked.svg';

type Props = {
    title: string;
    price: number;
    checked: boolean;
    onChange: () => void;
    tooltip?: string;
    isNew?: boolean;
    disabled?: boolean;
    disabledText?: string;
};

export const TaskCheckbox = ({ title, price, checked, onChange, tooltip, isNew, disabled, disabledText }: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    
    const getPriceBeforeGST = (price) => {
        const tax = Math.round((100 * price) / 11) / 100;
        return (Math.round(100 * (price - tax)) / 100).toFixed(2);
    }

    return (
        <div
            className={cn(styles.container, {
                [styles.checked]: checked,
                [styles.disabled]: disabled,
            })}
            onClick={disabled ? () => {} : onChange}
        >
            <div className={styles.checkbox} role="checkbox" aria-checked={checked}>
                <img src={checked ? checkboxChecked : checkboxUnchecked} alt="" />
            </div>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.text}>{title}</div>
                    {tooltip && (
                        <div
                            className={styles.tooltip}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            ?
                            <Tooltip
                                active={showTooltip}
                                top={false}
                                light
                                align="center"
                                closeCallback={() => setShowTooltip(false)}
                            >
                                <div className={styles.tooltipBody} dangerouslySetInnerHTML={{ __html: tooltip }} />
                            </Tooltip>
                        </div>
                    )}
                    {(isNew && !disabled) && <div className={styles.new}>New</div>}
                </div>
                <div className={styles.text}>
                    {disabled ? (
                        disabledText || 'Coming soon'
                    ) : (
                        <>
                            <b>${getPriceBeforeGST(price)}*</b>/use
                        </>
                    )}
                </div>
            </div>
            {isNew && <img className={styles.newMobile} src="/images/new-tag.svg" alt="New" />}
        </div>
    );
};
