import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './orderInvoice.module.scss';
import { ApplicationState } from '../store';
import { Loading } from '../components/loading';
import { orderActionCreators } from '../store/orders/actionCreators';
import { _supportEmail } from '../settings';
import checkIcon from '../assets/check-lg.svg';
import { PaymentSummary } from '../components/orders/paymentSummary';
import { RouteComponentProps } from "react-router-dom";
import { getNiceDateString, convertDate } from '../helpers/dates';
import { braintreeTransactionStatus, creditCardStatus } from '../helpers/format';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

export const OrderInvoice: React.FC<RouteComponentProps<RouteProps>> = ({ match }) => {
    const invoiceId = match.params.invoiceId;
    const dispatch = useDispatch();
    const orderInvoice = useSelector((state: ApplicationState) => state.orderState.orderInvoice);
    const taskCount = orderInvoice?.order?.tasks.length;

    useEffect(() => {
        if (!orderInvoice) {
            console.log("orderInvoice", orderInvoice);
            dispatch(orderActionCreators.requestInvoice(invoiceId));
        }
    });

    if (!orderInvoice) return <Loading text="Loading..." />;

    return (
        <div className={styles.page}>
            <div className={styles.topbar}>
                <div>
                    <div><img src="/images/invoice-logo.svg"/></div>
                    <div className={styles.title}>Tax invoice</div>
                </div>
                <button className={styles.printpdf} onClick={window.print}>Print / Save as PDF</button>
            </div>
            <div className={styles.content}>
                <div className={styles.logo}><img src="/images/olasio-logo-inv.svg"/></div>
                <div className={styles.header}>
                    <div>
                        <h2>Tax receipt</h2>
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div>
                        <p className={styles.label}>{orderInvoice?.order?.recipient.name}</p>
                        <p>{orderInvoice?.order?.recipient.phone}</p>
                        <p>{orderInvoice?.order?.recipient.email}</p>
                    </div>
                    <div className={styles.ref}>
                        <p className={styles.label}>Date issued</p>
                        <p>{getNiceDateString(convertDate(orderInvoice?.order?.createdUtc))}</p>
                        <p></p>
                        <p></p>
                        <p className={styles.label}>Reference number</p>
                        <p>{orderInvoice?.order?.referenceId}</p>
                    </div>
                </div>
                <hr/>
                {orderInvoice?.braintreeTransaction && (
                        <div className={styles.paymentSummary}>
                        <>
                            <div className={styles.headline}>
                                <div className={styles.title}>Payment summary</div>
                                <div className={styles.paymentStatus}>{braintreeTransactionStatus(orderInvoice?.braintreeTransaction?.status).toUpperCase()}</div>
                            </div>
                            <div className={styles.border}></div>
                            <PaymentSummary 
                                items={orderInvoice?.order?.tasks}
                                paymentDate = {getNiceDateString(convertDate(orderInvoice?.braintreeTransaction?.createdUtc))}
                            ></PaymentSummary>
                        </>
                         </div>
                )}
                {orderInvoice?.creditCardTransaction && (
                        <div className={styles.paymentSummary}>
                        <>
                            <div className={styles.headline}>
                                <div className={styles.title}>Payment summary</div>
                                <div className={styles.paymentStatus}>{creditCardStatus(orderInvoice?.creditCardTransaction?.status).toUpperCase()}</div>
                            </div>
                            <div className={styles.border}></div>
                            <PaymentSummary 
                                items={orderInvoice?.order?.tasks}
                                cardNumber = {orderInvoice?.creditCardTransaction?.cardNumber}
                                paymentDate = {getNiceDateString(convertDate(orderInvoice?.creditCardTransaction?.createdUtc))}
                            ></PaymentSummary>
                        </>
                         </div>
                )}
                {taskCount && taskCount > 0 && (
                    <div className={styles.orderSummary}>
                        <div className={styles.headline}>
                            <div className={styles.title}>Order summary</div>
                            <div className={styles.taskCount}>{taskCount} {taskCount > 1 ? 'items' : 'item'}</div>
                        </div>
                        <div className={styles.border}></div>
                        <div className={styles.block}>
                            <h3>TASKS</h3>
                            {orderInvoice?.order?.tasks.map((item, i) => (
                        <React.Fragment key={item.id}>
                            <div className={styles.section}>
                                <div className={styles.left}>
                                    <p>{item.name}</p>
                                </div>
                                <div>
                                    <div className={styles.right}>
                                        {item.fullDescription}
                                    </div>
                                    <small>
                                        {item.paymentDescription.toUpperCase()}
                                    </small>
                                </div>
                            </div>
                            {i !== orderInvoice?.order?.tasks.length - 1 && <hr />}
                        </React.Fragment>
                        ))}
                        </div>
                    </div>
                )}
                <div className={styles.footer}>
                 <div className={styles.org}>
                 <div className={styles.name}>Redbook Inspect Pty Ltd</div>
                 <div className={styles.abn}>ABN | 67 125 096 077</div>
                 <div className={styles.address}>449 Punt Road</div>
                 <div className={styles.address}>Richmond, VIC 3121</div>
                 </div>
                 <div>
                 <div className={styles.footerLogo}><img src="/images/olasio-logo-inv-sm.svg"/></div>
                 <div className={styles.statement}>Powered by Olasio</div>
                 <div className={styles.slogan}>Customer data collection made simple</div>
                 </div>
             </div>
            </div>
        </div>
    );
};

interface RouteProps {
    invoiceId: string;
}
