import ReactDOM from 'react-dom';
import './index.scss';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';

const baseUrl = '/';
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history, {});

const render = () => {
    const App = require('./App').default;

    ReactDOM.render(
        <App history={history} store={store} />,
        document.getElementById('root')
    );
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
