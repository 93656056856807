import React from 'react';
import cn from 'classnames';
import styles from './fullscreenFilter.module.scss';
import checkIcon from '../../assets/check-sm.svg';

type Props = {
  onClose: () => void;
  filters: Filter[];
  resultCount: number;
};

type Filter = {
  label: string;
  options: string[];
  selected: string;
  onChange: (option: string) => void;
};

export const FullscreenFilter = ({ onClose, filters, resultCount }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>Filters</h3>
          <button onClick={onClose}>
            <img src="/images/close.svg" alt="Close" />
          </button>
        </div>
        <div className={styles.body}>
          {filters.map(filter => (
            <React.Fragment key={filter.label}>
              <div className={styles.label}>{filter.label}</div>
              <ul className={styles.list}>
                {filter.options.map(option => {
                  const selected = option === filter.selected;
                  return (
                    <li
                      key={option}
                      className={cn({ [styles.selected]: selected })}
                    >
                      <button onClick={() => filter.onChange(option)}>
                        {option}
                        {selected && (
                          <img src={checkIcon} alt="Selected" />
                        )}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          ))}
        </div>
        <div className={styles.footer}>
          <button onClick={onClose}>Show {resultCount} results</button>
        </div>
      </div>
    </div>
  );
};
