import { zeroLeft } from "./format";

export const convertDate = (str: string) => {
    if (!str)
        return new Date();
    if (str.startsWith('/Date')) {
        let value = str
            .replace("/Date(", "")
            .replace(")/", "");
        let num = parseInt(value, 10);
        let result = new Date(num);
        return result;
    }
    else
        return new Date(Date.parse(str));
};

export const getDayOfWeek = (day: number) => {
    switch (parseInt(day as any)) {
        case 1: return 'Monday';
        case 2: return 'Tuesday';
        case 3: return 'Wednesday';
        case 4: return 'Thursday';
        case 5: return 'Friday';
        case 6: return 'Saturday';
        case 0: return 'Sunday';
        default: return null;
    }
};

export const getMonthName = (month: number) => {
    switch (month) {
        case 0: return 'January';
        case 1: return 'February';
        case 2: return 'March';
        case 3: return 'April';
        case 4: return 'May';
        case 5: return 'June';
        case 6: return 'July';
        case 7: return 'August';
        case 8: return 'September';
        case 9: return 'October';
        case 10: return 'November';
        case 11: return 'December';
        default: return null;
    }
}

export const howLongAgo = (from: Date) => {
    const now = new Date();
    const diff = (now as any) - (from as any);
    const dayDiff = diff / (24*60*60*1000);

    if (diff < 0) return 'the future';
    else if (dayDiff < 1) {
        const minuteDiff = diff / (60 * 60 * 1000);

        if (minuteDiff < 0.5) return `${Math.round(minuteDiff * 60)} seconds ago`;
        if (minuteDiff <= 1.5) return 'a minute ago';
        if (minuteDiff < 55) return `${Math.round(minuteDiff)} minutes ago`;
        if (minuteDiff <= 90) return 'an hour ago';
        
        return `${Math.round(minuteDiff / 60)} hours ago`;
    }
    else if (dayDiff < 2) {
        return 'yesterday';
    }
    else if (dayDiff < 11) {
        return `${Math.round(dayDiff)} days ago`;
    }
    else if (dayDiff < 30) {
        return `${Math.round(dayDiff / 7)} weeks ago`;
    }
    else if (dayDiff < 335) {
        return `${Math.round(dayDiff / 30)} months ago`;
    }
    else if (dayDiff < 548) {
        return 'a year ago';
    }

    return `${Math.round(dayDiff / 365)} years go`;
}

export const addDays = (date: Date, days: number) => {
    var resultDate = new Date(date.valueOf());
    resultDate.setDate(date.getDate() + days);
    return resultDate;
}

export const addMinutes = (date: Date, minutes: number) => {
    var resultDate = new Date(date.valueOf());
    resultDate.setMinutes(date.getMinutes() + minutes);
    return resultDate;
}

export const getDateString = (date: Date) => {
    return `${date.toISOString().split('T')[0]}`;
}

export const getDateStringLocal = (date: Date) => {
    return `${date.getFullYear()}-${zeroLeft(date.getMonth() + 1, 2)}-${zeroLeft(date.getDate(), 2)}`;
}

export const getTimeString = (date: Date) => {
    return `${date.toISOString().split('T')[1]}`;
}

export const getDurationHours = (from: Date, to: Date) => {
    return ((to as any) - (from as any)) / 3600000;
}

export const getDurationMinutes = (from: Date, to: Date) => {
    return Math.abs((to as any) - (from as any)) / 60000;
}

export const getDateDifferenceMinutes = (from: Date, to: Date) => {
    return ((to as any) - (from as any)) / 60000;
}

export const getDurationSeconds = (from: Date, to: Date) => {
    return ((to as any) - (from as any)) / 1000;
}

export const convertTimespanToMinutes = (time: string) => {
    if (!time) return 0;
    const regex = /P((([0-9]*\.?[0-9]*)Y)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)W)?(([0-9]*\.?[0-9]*)D)?)?(T(([0-9]*\.?[0-9]*)H)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)S)?)?/;
    let matches = time.match(regex);
    if (matches != null) {
        let hours = parseFloat(matches[12] || '0');
        let minutes = parseFloat(matches[14] || '0');
        let seconds = parseFloat(matches[16] || '0');

        return (hours * 60) + minutes + (seconds / 60);
    }
    else
        return 0;
}

export const convertTimespanToSeconds = (time: string) => {
    if (!time) return 0;
    const regex = /P((([0-9]*\.?[0-9]*)Y)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)W)?(([0-9]*\.?[0-9]*)D)?)?(T(([0-9]*\.?[0-9]*)H)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)S)?)?/;
    let matches = time.match(regex);
    if (matches != null) {
        let hours = parseFloat(matches[12] || '0');
        let minutes = parseFloat(matches[14] || '0');
        let seconds = parseFloat(matches[16] || '0');

        return (hours * 60 * 60) + (minutes * 60) + seconds;
    }
    else
        return 0;
}

export const convertMinutesToDuration = (minutes: number) => {
    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;
    return `${zeroLeft(hours)}:${zeroLeft(mins)}`;
}

export const convertMinutesToTimespan = (minutes: number) => {
    minutes = parseInt(minutes.toString());
    if (minutes < 60)
        return `PT${minutes % 60}M`;
    else if (minutes % 60 === 0)
        return `PT${parseInt((minutes / 60).toString())}H`;
    else
        return `PT${parseInt((minutes / 60).toString())}H${minutes % 60}M`;
}

export const convertTimespanToNiceTimeString = (time: string) => {
    let totalMinutes = convertTimespanToMinutes(time);
    return getNiceTimeString(totalMinutes);
}

export const convertDurationToMinutes = (time: string) => {
    let arr = time.split(':');
    let hours = parseInt(arr[0]);
    let minutes = parseInt(arr[1]);
    return (hours * 60) + minutes;
}

export const convertStringToDate = (str: string) => {
    return new Date(Date.parse(str));
}

export const getLocalISOString = (date: Date) => {
    let year = date.getFullYear();
    let month = zeroLeft(date.getMonth() + 1, 2);
    let day = zeroLeft(date.getDate(), 2);
    let hour = zeroLeft(date.getHours(), 2);
    let minute = zeroLeft(date.getMinutes(), 2);

    return `${year}-${month}-${day}T${hour}:${minute}:00.000`;
}

export const getNiceDateString = (date: Date) => {
    let year = date.getFullYear();
    let month = getMonthName(date.getMonth());
    let day = date.getDate();

    return `${month} ${day}, ${year}`;
}

export const getNiceDateDayString = (dayOfMonth: number) => {
    switch (dayOfMonth) {
        case 1:
            return '1st';
        case 2:
            return '2nd';
        case 3:
            return '3rd';
        default:
            return `${dayOfMonth}th`;
    }
}

export const getStartOfWeekMonday = (inputDate: Date) => {
    let date = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
    let day = date.getDay();
    let diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff));
}

export const getNiceTimeStringFromDate = (date: Date) => {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const diffInMs = date.getTime() - startOfDay.getTime();
    const minutes = diffInMs / 60000;
    
    return getNiceTimeString(minutes);
}

export const getNiceTimeString = (totalMinutes: number) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = Math.floor(totalMinutes % 60);

    while (hours >= 24) {
        hours -= 24;
    }

    let ampm = hours >= 12 ? 'pm' : 'am';

    if (hours > 12)
        hours -= 12;
    else if (hours === 0)
        hours = 12;

    return `${zeroLeft(hours, 2)}:${zeroLeft(minutes, 2)}${ampm}`;
}
