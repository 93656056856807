import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { UserState } from '../store/users';
import './login.scss';
import { Loading } from '../components/loading';

class LoginPage extends React.Component<PageProps, PageState> {
    render() {
        return (
            <div className='login-page'>
                <div className="landing-body">
                    <div className="banner-image">
                        <img src="/images/olasio-logo-lg.svg" alt="Olasio Source" />
                    </div>
                    <div className="main-content">
                        <div className="content-header">
                            <a href="https://www.olasio.com/" className="back-link">
                                <div><img src="/images/back.svg" alt="" /></div>
                                Back to home page
                            </a>
                            <p>Don't have an account? <a href="/signup">Sign up here</a>.</p>
                        </div>
                        <div className="content-container">
                            <form className='form' onSubmit={this.submit}>
                                <h2>Sign in to Olasio</h2>
                                <p>Enter your login details below.</p>
                                <label>
                                    <span>Email Address</span>
                                    <input type='email' placeholder='Email Address' name='email' ref={this.email} />
                                </label>
                                <label>
                                    <span>Password</span>
                                    <input type='password' placeholder='Password' name='password' ref={this.password} />
                                </label>
                                {this.props.store.loggingIn ?
                                    <Loading text='Logging in...' /> :
                                    <div className='login-options'>
                                        <button className='button default' type='submit'>
                                            SIGN IN
                                        </button>
                                        <a href='/forgotpassword'>Forgot your password?</a>
                                    </div>
                                }
                                {(this.props.store.loginResult && !this.props.store.loginResult.result) ?
                                    <div className='errors' style={{ color: '#d00', marginTop: '20px' }}>
                                        {this.props.store.loginResult.message}
                                    </div>: ''
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    constructor(props: PageProps) {
        super(props);

        // Refs
        this.email = React.createRef();
        this.password = React.createRef();

        // Bindings
        this.submit = this.submit.bind(this);
    }

    email: React.RefObject<HTMLInputElement>;
    password: React.RefObject<HTMLInputElement>;
    submit(e: React.FormEvent<HTMLElement>) {
        e.preventDefault();

        if (this.email.current && this.password.current) {
            const username = this.email.current.value;
            const password = this.password.current.value;

            if (username && password) {
                this.props.actions.requestLogin(username, password);
            }
        }
    }

    componentWillMount() {
        if (this.props.store.user)
            this.props.actions.alreadyLoggedIn();
    }
}

interface PageProps {
    store: UserState;
    actions: typeof userActionCreators;
}

interface PageState {

}

export default connect(
    (state: ApplicationState) => ({
        store: state.userState
    }),
    (dispatch: Dispatch) => {
        return {
            actions: bindActionCreators(userActionCreators, dispatch)
        };
    }
)(LoginPage);
