import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { SlimCentre } from '../components/slimCentre';
import { Loading } from '../components/loading';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { Checkbox } from '../components/checkbox';
import styles from './settings.module.scss';

export const SettingsPage:React.FC<PageProps> = (props) => {
    const saveChanges = () => {
        
    }

    const [loading, setLoading] = React.useState(false);
    const [settings, setSettings] = React.useState(props.store.orgState.organisation?.keyValueSettings || []);
    const updateSetting = (key: string, val: string) => {
        const updatedSettings = [...settings];
        let match = false;
        updatedSettings.forEach(s => {
            if (s.key == key) {
                s.value = val;
                match = true;
            }
        });

        if (!match) {
            updatedSettings.push({
                key: key,
                value: val,
                id: 0
            });
        }
        setSettings(updatedSettings);
    }

    React.useEffect(() => {
        setSettings(props.store.orgState.organisation?.keyValueSettings || [])
    }, [ props.store.orgState.organisation ]);
    React.useEffect(() => {
        props.orgActions.requestOrganisation();
    }, []);

    if (props.store.userState.user &&
        props.store.userState.user.role === 'Admin' &&
        !loading &&
        props.store.orgState.organisation) {
        return (
            <>
                <form className='settings page form standalone' autoComplete='off'>
                    <SlimCentre>
                        <h1>Organisation Settings</h1>
                        <div className='fluid-container'>
                            <div className='row'>
                                {availableSettings.map(s => {
                                    let setting = settings?.filter(x => x.key == s.key)[0];
                                    if (s.type == 'bool') {
                                        return (<div key={s.key} className='col-xs-12 col-md-6'>
                                            <label>
                                                <span>{s.description}</span>
                                                <Checkbox
                                                    name={s.key}
                                                    onChange={(v) => updateSetting(s.key, v ? 'true' : 'false')}
                                                    defaultValue={setting?.value == 'true'}>
                                                        {s.name}
                                                </Checkbox>
                                            </label>
                                        </div>)
                                    }
                                    else {
                                        return (
                                            <div key={s.key} className='col-xs-12 col-md-6'>
                                                <label>
                                                    <span>{s.name}</span>
                                                    <input
                                                        type='text'
                                                        defaultValue={setting?.value}
                                                        placeholder={s.placeholder}
                                                        onChange={(e) => {
                                                            updateSetting(s.key, e.target.value);
                                                        }}
                                                        />
                                                </label>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                            <footer>
                                <button type='button' className='button default' onClick={saveChanges}>Save Changes</button>
                            </footer>
                        </div>
                        <hr className={styles.divider} />
                        <h1>User Details</h1>
                        <div className='fluid-container'>
                            <div className='row'>
                                <div className='col-xs-12 col-md-6'>
                                    <label>
                                        <span>Name</span>
                                        <input type='text' defaultValue={props.store.userState.user.name} disabled />
                                    </label>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <label>
                                        <span>Contact Number</span>
                                        <input disabled autoComplete='false' type='tel' defaultValue={props.store.userState.user.phone} />
                                    </label>
                                </div>
                                <div className='col-xs-12'>
                                    <label>
                                        <span>Email</span>
                                        <input disabled autoComplete='new-password' type='email' defaultValue={props.store.userState.user.email} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </SlimCentre>
                </form>
            </>
        );
    }
    else if (!props.store.orgState.organisation || loading) {
        return <Loading />
    }
    else {
        props.userActions.returnHome();
        return <div>Something went wrong. Please refresh the page.</div>
    }
}

const availableSettings = [
    {
        key: 'WebhookBaseUrl',
        name: 'Webhook Base URL',
        placeholder: 'https://www.org.com',
        description: 'Base webhook url which API requests will use to notify your systems of completed tasks.',
        type: 'text'
    },
    {
        key: 'DisableTaxInvoiceEmail',
        name: 'Disable Tax Invoice Email',
        description: 'Disable the automated tax-invoice emails being sent when new requests are created?',
        type: 'bool'
    },
]

interface PageProps {
    store: ApplicationState;
    userActions: typeof userActionCreators;
    orgActions: typeof organisationActionCreators;
}

interface PageState {

}

export default connect(
    (state: ApplicationState) => ({
        store: state
    }),
    (dispatch: Dispatch) => {
        return {
            userActions: bindActionCreators(userActionCreators, dispatch),
            orgActions: bindActionCreators(organisationActionCreators, dispatch)
        };
    }
)(SettingsPage);
