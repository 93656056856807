import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { orderActionCreators } from '../store/orders/actionCreators';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { UserState } from '../store/users';
import { OrderState } from '../store/orders';
import { OrganisationState } from '../store/organisation';
import { Loading } from '../components/loading';
import { Checkbox } from '../components/checkbox';

class OrderBulkPage extends React.Component<PageProps, PageState> {
    render() {
        return (
            <div className='order-task page'>
                {this.state.loading && <Loading text='Processing...' /> }
                <div className={`fluid-container form ${this.state.loading && 'hidden'}`}>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <h2>Recipient details</h2>
                            <h5>Please download the CSV template to fill in all recipients' details, then upload here once complete.</h5>
                        </div>
                        <div className='col-xs-12' style={{ margin: '30px 0 0 0' }}>
                            <div className='label-row'>
                                <div className='row-label'>
                                    <strong>Download CSV template</strong>
                                </div>
                                <div className='label-row-content'>
                                    <a
                                        className='button boring'
                                        href={'#'}
                                        onClick={(e) => {
                                            let downloadAnchor = document.createElement('a');
                                            document.body.append(downloadAnchor);
                                            e.preventDefault();
                                            let url = `/v1/orders/olasio-source-template.csv?`;
                                            let taskSettingsIds = `${(this.props.orderStore.inProgressOrder?.taskItems.map(t => `taskSettingsId=${t.id}`))?.join('&')}`;
                                            url += taskSettingsIds;
                                            console.log(taskSettingsIds)
                                            fetch(url, { headers: { 'authorization': `bearer ${this.props.userStore.user?.token}` } })
                                                .then(response => {
                                                    return response.blob();
                                                })
                                                .then(data => {
                                                    var objectURL = URL.createObjectURL(data);
                                                    downloadAnchor.href = objectURL;
                                                    downloadAnchor.download = 'olasio-source-template.csv'
                                                    downloadAnchor.click();
                                                    window.URL.revokeObjectURL(objectURL);
                                                })
                                                .catch((err) => {
                                                    document.location.reload();
                                                });
                                        }}
                                        download
                                        target='_self'>
                                            Download
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12' style={{ margin: '20px 0' }}>
                            <hr />
                        </div>
                        <div className='col-xs-12'>
                            <h5>Upload file</h5>
                            <input
                                type='file'
                                accept=".csv,text/comma-separated-values"
                                multiple={false}
                                placeholder='Upload CSV File'
                                ref={this.fileRef}
                                />
                        </div>
                        <div className='col-xs-12' style={{ margin: '20px 0' }}>
                            <hr />
                        </div>
                        <div className='col-xs-12'>
                        <div className = 'errorLable'>
                            <h2>Message to all recipients</h2>
                            {this.state.reasonError ?
                                    <span className='required'>Required*</span>
                            : ''}
                            </div>
                            <h5>Please provide a message or reason for this request</h5>
                            <label>
                                <textarea
                                    rows={4}
                                    name='reason'
                                    defaultValue={this.props.orderStore.inProgressOrder?.reason}
                                    ref={this.reasonRef}
                                    placeholder='Enter your message for the recipient here...'
                                    className = {this.state.reasonError ? 'error' : ''}
                                    onInput={(e) => {
                                        !this.reasonRef.current?.value ?
                                        this.setState({ reasonError: true }) : this.setState({ reasonError: false });
                                        }}>
                                </textarea>
                            </label>
                        </div>
                        <div className='col-xs-12' style={{ margin: '30px 0 30px 0' }}>
                            <hr />
                        </div>
                        <div className='col-xs-12'>
                            <Checkbox onChange={e => this.setState({ notifyOnTaskComplete: e })}>
                                I would like to receive notification once the report is ready
                            </Checkbox>
                        </div>
                        <div className='col-xs-12'>
                            {this.state.errors.map(e => {
                                return <p key={e} className='form-error-message'>{e}</p>
                            })}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-6' style={{ textAlign: 'left', marginTop: '30px' }}>
                            <button className='button boring' disabled={false} onClick={this.prevClick}>Prev</button>
                        </div>
                        <div className='col-xs-6' style={{ textAlign: 'right', marginTop: '30px' }}>
                            <button className='button default' disabled={false} onClick={this.nextClick}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    constructor(props: PageProps) {
        super(props);

        this.state = {
            errors: [],
            loading: false,
            reasonError: false,
            notifyOnTaskComplete: false,
        };

        // Bindings
        this.nextClick = this.nextClick.bind(this);
        this.prevClick = this.prevClick.bind(this);

        // Refs
        this.reasonRef = React.createRef();
        this.fileRef = React.createRef();
    }

    reasonRef: React.RefObject<HTMLTextAreaElement>;
    fileRef: React.RefObject<HTMLInputElement>;

    nextClick() {
        if (!this.fileRef.current || !this.fileRef.current?.files || !this.reasonRef.current?.value){
            this.setState({reasonError: true})
            return;
        }
        let csv = this.fileRef.current.files[0];
        if (!csv)
            return;

        this.setState({ loading: true, errors: [] });
        let form = new FormData();
        form.append("file", csv);
        console.log(csv);

        fetch(`/v1/orders/uploadcsv`, { method: "POST", body: form, headers: {
            'authorization': `bearer ${this.props.userStore.user?.token}`
        } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    return response.text().then(text => { throw text; });
                }
            })
            .then(csvData => {
                if (this.props.orderStore.inProgressOrder) {
                    this.props.orderActions.saveAndProgressOrder({
                        ...this.props.orderStore.inProgressOrder,
                        recipients: csvData,
                        reason: this.reasonRef.current?.value || '',
                        notifyMe: this.state.notifyOnTaskComplete
                    });
                }
                else
                    this.props.orderActions.backToOrderStepOne();
            })
            .catch((err) => {
                this.setState({ errors: [ err ] });
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    prevClick() {
        this.props.orderActions.backToOrderStepOne();
    }

    componentDidMount() {
        if (!this.props.orderStore.inProgressOrder)
            this.props.orderActions.startNewOrder();
    }

    static getDerivedStateFromProps(nextProps: PageProps, prevState: PageState) {
        const state = {...prevState};

        return state;
    }
}

interface PageProps {
    userStore: UserState;
    userActions: typeof userActionCreators;
    orderStore: OrderState;
    orderActions: typeof orderActionCreators;
    orgStore: OrganisationState;
    orgActions: typeof organisationActionCreators;
}

interface PageState {
    errors: string[];
    loading: boolean;
    reasonError: boolean;
    notifyOnTaskComplete: boolean;
}

export default connect(
    (state: ApplicationState) => ({
        userStore: state.userState,
        orderStore: state.orderState,
        orgStore: state.orgState
    }),
    (dispatch: Dispatch) => {
        return {
            userActions: bindActionCreators(userActionCreators, dispatch),
            orderActions: bindActionCreators(orderActionCreators, dispatch),
            orgActions: bindActionCreators(organisationActionCreators, dispatch)
        };
    }
)(OrderBulkPage);
