import * as React from 'react';
import styles from './apiDocs.module.scss';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

export const ApiDocs: React.FC = () => {
    const [lang, setLang] = useState('csharp');
    const [codeString, setCode] = useState(csharpString);

    const user = useSelector((state: ApplicationState) => state.userState.user);

    if (!user?.apiKey) {
        return <h1>Not Found</h1>
    }

    const chooseLang = (lang: string) => {
        setLang(lang);

        switch (lang) {
            case 'csharp':
            default:
                setCode(csharpString);
                break;
        }
    };

    return <div className={styles.container}>
        <div className={styles.explanation}>
        <h2>Api Reference docs</h2>
            <p>
                This page only explains how to sign your calls to the Olasio Source
                API. Documents specifying methods, schemas, etc are available here: <a href="https://sourceapi.olasio.com/swagger">https://sourceapi.olasio.com/swagger</a>
            </p>
            <h2>Signing requests</h2>
            <p>
                The Olasio Source API is HTTPS only and requires each call to 
                be uniquely signed. To the right there is an example of this 
                being implemented (select your language from the drop-down).
            </p>
            <p>
                Each call should be signed (aka hashed) with your organisation's <em>secret key                    
                </em> (provided to you by us) using <em>SHA256</em>. The data to 
                sign should be in the format <code>[apiKey][pathAndQuery][timestamp]</code>.
                The encoding used should be UTF8.
            </p>
            <p>
                <code>timestamp</code> refers to an ISO8601 date/time value in UTC/GMT time. 
                eg: <code>2021-06-19T13:16:45.012Z</code><br />
                <code>apiKey</code> refers to your organisation's API key, provided to you in advance<br />
                <code>pathAndQuery</code> is the path and query string you are calling. It will need to match precisely the call you make to be accepted.
            </p>
            <p>
                This signature should then be provided as part of a <em>bearer token</em>, the format of which 
                should be <code>[apiKey]|[signature]|[timeStamp]</code>. That is the API Key,
                signature and timestamp separated by the <code>|</code> or "pipe" symbol.
            </p>
            <p>
                <strong>Additional note</strong>: A <code>User-Agent</code> header is required for all requests.
            </p>
        </div>
        <div className={styles.example}>
            <label>
                Select language:
                <select onChange={(e) => { chooseLang(e.target.value) }}>
                    <option>csharp</option>
                </select>
            </label>
            <SyntaxHighlighter language={lang} style={ghcolors} showLineNumbers wrapLongLines>
                {codeString}
            </SyntaxHighlighter>
        </div>
    </div>;
}

const csharpString =
`using (var client = new HttpClient())
{
    string apiKey = "[APIKEY]";
    string url = "";
    string timestamp = $"{DateTime.UtcNow:o}";

    var uri = new Uri(url);
    var secretKeyBytes = Convert.FromBase64String("[SECRETKEY]");
    string pathAndQuery = System.Web.HttpUtility.UrlEncode(uri.PathAndQuery);
    string dataToSign = $"{apiKey}{pathAndQuery}{timestamp}";
    byte[] dataBytes = Encoding.UTF8.GetBytes(dataToSign);
    string signature;

    using (HMACSHA256 hmac = new HMACSHA256(secretKeyBytes))
    {
        byte[] rawSignature = hmac.ComputeHash(dataBytes);
        signature = Convert.ToBase64String(rawSignature);
    }

    string token = $"{apiKey}|{signature}|{timestamp}";
    client.DefaultRequestHeaders.Add("Authorization", $"bearer {token}");
    client.DefaultRequestHeaders.Add("User-Agent", "[USER-AGENT]");

    // Example for GET request
    var response = await client.GetAsync(url);

    // Huzzah, eureka, etc
    if (response.IsSuccessStatusCode)
    {
        string json = await response.Content.ReadAsStringAsync();
        // TODO: Something with your json
    }
}`;
const phpString =
`
CONFUSED SCREAMING?!
`
const jsString =
`
`;