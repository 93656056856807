import React from 'react';
import styles from './paymentBlock.module.scss';
import { TaskItem } from '../../models/inProgressOrder';

type Props = {
    items: TaskItem[];
    hidePrice?: boolean;
};

export const PaymentBlock: React.FC<Props> = ({ items, hidePrice, children }) => {
    const price = items.reduce((acc, curr) => acc + curr.price, 0);
    const tax = Math.round((100 * price) / 11) / 100;

    return (
        <div className={styles.container}>
            {items.map(item => (
                <div key={item.id} className={styles.itemRow}>
                    <span>{item.description}</span>
                    <span>x 1</span>
                </div>
            ))}
            {!hidePrice && (
                <>
                    <hr />
                    <div className={styles.priceRow}>
                        <span>Sub-total</span>
                        <span>${(Math.round(100 * (price - tax)) / 100).toFixed(2)}</span>
                    </div>
                    <div className={styles.priceRow}>
                        <span>GST</span>
                        <span>${tax.toFixed(2)}</span>
                    </div>
                    <hr />
                    <div className={styles.priceRow}>
                        <span>Total</span>
                        <span className={styles.total}>${price.toFixed(2)}</span>
                    </div>
                </>
            )}
            {children}
        </div>
    );
};
