import * as React from 'react';
import './loading.scss';

export const Loading: React.FC<LoadingProps> = (props) => {
    return <div className='loading'>
        <svg viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20"></circle>
        </svg>
        <div className='loading-label'>{props.text}</div>
    </div>;
}

interface LoadingProps {
    text?: string;
}
