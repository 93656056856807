import React from "react";
import "./actionResultModal.scss";

type Props = {
  type: 'success' | 'failure';
  message: string;
  onConfirm: () => void;
};

export const ActionResultModal = (props: Props) => (
  <div className="action-result-modal">
    {props.type === 'success' ?

    <img src="/images/c-check.svg" alt="" />
    :
    <img src="/images/error.svg" alt="" />
  }
    <p>{props.message}</p>
    <button className="button default" onClick={props.onConfirm}>
      OK
    </button>
  </div>
);
