import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import styles from './orderPaymentSingle.module.scss';
import { ApplicationState } from '../store';
import { Loading } from '../components/loading';
import { Spinner } from '../components/spinner';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { userActionCreators } from '../store/users/actionCreators';
import { orderActionCreators } from '../store/orders/actionCreators';
import { Checkbox } from '../components/checkbox';
import { PaymentBlock } from '../components/newtask/paymentBlock';
import { CcPaymentBlock } from '../components/newtask/ccPaymentBlock';
import { InvoiceEntity } from '../models/organisation';
import chevronLeft from '../assets/chevron-left.svg';
import { getPaymentDescription } from '../helpers/format';
import { _braintreePaymentGatewayAuthorization } from '../settings';

export const OrderPaymentSingle = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.userState.user);
    const order = useSelector((state: ApplicationState) => state.orderState.inProgressOrder);
    const org = useSelector((state: ApplicationState) => state.orgState.organisation);
    const loadingOrg = useSelector((state: ApplicationState) => state.orgState.loadingOrganisation);
    const orderError = useSelector((state: ApplicationState) => state.orderState.orderError);

    const [ccNumber, setCcNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [cvc, setCvc] = useState('');
    const [name, setName] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (orderError) {
            setLoading(false);
        }
    }, [orderError]);

    useEffect(() => {
        if (!user) dispatch(userActionCreators.logout());
        if (!order) dispatch(userActionCreators.returnHome());
        if (!org && !loadingOrg) {
            dispatch(organisationActionCreators.requestOrganisation());
        }
    }, [org, order, user]);

    if (!org || loadingOrg || !order) return <Loading text="Loading..." />;

    // TODO: below filtering is quite messy and needs some work

    // first get cc tasks
    const ccPaymentTasks = order.taskItems.filter(item => item.paymentMethod === 'cc');

    // then get invoice me tasks
    const invoiceToMeOption = org.invoiceOptions.find(option => option.limitedToSurveyId === null);
    const invoiceToMeTasks = invoiceToMeOption
        ? order.taskItems.filter(item => item.paymentMethod === invoiceToMeOption.id)
        : [];

    // then group the rest by invoice entities
    const thirdPartyInvoiceTasks = order.taskItems.filter(
        item => item.paymentMethod !== 'cc' && invoiceToMeOption && item.paymentMethod !== invoiceToMeOption.id
    );
    const invoiceEntities: InvoiceEntity[] = []; // create an array of unique invoicees
    thirdPartyInvoiceTasks.forEach(task => {
        const invoiceOption = org.invoiceOptions.find(option => option.id === task.paymentMethod);
        if (invoiceOption && !invoiceEntities.some(entity => entity.id === invoiceOption.invoiceEntity.id)) {
            invoiceEntities.push(invoiceOption.invoiceEntity);
        }
    });

    const handleCC = () => {
    
        let paymentDetails = {
            ccv: cvc,
            expiryMonth: parseInt(month),
            expiryYear: parseInt(year),
            number: ccNumber,
        };
    
        var deviceDataPromise = getDeviceData();
        deviceDataPromise.then(deviceData => {
                var generateNoncePromise = getNonce(paymentDetails);
                generateNoncePromise.then(nonce => {
                        processPayment(nonce, deviceData);
                })
        })
    };
    
    const getNonce = async (paymentDetails) => {
        return new Promise<string>((resolve) => {
                    var createClient = require('braintree-web/client').create;
                    createClient({
                        authorization: _braintreePaymentGatewayAuthorization
                    }, function (err, clientInstance) {
                        if (err) {
                            console.error(err);
                            return;
                        }
                        var data = {
                            creditCard: {
                                number: paymentDetails.number,
                                cvv: paymentDetails.ccv,
                                expirationDate: `${paymentDetails.expiryMonth}/${paymentDetails.expiryYear}`,
                                options: {
                                    validate: false
                                }
                            }
                        };
                        clientInstance.request({
                            endpoint: 'payment_methods/credit_cards',
                            method: 'post',
                            data: data
                        }, function (requestErr, response) {
                            if (requestErr) {
                                console.error(err);
                                return;
                            }
                            var nonce = response && response.creditCards && response.creditCards[0] && response.creditCards[0].nonce;
                            resolve(nonce)
                        });
                    });
        });
    }

    const getDeviceData = async () => {
        return new Promise<string>((resolve) => {
                    var createClient = require('braintree-web/client').create;
                    createClient({
                        authorization: _braintreePaymentGatewayAuthorization
                    }).then(function (clientInstance) {
                        var dataCollector = require('braintree-web/data-collector').create;
                        dataCollector({
                            client: clientInstance
                        }).then(function (dataCollectorInstance) {
                            resolve(dataCollectorInstance.deviceData);
                        });
                    });
        });
    }

    const handleNext = () => {
        if (order && user) {
            setLoading(true);
            handleCC();
        }
    };

    const processPayment = (nonce, deviceData) => {
        dispatch(orderActionCreators.requestNewSingleOrder({
            externalReference: order.recipients[0].externalReference,
            reason: order.reason,
            notifyOnComplete: order.notifyMe,
            recipient: {
                name: order.recipients[0].name,
                phone: order.recipients[0].phone,
                email: order.recipients[0].email,
            },
            tasks: order.recipients[0].tasks.map(t => {
                const taskItem = order.taskItems.find(ti => ti.id === t.id)!;
                return {
                    taskSettingsId: t.id,
                    invoiceOptionId: taskItem.paymentMethod === 'cc' ? undefined : taskItem.paymentMethod as number,
                    descriptions: t.descriptions!,
                    paymentDescription: taskItem.paymentMethod === 'cc' ? getPaymentDescription('creditcard') : `${getPaymentDescription('invoice')} ${
                        org.invoiceOptions.find(option => option.id === taskItem.paymentMethod)!
                            .name
                    }`
                };
            }),
            braintreeDetails: ccPaymentTasks.length > 0
                ? {
                    nonce: nonce,
                    deviceData: deviceData
                } : undefined
        }));
    }

    const getNextDisabledStatus = () => {
        if (loading) return true;
        if (ccPaymentTasks.length > 0) {
            return !(ccNumber.length > 12 && month && year && cvc && name && termsAccepted);
        } else {
            return !termsAccepted;
        }
    };

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <h2>Payment</h2>

                {invoiceEntities.length > 0 &&
                    invoiceEntities.map((entity, i) => {
                        const taskItems = order.taskItems.filter(
                            item =>
                                org.invoiceOptions.find(option => option.id === item.paymentMethod)?.invoiceEntity
                                    .id === entity.id
                        );
                        return (
                            <React.Fragment key={entity.id}>
                                <p>Tasks to be invoiced to {entity.name}:</p>
                                <PaymentBlock items={taskItems} hidePrice />
                                {i + 1 !== invoiceEntities.length && <hr />}
                            </React.Fragment>
                        );
                    })}

                {invoiceEntities.length > 0 && (invoiceToMeTasks.length > 0 || ccPaymentTasks.length > 0) && <hr />}

                {invoiceToMeTasks.length > 0 && (
                    <>
                        <p>Tasks to be invoiced to me:</p>
                        <PaymentBlock items={invoiceToMeTasks} />
                    </>
                )}

                {invoiceToMeTasks.length > 0 && ccPaymentTasks.length > 0 && <hr />}

                {ccPaymentTasks.length > 0 && (
                    <>
                        <p>Tasks payable by credit card:</p>
                        <CcPaymentBlock
                            items={ccPaymentTasks}
                            ccNumber={ccNumber}
                            month={month}
                            year={year}
                            cvc={cvc}
                            name={name}
                            onCcNumberChange={val => setCcNumber(val)}
                            onMonthChange={val => setMonth(val)}
                            onYearChange={val => setYear(val)}
                            onCvcChange={val => setCvc(val)}
                            onNameChange={val => setName(val)}
                        />
                    </>
                )}

                <div className={styles.termsSection}>
                    <p className={styles.small}>
                        Please view the{' '}
                        <a href="https://www.olasio.com/source/privacypolicy" target="_blank">
                            Olasio Personal Information Collection Statement
                        </a>
                        .
                    </p>
                    <Checkbox defaultValue={false} light onChange={v => setTermsAccepted(v)}>
                        <p className={styles.small}>
                            I confirm that I am authorised to order these services on behalf of this organization and
                            agree to the{' '}
                            <a
                                href="https://www.olasio.com/source/termsofservice"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                target="_blank"
                            >
                                Service Terms
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.olasio.com/source/privacypolicy"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                            .
                        </p>
                    </Checkbox>
                    {orderError && <p className={styles.error}>{orderError}</p>}
                </div>

                <footer className={styles.nav}>
                    <button
                        className={`button default ${styles.next}`}
                        disabled={getNextDisabledStatus()}
                        onClick={handleNext}
                    >
                        {loading ? <Spinner small /> : 'Confirm order'}
                    </button>
                    <button className={`button disguise ${styles.back}`} onClick={() => dispatch(goBack())}>
                        <img src={chevronLeft} alt="" />
                        Back
                    </button>
                </footer>
            </div>
        </div>
    );
};
