import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import style from './taskTile.module.scss';
import { MarketplaceTask } from '../../models/marketplaceTask';
import { ApplicationState } from '../../store';
import { Loading } from '../loading';
import { useHistory } from 'react-router-dom';

export const TaskTile: React.FC<Props> = ({ task, fixedSize, hideActions, showBorder, anon, onAdd, onRemove }) => {
    const { id, name, bannerImageUrl, description, priceToAdd, pricePerUse, addedUtc, comingSoon } = task;
    const url = `/store/task/${id}`;

    const history = useHistory();
    const handleAdd = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onAdd) onAdd(id);
    }
    const handleRemove = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onRemove) onRemove(id);
    };
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        history.push(url);
        console.log(url);
    }

    const loading = useSelector((state: ApplicationState) =>
        state.marketplaceState.addingTaskIds.indexOf(id) !== -1 ||
        state.marketplaceState.removingTaskIds.indexOf(id) !== -1);

    const getPriceBeforeGST = (price) => {
        const tax = Math.round((100 * price) / 11) / 100;
        return (Math.round(100 * (price - tax)) / 100).toFixed(2);
    }

    return <div
        className={cn(style.tile, { [style.anon]: anon, [style.fixed]: fixedSize, [style.short]: hideActions, [style.border]: showBorder })}
        onClick={(comingSoon || anon || hideActions) ? undefined : handleClick}>
        <div className={cn(style.imgContainer, { [style.filter]: task.comingSoon })}>
            <img src={bannerImageUrl} alt={name} />
        </div>
        <div className={style.tileBody}>
            <h3>{name}</h3>
            <div className={style.tileContentContainer}>
                <div className={style.tileContent}>{description}</div>
            </div>
            <div className={style.price}>
                {comingSoon ?
                    <strong>Coming soon...</strong> : anon ? '' :
                    <><strong>{priceToAdd ? `$${priceToAdd.toFixed(2)}` : 'Free'} to add, {pricePerUse ? `$${getPriceBeforeGST(pricePerUse)} + GST` : 'free'}</strong>/use</>
                }
            </div>
            {loading ? <div className={style.loadingContainer}><Loading /></div> : !hideActions &&
                <div className={style.actions}>
                    {anon ? <div className={style.addRemove}>
                        {comingSoon ?
                            <a className={`button boring ${style.enquiry}`} href='mailto:info@olasio.com'>Enquire now</a> :
                            <a className='button default' href='/signup' target='_self'>Start Task</a>
                        }
                    </div> :
                    <>
                        <div className={style.addRemove}>
                            {comingSoon ?
                                <a className={`button boring ${style.enquiry}`} href='mailto:info@olasio.com'>Enquire now</a> :
                            addedUtc ?
                                <button onClick={handleRemove} className='button boring'>Remove</button> :
                                <button onClick={handleAdd} className='button default'><img src='/images/plus.svg' alt='+' /> Add</button>}
                        </div>
                        {comingSoon ? '' : <a href={url} onClick={(e) => e.stopPropagation()}>View details</a>}
                    </>
                    }
                </div>
            }
        </div>
    </div>
}

interface Props {
    task: MarketplaceTask;
    fixedSize?: boolean;
    hideActions?: boolean;
    showBorder?: boolean;
    anon?: boolean;
    onAdd?: (id: number) => void;
    onRemove?: (id: number) => void;
}
