import { createStore, applyMiddleware, compose, combineReducers, Store, StoreEnhancerStoreCreator, ReducersMapObject } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { ApplicationState, reducers } from './index';
import { History } from 'history';

export default function configureStore(history: History, initialState?: any) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    // If devTools is installed, connect to it
    const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancerStoreCreator<any>;
    const composedEnhancers = compose(
        applyMiddleware(thunk, routerMiddleware(history)),
        devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next
    );

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer(reducers, history);
    const store = createStore(allReducers, initialState, composedEnhancers) as unknown as Store<ApplicationState>;

    return store;
}

function buildRootReducer(allReducers: ReducersMapObject, history: History) {
    return combineReducers<any>(
        Object.assign({
            router: connectRouter(history)
        }, allReducers));
}
