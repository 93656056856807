import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import './signup.scss';

class SignUpPage extends React.Component<PageProps, PageState> {
    render() {
        return (
            <div className='signup-page'>
                <form className='form' onSubmit={this.submit}>
                    <input type='text' placeholder='Name' name='name' />
                    <input type='email' placeholder='Email Address' name='email' />
                    <input type='tel' placeholder='Phone' name='phone' />
                    <input type='password' placeholder='Password' name='password' />
                    <input type='password' placeholder='Confirm Password' name='password_confirm' />

                    <button className='button primary' type='submit'>
                        Sign up
                    </button>
                </form>
            </div>
        );
    }

    constructor(props: PageProps) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    submit(e: React.FormEvent<HTMLElement>) {
        e.preventDefault();

        // TODO: Stuff
    }
}

interface PageProps {
    //store: 
}

interface PageState {

}

export default connect(
    (state: ApplicationState) => ({
        store: state.orderState
    }),
    (dispatch: Dispatch) => {
        return {
            actions: bindActionCreators(userActionCreators, dispatch)
        };
    }
)(SignUpPage);
