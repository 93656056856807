import * as React from 'react';
import './navBar.scss';
import { Tooltip } from './tooltip';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { UserState } from '../store/users';
import { modalActionCreators, ModalState } from '../store/modal';
import { Loading } from './loading';
import { Nav } from './nav';
import logo from '../assets/logo-olasio-o.svg';

class NavBar extends React.PureComponent<NavProps, NavState> {
    render() {
        return (
            <div className='navbar'>
                {this.props.special ?
                    <img src={logo} alt='Olasio Source' className='special-logo' /> :
                    <Link className='logo' to='/tasks'>
                        <img src='/images/olasio-logo.svg' alt='Olasio Source' />
                    </Link>
                }
                {this.props.special ?
                    <h2 className='nav-title'>Send Smart Task</h2> :
                    <nav>
                        <Nav />
                    </nav>
                }
                {this.props.userStore.user ?
                <>
                    {!this.props.special && <Link className='button primary nav-button' to='/new-task'>
                        <img src='/images/new-task.svg' width='28' alt='' />
                        Send Request
                    </Link>}
                    {this.props.special ?
                        <Link to='/tasks' className='special-cancel'>Cancel</Link> :
                        <div className={`user-drop-down tooltip-target ${this.state.dropdown ? 'active' : ''}`}>
                            <img
                                ref={this.tooltipRef}
                                src='/images/icon-menu.svg'
                                alt='User Options'
                                onClick={this.toggleDropdown}
                                />
                            <Tooltip
                                closeCallback={this.closeDropdown}
                                top={false}
                                active={this.state.dropdown}
                                align='left'
                                light={true}>
                                <div className='tooltip-action-list mobile'>
                                    <Nav />

                                </div>
                                <div className='tooltip-action-list'>
                                    {this.props.userStore.user.role === 'Admin' ? <button className='link-button' onClick={this.showInviteModal}>Invite people</button> : ''}
                                    {this.props.userStore.user.role === 'Admin' ? <Link to="/manageteam">Manage team</Link> : ''}
                                    {/* <Link to="/settings">Settings</Link> */}
                                    <a href='https://olasio.zendesk.com/hc/en-au' target='_blank'>Help Centre</a>
                                    <a href='mailto:help@olasio.com'>Contact support</a>
                                    <button onClick={() => this.props.userActions.logout()}>Log out</button>
                                </div>
                            </Tooltip>
                        </div>
                    }
                </> :
                <Link to='/tasks' className='button default nav-button' style={{ marginRight: '30px' }}>
                    <img src='/images/icon-menu.svg' width='28' alt='' />
                    Login
                </Link>}
            </div>
        );
    }

    constructor(props: NavProps) {
        super(props);

        this.state = {
            dropdown: false
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.showInviteModal = this.showInviteModal.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.openDropdown = this.openDropdown.bind(this);

        this.tooltipRef = React.createRef();
    }

    tooltipRef: React.RefObject<HTMLImageElement>;

    toggleDropdown() {
        if (this.state.dropdown)
            this.closeDropdown();
        else
            this.openDropdown();
    }

    closeDropdown(e?: MouseEvent) {
        if (e && this.tooltipRef.current) {
            let el = this.tooltipRef.current;
            if (e.target !== el)
                this.setState({ dropdown: false });
        }
        else
            this.setState({ dropdown: false });
    }

    openDropdown() {
        this.setState({ dropdown: true });
    }

    showInviteModal() {
        this.props.modalActions.showModal(<InviteModal
            cancel={() => {
                this.props.modalActions.hideModal();
            }}
            confirm={(name, email, done) => {
                this.props.userActions.requestInviteUser(name, email, done);
            }}
            />, 'Invite people');
    }
}

const InviteModal: React.FC<InviteModalProps> = (props) => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailValid, setEmailValid] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [complete, setComplete] = React.useState(false);

    return complete ?
        <>
            <p>Sent!</p>
        </> : loading ?
        <>
            <Loading text='Sending...' />
        </> :
        <>
            <p>Send invitation to a new user by entering their name and email address.</p>
            <div className='form modal-form'>
                <label>
                    <span>Name</span>
                    <input type='text' placeholder='Name' onChange={(e) => setName(e.target.value)} />
                </label>
                <label>
                    <span>Email</span>
                    <input type='email' placeholder='Email address' onChange={(e) => {
                        setEmailValid(e.target.checkValidity());
                        setEmail(e.target.value);
                        }} />
                </label>
            </div>
            <footer>
                <button className='button boring' onClick={props.cancel}>Cancel</button>
                <button className='button default' disabled={!name || !email || !emailValid} onClick={() => {
                    if (name && email && emailValid)
                        setLoading(true);
                        props.confirm(name, email, (result) => {
                            if (result) {
                                setLoading(false);
                                setComplete(true);
                                setTimeout(() => {
                                    props.cancel();
                                }, 1000);
                            }
                            else {
                                setLoading(false);
                            }
                        });
                }}>Confirm</button>
            </footer>
        </>;
}

interface NavProps {
    userStore: UserState;
    userActions: typeof userActionCreators;
    modalStore: ModalState;
    modalActions: typeof modalActionCreators;
    special?: boolean;
}

interface NavState {
    dropdown: boolean;
}

interface InviteModalProps {
    cancel: () => void;
    confirm: (name: string, email: string, onDone: (success: boolean) => void) => void;
}

export default connect(
    (state: ApplicationState) => ({
        userStore: state.userState,
        modalStore: state.modalState
    }),
    (dispatch: Dispatch) => {
        return {
            userActions: bindActionCreators(userActionCreators, dispatch),
            modalActions: bindActionCreators(modalActionCreators, dispatch)
        };
    }
)(NavBar);
