const API_KEY = 'AIzaSyCvx3IBi8PSOGAb_e2FZl9y-fIyx6RDC9I';

export const initPlacesLibrary = () =>
  new Promise<void>(resolve => {
    if (window.google?.maps?.places) {
      resolve();
    } else {
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
      script.onload = () => resolve();
    }
  });
