import axios from 'axios';
import { MarketplaceTask, MarketplaceTaskDetails } from '../models/marketplaceTask';

const marketplaceApi = axios.create({
    baseURL: `/v1/marketplace/`,
});

export const getMarketplaceTasks = (token?: string) => {
    if (token) {
        return marketplaceApi.get<MarketplaceTask[]>('/tasks', {
            headers: {
                Authorization: `bearer ${token}`,
            },
        });
    } else {
        return marketplaceApi.get<MarketplaceTask[]>('/public/tasks');
    }
};

export const getMarketplaceTaskDetails = (token: string, taskId: number) =>
    marketplaceApi.get<MarketplaceTaskDetails>(`/task/${taskId}`, {
        headers: {
            Authorization: `bearer ${token}`,
        },
    });

export const getMarketplaceTaskSuggestions = (token: string, taskId: number) =>
    marketplaceApi.get<MarketplaceTask[]>(`/tasks/suggestions/${taskId}`, {
        headers: {
            Authorization: `bearer ${token}`,
        },
    });

export const addMarketplaceTask = (token: string, taskId: number) =>
    marketplaceApi.post<number>(`/task/${taskId}`, null, {
        headers: {
            Authorization: `bearer ${token}`,
        },
    });

export const removeMarketplaceTask = (token: string, taskId: number) =>
    marketplaceApi.delete<boolean>(`/task/${taskId}`, {
        headers: {
            Authorization: `bearer ${token}`,
        },
    });

export const getMarketplaceTags = (token: string) =>
    marketplaceApi.get<string[]>('/tags', {
        headers: {
            Authorization: `bearer ${token}`,
        },
    });

export const getMarketplaceTasksForOnboarding = (token: string, tags: string[]) =>
    marketplaceApi.get<MarketplaceTask[]>('/tasks/onboarding', {
        headers: {
            Authorization: `bearer ${token}`,
        },
        params: {
            tags,
        },
    });

export const addTag = (token: string, name: string) =>
    marketplaceApi.post<string>(
        `/tag/${name}`,
        {},
        {
            headers: {
                Authorization: `bearer ${token}`,
            },
        }
    );  