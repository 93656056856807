import * as React from 'react';
import { ApplicationState } from '../../store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ModalState, modalActionCreators } from '../../store/modal';
import './modal.scss';

class ModalRoot extends React.Component<RootProps> {
    render () {
        return <div className={`modal-overlay ${this.props.store.current ? 'active' : ''}`} onClick={() => this.onOverlayClick}>
            {this.props.store.current ?
            <div className='modal-window' onClick={(e) => e.stopPropagation()}>
                <button onClick={this.close} className='closer'><img src='/images/icon-close.svg' /></button>
                <header>
                    {this.props.store.current.title ? <h2>{this.props.store.current.title}</h2> : ''}
                </header>
            <div className='modal-body'>
                    {this.props.store.current.body}
                </div>
            </div> : '' }
        </div>
    }

    constructor(props: RootProps) {
        super(props);

        this.state = {
            //
        };

        this.close = this.close.bind(this);
    }

    componentDidMount() {
        window.onkeydown = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape' || e.keyCode === 27) {
                if (this.props.store.current) {
                    this.close();
                }
            }
        }
    }

    onOverlayClick(e: MouseEvent){
        if(e.target === document.getElementById('modal-overlay'))
            this.close();
    }

    close() {
        this.props.actions.hideModal();
    }
}

interface RootProps {
    store: ModalState;
    actions: typeof modalActionCreators;
}

export default connect(
    (state: ApplicationState) => ({
        store: state.modalState
    }),
    (dispatch: Dispatch) => {
        return {
            actions: bindActionCreators(modalActionCreators, dispatch)
        };
    }
)(ModalRoot);
