import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import styles from './marketplaceHome.module.scss';
import '../components/pagination.scss';
import { addMarketplaceTask, removeMarketplaceTask } from '../store/marketplace';
import { TaskTile } from '../components/marketplace/taskTile';
import { Filter } from '../components/marketplace/filter';
import { FullscreenFilter } from '../components/marketplace/fullscreenFilter';
import { getMarketplaceTasks, confirmAdd } from '../store/marketplace';
import { MarketplaceTask } from '../models/marketplaceTask';
import { Loading } from '../components/loading';
import { Toast } from '../components/toast';
import { NoList } from '../components/nolist';
import { RemoveTaskModal } from '../components/marketplace/removeTaskModal';
import { modalActionCreators } from '../store/modal';
import { NccTermsModal } from '../components/marketplace/nccTermsModal';

export const MarketplaceHome = () => {
    const tasks = useSelector((state: ApplicationState) => state.marketplaceState.tasks);
    const isLoading = useSelector((state: ApplicationState) => state.marketplaceState.isLoading);

    const [tagFilter, setTagFilter] = useState(AllTagsFilter);
    const [statusFilter, setStatusFilter] = useState(AllStatusFilter);
    const [showMobileFilter, setShowMobileFilter] = useState(false);

    const [visibleTasks, setVisibleTasks] = useState<MarketplaceTask[]>([]);
    const [featuredTasks, setFeaturedTasks] = useState<MarketplaceTask[]>([]);

    const dispatch = useDispatch();

    // get tasks list
    useEffect(() => {
        dispatch(getMarketplaceTasks());
    }, [dispatch]);

    useEffect(() => {
        let main = tasks
            .filter(task => {
                if (tagFilter === AllTagsFilter) return true;
                return task.tags.includes(tagFilter);
            })
            .filter(task => {
                if (statusFilter === AllStatusFilter) return true;
                return !!task.addedUtc; // show added only
            })
            .sort(sort);
        let featured: MarketplaceTask[] = [];

        if (tagFilter === AllTagsFilter && statusFilter === AllStatusFilter) {
            const featuredTaskNames = [
                'Car Verification',
                'Identity Verification',
                'Plant & Equipment Verification'
            ];

            featured = tasks
                .filter(task => {
                    return featuredTaskNames.indexOf(task.name) !== -1;
                })
                .sort(sort);
        }
        else
            featured = [];

        setVisibleTasks(main);
        setFeaturedTasks(featured);
    }, [tasks, tagFilter, statusFilter]);

    // get tags list
    const tags = getTagsFromTasks(tasks);

    const [notification, setNotification] = useState<JSX.Element | null>(null);
    const handleTaskAdded = (name: string) => {
        setNotification(<span>"<strong>{name}</strong>" has been added to your task list</span>);
    };
    const handleTaskRemoved = (name: string) => {
        setNotification(<span>"<strong>{name}</strong>" has been removed from your task list</span>);
    };
    const handleAddTask = (id: number) => {
        const task = tasks.filter(t => t.id === id)[0];
        if (task.externalTermsType)  {
            switch (task.externalTermsType) {
                case 'NCC':
                    dispatch(modalActionCreators.showModal(<NccTermsModal
                        onConfirm={() => {
                            try {
                                dispatch(modalActionCreators.hideModal());
                                dispatch(addMarketplaceTask(id));
                            }
                            catch (err) {
                                console.log("err", err);
                            }
                        } }
                        taskId = {id}
                        />, 'Accept the Terms & Conditions'));
                    break;
            }
        }
        else
            dispatch(addMarketplaceTask(id));
    };
    const handleRemoveTask = (id: number) => {
        dispatch(modalActionCreators.showModal(
            <RemoveTaskModal onConfirm={() => {
                dispatch(removeMarketplaceTask(id));
            }} />,
            'Are you sure you want to remove this task?'
        ));
    };

    const added = useSelector((state: ApplicationState) =>
        state.marketplaceState.addedTask ?
            state.marketplaceState.tasks.find(t => t.id === state.marketplaceState.addedTask)
            : undefined);
    const removed = useSelector((state: ApplicationState) =>
        state.marketplaceState.removedTask !== undefined ?
            state.marketplaceState.tasks.find(t => t.id === state.marketplaceState.removedTask)
            : undefined);

    useEffect(() => {
        if (added) {
            handleTaskAdded(added.name);
        }
    }, [added]);

    useEffect(() => {
        if (removed) {
            handleTaskRemoved(removed.name);
        }
    }, [removed]);

    if (showMobileFilter) {
        return (
            <FullscreenFilter
                onClose={() => setShowMobileFilter(false)}
                filters={[
                    {
                        label: 'Industry',
                        options: [AllTagsFilter, ...tags],
                        selected: tagFilter,
                        onChange: option => setTagFilter(option),
                    },
                    {
                        label: 'Filter',
                        options: [AllStatusFilter, 'Show added only'],
                        selected: statusFilter,
                        onChange: option => setStatusFilter(option),
                    },
                ]}
                resultCount={visibleTasks.length}
            />
        );
    }

    console.log(tagFilter, statusFilter);

    return (
        <>
            <Toast message={notification} onDismiss={() => {
                setNotification(null);
                if (added) dispatch(confirmAdd(added.id))
                if (removed) dispatch(confirmAdd(removed.id))
            }} />
            <div className={styles.banner}>
                <h1>Smart Task Store</h1>
                <button className={styles.filterBtn} onClick={() => setShowMobileFilter(true)}>
                    <img src="/images/filter.svg" alt="" />
                    Filters
                </button>
            </div>
            <div className={styles.body}>
                <div className={styles.filter}>
                    <Filter
                        label="Industry"
                        onChange={option => setTagFilter(option)}
                        options={[AllTagsFilter, ...tags]}
                    />
                    <hr />
                    <Filter
                        label="Filter"
                        onChange={option => setStatusFilter(option)}
                        options={[AllStatusFilter, "Show added only"]}
                    />
                </div>
                <div className={styles.taskContainer}>
                    {
                        featuredTasks && featuredTasks.length > 0 ?
                        <div className={styles.featuredTasks}>
                            <h2>Featured</h2>
                            <div className={styles.tasks}>
                                {featuredTasks
                                    .map(task => (
                                    <div key={task.id} className={styles.tile}>
                                        <TaskTile task={task} onAdd={handleAddTask} onRemove={handleRemoveTask} />
                                    </div>
                                ))}
                            </div>
                        </div> : ''
                    }
                    {featuredTasks && featuredTasks.length > 0 ? <h2>All tasks</h2> : ''}
                    <div className={styles.tasks}>
                        {(visibleTasks && visibleTasks.length > 0) ?
                            visibleTasks.map(task => (
                                <div key={task.id} className={styles.tile}>
                                    <TaskTile task={task} onAdd={handleAddTask} onRemove={handleRemoveTask} />
                                </div>
                            )) :
                        isLoading ?
                        <div className={styles.loadingContainer}><Loading text='Loading...' /></div>
                        : <NoList
                            title='You haven’t added any tasks yet'
                            description='Please check all available tasks and you can add the tasks that you need from the list.'
                            imgLink="/images/empty-mp-list.svg"
                        ></NoList>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

const AllTagsFilter = 'All industries';
const AllStatusFilter = 'Show all';

const getTagsFromTasks = (tasks: MarketplaceTask[]) => {
    const tags = tasks.flatMap(task => task.tags);
    const uniqueTags = Array.from(new Set(tags));
    return uniqueTags;
};

const sort = (a: MarketplaceTask, b: MarketplaceTask) => {
    if (a.comingSoon && !b.comingSoon)
        return 1;
    if (!a.comingSoon && b.comingSoon)
        return -1;

    if (a.name > b.name)
        return 1;
    if (b.name > a.name)
        return -1;
    return 0;
}
