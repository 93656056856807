import * as React from 'react';
import './infoTile.scss';
import { Tooltip } from './tooltip';

export const InfoTile = (props: Props) => {
    const [helpActive, setHelp] = React.useState(false);

    return (
        <div
            className={`info-tile ${props.active ? 'active' : ''} ${props.onclick ? 'clickable' : ''}`}
            onClick={props.onclick}>
            <div className='counter'>{props.counter}</div>
            <div className='caption'>{props.caption}</div>
            {(props.help || props.helpTitle) ?
                <button
                    className='tooltip-target help-clicker'
                    onClick={(e) => { setHelp(!helpActive); e.stopPropagation(); }}>
                    <img src="/images/icon-moreinfo.svg" alt="?" />
                    <Tooltip
                        active={helpActive}
                        top={false}
                        align={'left'}
                        closeCallback={() => setHelp(false)}>
                        {props.helpTitle ? <h4>{props.helpTitle}</h4> : ''}
                        {props.help ? <p>{props.help}</p> : ''}
                    </Tooltip>
                </button> :
                ''}
            <img className='icon' src={props.icon} alt={props.caption} />
        </div>
    );
}

interface Props {
    counter: string | number;
    caption: string;
    icon: string;
    help?: string;
    helpTitle?: string;
    active?: boolean;
    onclick?: () => void;
}
