import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { userActionCreators } from '../store/users/actionCreators';
import { UserState } from '../store/users';
import { SlimCentre } from '../components/slimCentre';
import { UserList } from '../components/users/userList';
import { Loading } from '../components/loading';

class ManageTeamPage extends React.Component<PageProps, PageState> {
    render() {
        if (this.props.store.user) {
            return (
                <div className='manage page standalone'>
                    <SlimCentre>
                        <h2>Manage team</h2>
                        {this.props.store.userList ?
                            <UserList
                                users={this.props.store.userList}
                                viewingUser={this.props.store.user.id}
                                onRemoveUser={this.removeUser}
                                onSetUserRole={this.onSetUserRole}
                                /> :
                            <Loading text='Loading users...' />
                        }
                    </SlimCentre>
                </div>
            );
        }
        else {
            this.props.actions.returnHome();
            return <div>Something went wrong. Please refresh the page.</div>
        }
    }
    // eslint-disable-next-line
    constructor(props: PageProps) {
        super(props);

        this.removeUser = this.removeUser.bind(this);
        this.onSetUserRole = this.onSetUserRole.bind(this);
    }

    componentDidMount() {
        this.props.actions.requestUserList();
    }

    removeUser(userId: string) {
        this.props.actions.requestRemoveUser(userId);
    }

    onSetUserRole(userId: string, role: string) {
        this.props.actions.requestSetUserRole(userId, role);
    }
}

interface PageProps {
    store: UserState;
    actions: typeof userActionCreators;
}

interface PageState {

}

export default connect(
    (state: ApplicationState) => ({
        store: state.userState
    }),
    (dispatch: Dispatch) => {
        return {
            actions: bindActionCreators(userActionCreators, dispatch)
        };
    }
)(ManageTeamPage);
