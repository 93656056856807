import { AppThunkAction } from "..";
import { OrderStateKnownAction } from ".";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { LogOut } from "../users/actions";
import { OrderSummary, Order } from "../../models/order";
import { InProgressOrder, SubmitOrder, TaskItem } from "../../models/inProgressOrder";
import { OrderCreate } from "../../models/orderCreate";
import { OrderInvoice } from "../../models/orderInvoice";

type KnownAction = OrderStateKnownAction | CallHistoryMethodAction | LogOut | Function;

export const orderActionCreators = {
    requestOrders: (status: string, pageOffset: number, pageSize: number, search: string, sortBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;
        if (user && user.token) {
            dispatch({ type: 'REQUEST_ORDERS' });

            // We have left these breaks out on purpose
            let sortCol = 'createdutc', sortDir = 'desc';
            switch (sortBy) {
                case 'lastActivity':
                    sortDir = 'asc';
                    /* esline-disable no-fallthrough */
                case 'lastActivityDesc':
                    sortCol = 'updatedutc';
                    break;
                case 'created':
                    sortDir = 'asc';
                    /* esline-disable no-fallthrough */
                case 'createdDesc':
                    sortCol = 'createdutc';
                    break;
            }

            const url = `/v1/orders?` +
                `user=${user.id}&` +
                `status=${status}&` +
                `pageOffset=${pageOffset}&` +
                `pageSize=${pageSize}&` +
                `search=${search}&` +
                `sortOn=${sortCol}&` +
                `sortOrder=${sortDir}`;
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                }
            })
                .then((response) => {
                    if (response.ok)
                        return response.json() as Promise<OrderSummary>;
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then((orders?: OrderSummary) => {
                    if (orders) {
                        dispatch({
                            type: 'RECEIVE_ORDERS',
                            orders: orders
                        });
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err);
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    archiveOrder: (id: number, status: string, pageOffset: number, pageSize: number, search: string, sortBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            dispatch({
                type: 'REMOVE_ORDER',
                orderId: id
            });

            let sortCol = 'createdutc', sortDir = 'desc';
            switch (sortBy) {
                case 'lastActivity':
                    sortDir = 'asc';
                case 'lastActivityDesc':
                    sortCol = 'updatedutc';
                    break;
                case 'created':
                    sortDir = 'asc';
                case 'createdDesc':
                    sortCol = 'createdutc';
                    break;
            }

            const url = `/v1/orders/${id}/archive?user=${user.id}&status=${status}&pageOffset=${pageOffset}&pageSize=${pageSize}&search=${search}&sortOn=${sortCol}&sortOrder=${sortDir}`;
            fetch(url, {
                method: 'put',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(orders => {
                    if (orders) {
                        dispatch({
                            type: 'RECEIVE_ORDERS',
                            orders: orders
                        });
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err);
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    cancelOrder: (id: number, status: string, pageOffset: number, pageSize: number, search: string, sortBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            dispatch({
                type: 'REMOVE_ORDER',
                orderId: id
            });

            let sortCol = 'createdutc', sortDir = 'desc';
            switch (sortBy) {
                case 'lastActivity':
                    sortDir = 'asc';
                case 'lastActivityDesc':
                    sortCol = 'updatedutc';
                    break;
                case 'created':
                    sortDir = 'asc';
                case 'createdDesc':
                    sortCol = 'createdutc';
                    break;
            }

            const url = `/v1/orders/${id}/cancel?user=${user.id}&status=${status}&pageOffset=${pageOffset}&pageSize=${pageSize}&search=${search}&sortOn=${sortCol}&sortOrder=${sortDir}`;
            fetch(url, {
                method: 'put',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(orders => {
                    if (orders) {
                        dispatch({
                            type: 'RECEIVE_ORDERS',
                            orders: orders
                        });
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    alert('Whoops, something went wrong. Please try again later.');
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    nudgeOrder: (id: number, detail: string, email: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (email) {
            // TODO: Send email to detail
        }
        else {
            // TODO: Send sms to detail
        }
    },

    loadArchive: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'REQUEST_ARCHIVE'
        });
        const user = getState().userState.user;

        if (user && user.token) {
            const url = `/v1/orders/archive`;
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(orders => {
                    if (orders) {
                        dispatch({
                            type: 'RECEIVE_ARCHIVE',
                            orders: orders
                        });
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err);
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    requestNewOrder: (order: SubmitOrder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'ORDER_ERROR',
            message: undefined
        });

        const user = getState().userState.user;

        if (user && user.token) {
            /*order.taskItems.forEach(t => {
                t.reportId = _surveyTemplates[t.surveyId][user.organisationId]
            });*/

            const url = `/v1/orders`;
            fetch(url, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify(order)
            })
            .then(response => {
                if (response.ok)
                    return response.json() as Promise<Order[]>;
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text; });
            })
            .then(orders => {
                // Update the order list
                //dispatch(orderActionCreators.requestOrders('active', 1, 10, '', ''));

                if (orders) {
                    dispatch({
                        type: 'RECEIVE_NEW_ORDERS',
                        orders: orders
                    })
                    dispatch(push(`/task/success`));
                }
                else
                    dispatch(push('/'));
            })
            .catch(err => {
                dispatch({
                    type: 'ORDER_ERROR',
                    message: err ?? 'Something went wrong, please try again.'
                });
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    requestNewSingleOrder: (orderCreate: OrderCreate): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'ORDER_ERROR',
            message: undefined
        });

        const user = getState().userState.user;

        if (user && user.token) {
            const url = `/v1/orders/single`;
            fetch(url, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify(orderCreate)
            })
            .then(response => {
                if (response.ok)
                    return response.json() as Promise<Order>;
                else if (response.status === 401 || response.status === 403) {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    dispatch(push('/login'));
                    return Promise.resolve(undefined);
                }
                else
                    return response.text().then(text => { throw text; });
            })
            .then(order => {
                if (order) {
                    dispatch({
                        type: 'RECEIVE_NEW_ORDERS',
                        orders: [order]
                    })
                    dispatch(push(`/new-task/success`));
                }
                else
                    dispatch(push('/'));
            })
            .catch(err => {
                dispatch({
                    type: 'ORDER_ERROR',
                    message: err ?? 'Something went wrong, please try again.'
                });
            });
        }
        else {
            dispatch(push('/login'));
        }
    },

    saveAndProgressOrder: (order: InProgressOrder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'RECEIVE_ORDER_INPROGRESS',
            order: order
        });
        dispatch(push('/new-task/payment'));
    },

    // same action as above, but links to the new payment flow for single orders
    saveAndProgressSingleOrder: (order: InProgressOrder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'RECEIVE_ORDER_INPROGRESS',
            order: order
        });
        dispatch(push('/new-task/payment-method'));
    },

    savePaymentMethodsAndProgress: (paymentSelections: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const order = getState().orderState.inProgressOrder;
        dispatch({
            type: 'RECEIVE_ORDER_INPROGRESS',
            order: {
                ...order!,
                taskItems: order!.taskItems.map((item, i) => ({
                    ...item,
                    paymentMethod: paymentSelections[i] === 'cc' ? 'cc' : parseInt(paymentSelections[i]),
                }))
            }
        });
        dispatch(push('/new-task/payment-single'));
    },

    saveTaskListAndProgress: (taskItems: TaskItem[], multiple: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let order = getState().orderState.inProgressOrder;
        dispatch({
            type: 'RECEIVE_ORDER_INPROGRESS',
            order: {
                ...(order || { reason: '', recipients: [] }),
                taskItems: taskItems,
                notifyMe: false
            }
        });

        if (multiple)
            dispatch(push('/new-task/bulk'));
        else
            dispatch(push('/new-task/single'));
    },

    clearInProgressOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'CLEAR_ORDER_INPROGRESS'
        });
    },

    startNewOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(orderActionCreators.clearInProgressOrder());
        dispatch(push('/new-task'));
    },

    backToOrderStepOne: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(push('/new-task'));
    },

    shareOrder: (id: number, email: string, tasks: number[], password: string, message: string, onDone: (result: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            dispatch({
                type: 'START_SHARE_ORDER'
            });

            const url = `/v1/reports/${id}/share`;
            fetch(url, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify({
                    email: email,
                    tasks: tasks,
                    password: password,
                    message: message
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(() => {
                    dispatch({
                        type: 'FINISH_SHARE_ORDER'
                    });
                    onDone(true);
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err);
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    notifyUser: (id: number, name: string, email: string, message: string, onDone: (result: boolean) => void, onError: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            dispatch({
                type: 'START_NOTIFY_USER'
            });

            const url = `/v1/orders/${id}/notifyuser`;
            fetch(url, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(() => {
                    dispatch({
                        type: 'FINISH_NOTIFY_USER'
                    });
                    onDone(true);
                })
                .catch((err: any) => {
                    console.error(err);
                    onError();
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    editContact: (id: number, name: string, email: string, phone: string, onDone: (result: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            dispatch({
                type: 'START_EDIT_CONTACT'
            });

            const url = `/v1/orders/${id}/editcontact`;
            fetch(url, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone: phone
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(() => {
                    dispatch({
                        type: 'FINISH_EDIT_CONTACT'
                    });
                    onDone(true);
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err);
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    reIssueTask: (orderId: number, taskId: number, reason: string, onDone: (result: boolean) => void, onError: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;
        if (user && user.token) {
            dispatch({
                type: 'START_REISSUE_TASK'
            });

            const url = `/v1/orders/${orderId}/reissuetask`;
            fetch(url, {
                method: 'put',
                headers: {
                    'Content-type': 'application/json',
                    'authorization': `bearer ${user.token}`
                },
                body: JSON.stringify({
                    taskId: taskId,
                    reason: reason
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text; });
                })
                .then(() => {
                    dispatch({
                        type: 'FINISH_REISSUE_TASK'
                    });
                    onDone(true);
                })
                .catch((err: any) => {
                    console.error(err);
                    onError();
                });
        }
        else {
            dispatch(push('/login'));
        }
    },

    requestInvoice: (invoiceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'REQUEST_INVOICE' });
            const url = `/v1/invoice/${invoiceId}`;
            fetch (url, {
                method: 'get',
                headers: {
                    'Content-type': 'application/json'
                }
            })
                .then((response) => {
                    if (response.ok)
                        return response.json() as Promise<OrderInvoice>;
                    else
                        return response.text().then(text => { throw text; });
                })
                .then((orderInvoice?: OrderInvoice) => {
                    if (orderInvoice) {
                        dispatch({
                            type: 'RECEIVE_INVOICE',
                            orderInvoice: orderInvoice
                        });
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    alert(err);
                });
    },
}
