import * as React from 'react';
import './specialDropdown.scss';

export const SpecialDropdown: React.FC<Props> = (props) => {
    const [active, setActive] = React.useState(false);
    const [selected, setSelected] = React.useState(props.options[0]);
    const dropdown = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        let def = props.options.filter(x => x.value === props.value)[0];
        setSelected(def);
        if (dropdown.current)
            dropdown.current?.focus();
        
    }, [ props.value, props.options, dropdown ]);
    return <div className={`special-dropdown ${active ? 'active' : ''}`}>
        <button
            className={`special-dropdown-action ${props.noborder ? 'noborder' : ''}`}
            type='button'
            onClick={() => setActive(!active)}>
            {props.label ? <strong>{props.label}: </strong> : ''}{selected?.label}
        </button>
        {active && <div className='special-dropdown-content' ref={dropdown} tabIndex={1} onBlur={() => setActive(false)}>
            {props.options.map((o, i) => {
                return <button
                    key={i}
                    className={`special-dropdown-option ${props.value === o.value ? 'active' : ''}`}
                    onMouseDown={() => {
                        setSelected(o);
                        setActive(false);

                        if (props.onChange) {
                            props.onChange(o);
                        }
                    }}>
                    {o.label}
                </button>
            })}
        </div>}
    </div>
}

interface Props {
    defaultIndex?: number;
    defaultValue?: any;
    value: any;
    label: string;
    options: Option[];
    noborder?: boolean;
    onChange?: (option: Option) => void;
}

interface Option {
    label: string;
    value: any;
}
