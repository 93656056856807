import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActionCreators } from '../../store/modal';
import { CustomInput, Input } from '../core/input';
import { Loading } from '../loading';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';
import { ApplicationState } from '../../store';
import cn from 'classnames';
import styles from './nccTermsModal.module.scss';
import { Checkbox } from '../checkbox';
import { Address, AddressField } from '../custom/addressField';

type Props = {
    onConfirm: () => void;
    taskId?: number
};

export const NccTermsModal = ({ onConfirm, taskId }: Props) => {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.userState.user);

    const [businessName, setBusinessName] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [googleBusinessAddress, setGoogleBusinnessAddress] = useState<Address | undefined>(undefined);
    const [businessAddressError, setBusinessAddressError] = useState('');
    const [abn, setAbn] = useState('');
    const [fullName, setFullName] = useState('');
    const [position, setPosition] = useState('');
    const [policyUrl, setPolicyUrl] = useState('');
    const [policyUrlError, setPolicyUrlError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(398);
    const [valid, setValid] = useState(false);
    const [signatureDataUrl, setSignatureDataUrl] = useState<string | undefined>(undefined);
    const [step, setStep] = useState(1);
    const [termsUrl, setTermsUrl] = useState('');
    const [termsName, setTermsName] = useState('');
    const [nccAccepted, setNccAccepted] = useState(false);
    const [olasioAccepted, setOlasioAccepted] = useState(false);
    const [error, setError] = useState(false);

    const sigCanvasRef = createRef<ReactSignatureCanvas>();
    const fileRef = createRef<HTMLInputElement>();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        handleResize();

        fetch(`/v1/ncc/terms`, { headers: {
            'authorization': `bearer ${user?.token}`
        } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    return response.text().then(text => { throw text; });
                }
            })
            .then((data: { termsFile: string, url: string}) => {
                setTermsUrl(data.url);
                setTermsName(data.termsFile);
            });
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [ user ]);

    useEffect(() => {
        setValid(shortValidate());
    }, [ businessName, abn, fullName, position, policyUrl, signatureDataUrl, step, nccAccepted, olasioAccepted ]);

    const handleResize = () => {
        if (containerRef.current) {
            setWidth(containerRef.current.clientWidth);
        }
    }

    const shortValidate = () => {
        switch (step) {
            case 1:
                return !!(businessName && abn && fullName && position && businessAddress);
            default:
                return !!(businessName && abn && fullName && position && businessAddress && signatureDataUrl && nccAccepted && olasioAccepted);
        }
    }

    const validate = () => {
        if (!shortValidate()) {
            return false;
        }
        
        if (!policyUrl) {
            return !!(fileRef.current?.files?.length);
        }

        return true;
    }

    const validateAndSetPolicyUrl = (value: any) => {        
        var policyUrlToBeSet = value.toLowerCase();
        var regex = /(www|WWW).(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if(policyUrlToBeSet.startsWith("http") || policyUrlToBeSet.startsWith("https")){
            regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        }

        if(regex.test(policyUrlToBeSet)){
            setPolicyUrlError(false);
            setPolicyUrl(policyUrlToBeSet);
        }
        else if(value === ""){
            setPolicyUrlError(false);
        }
        else{
            setPolicyUrlError(true);
        } 
    }

    const submit = () => {
        if (!user)
            return;

        if (!validate()) {
            return;
        }

        setLoading(true);

        const data = new FormData();
        data.append('businessName', businessName);
        data.append('abn', abn);
        data.append('fullName', fullName);
        data.append('position', position);
        data.append('privacyUrl', policyUrl);
        data.append('termsFile', termsName);
        data.append('businessAddress', businessAddress);

        if (signatureDataUrl)
            data.append('signature', signatureDataUrl.split(',')[1]);
        if (fileRef.current?.files && fileRef.current?.files.length > 0)
            data.append('privacyFile', fileRef.current?.files[0]);

        const url = `/v1/ncc/billingcode`;
        fetch(url, {
            method: 'post',
            headers: {
                'Authorization': `bearer ${user.token}`
            },
            body: data
        })
        .then(response => {
            if (response.ok) {
                //Todo: Need to better approach. For now, just to be on safe side, if events trigger in different sequence
                const url = `/v1/marketplace/task/${taskId}`;
                fetch(url, {
                    method: 'post',
                    headers: {
                        'Authorization': `bearer ${user.token}`
                    },
                })
                .then(response => {
                    if (response.ok) {
                            onConfirm();        
                    }
                    else {
                        setError(true);
                        setLoading(false);
                        throw 'Failed';
                    }
                })
                .catch(err => {
                    setError(true);
                    setLoading(false);
                    return;
                });
            }
            else {
                setError(true);
                setLoading(false);
                throw 'Failed';
            }
        })
        .catch(err => {
            setError(true);
            setLoading(false);
            return;
        });
    };

    return (
        !termsUrl ? <Loading /> : <>
            {step === 1 && <div>
                <p>Please allow up to 24 hours for this task to be approved for usage.</p>
                <Input
                    label='Business Name *'
                    placeholder='e.g. Business Name Pty Ltd'
                    disabled={loading}
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    />
                <AddressField
                    label="Business Address *"
                    placeholder='e.g. 449 Punt Road, Cremorne, Vic, 3121'
                    value={businessAddress}
                    onChange={addr => setBusinessAddress(addr)}
                    onChangeAddress={addr => setGoogleBusinnessAddress(addr)}
                    error={businessAddressError}
                    onError={err => setBusinessAddressError(err ? err : '')}
                />
                <Input
                    type='number'
                    label='ABN *'
                    placeholder='e.g. 60 139 183 145'
                    disabled={loading}
                    value={abn}
                    onChange={(e) => setAbn(e.target.value)}
                    />
                <Input
                    label='Your full legal name *'
                    placeholder='e.g. John Nathan Smith'
                    disabled={loading}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    />
                <Input
                    label='Your position *'
                    placeholder='e.g. Managing Director'
                    disabled={loading}
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    />
            </div>}
            {step === 2 && <div>
                <p>Please allow up to 24 hours for this task to be approved for usage.</p>                
                <Input
                    label='Privacy Policy URL'
                    placeholder='e.g. www.businessname.com/privacy-policy'
                    type='url'
                    disabled={loading}
                    onChange={(e) => validateAndSetPolicyUrl(e.target.value)}
                    errorDesc={policyUrlError ? "Invalid url" : undefined}
                    />
                {/* Temporarily disabling this guy */}
                {/*<Input
                    label='Privacy Policy Upload'
                    type='file'
                    accept="application/pdf"
                    disabled={loading}
                    ref={fileRef}
                />*/}
                <Checkbox
                    onChange={(e) => { setNccAccepted(e.valueOf()) }}>
                    <p className={styles.resizePara}>
                        I accept the <a
                            href={termsUrl}
                            target="_blank">NCC Standard Client Agreement</a>.
                    </p>
                </Checkbox>
                <Checkbox
                    onChange={(e) => setOlasioAccepted(e.valueOf())}>
                    <p className={styles.resizePara}>
                        I accept the <a
                            href='https://www.olasio.com/source/termsofservice'
                            target="_blank">Olasio Source Service Terms</a>.
                    </p>
                </Checkbox>
                <CustomInput label='Sign here'>
                    <div className={styles.signatureContainer} ref={containerRef}>
                        <button className={cn('button', 'boring', styles.clearButton)} onClick={() => {
                            if (sigCanvasRef.current) {
                                sigCanvasRef.current.clear();
                            }
                        }}>
                            Clear
                        </button>
                        <SignatureCanvas
                            ref={sigCanvasRef}
                            penColor='black'
                            canvasProps={{ height: width > 350 ? 200 : 135, width: width }}
                            clearOnResize={true}
                            onEnd={(e) => {
                                if (sigCanvasRef.current) {
                                    if (!sigCanvasRef.current.isEmpty()) {
                                        setSignatureDataUrl(sigCanvasRef.current.toDataURL());
                                    }
                                    else {
                                        setSignatureDataUrl(undefined);
                                    }
                                }
                            }}
                        />
                    </div>
                </CustomInput>
                <p className={styles.resizePara}>By signing, you confirm that you are an authorised representative of the business identified on the previous screen.</p>
            </div>}
            <footer className={styles.footer}>
                {(loading) ? <Loading /> : <>
                    {step === 1 ?
                    <>
                        <button
                            className="button boring"
                            disabled={loading}
                            onClick={() => dispatch(modalActionCreators.hideModal())}>
                            Cancel
                        </button>
                        <button
                            className="button default"
                            disabled={loading || !valid}
                            onClick={() => { setStep(2); }}
                        >
                            Next
                        </button>
                    </> :
                    <>
                        <button
                            className="button boring"
                            disabled={loading}
                            onClick={() => setStep(1)}>
                            Back
                        </button>
                        <button
                            className="button primary"
                            disabled={loading || !valid}
                            onClick={submit}
                        >
                            Accept &amp; Submit
                        </button>
                    </>
                    }
                </>}
            </footer>
            {error ? 
            <p className={styles.error}>Something went wrong, please try again.</p> : ''
        }
        </>
    );
};