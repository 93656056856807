import * as React from 'react';
import './summary.scss';

export const Summary: React.FC<SummaryProps> = (props) => {
    return <div className={`summary-table ${(props.borderOff) ? 'no-borders' : ''}`}>
        {props.groups.map((g, index) => {
            return <div key={`${index}-${g.items.length}`} className='summary-group'>
                {g.items.map(i => <SummaryItem key={i.value} {...i} />)}
            </div>
        })}
        {props.final && <SummaryItem {...props.final} final={true} />}
    </div>
}

const SummaryItem: React.FC<SummaryItem & { final?: boolean }> = (props) => {
    return <div className={`summary-item ${props.final ? 'final' : ''}`} key={props.value}>
        <div className='label'>{props.label}</div>
        <div className='value'>{props.value}</div>
    </div>
}

interface SummaryProps {
    groups: SummaryGroup[];
    final?: SummaryItem;
    borderOff?: boolean;
}

interface SummaryGroup {
    items: SummaryItem[];
}

interface SummaryItem {
    label: string;
    value: string;
}
