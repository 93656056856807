import React from 'react';
import styles from './paymentSelect.module.scss';
import { InvoiceOption } from '../../models/organisation';

type Props = {
    label: string;
    iconSrc?: string; // Olasio logo by default
    invoiceOptions?: InvoiceOption[];
    value: string;
    onChange: (value: string) => void;
};

const OlasioLogoSrc = '/images/logo-olasio.svg';

export const PaymentSelect = ({ label, iconSrc, invoiceOptions, value, onChange }: Props) => {
    return (
        <div className={styles.container}>
            <label>
                <div className={styles.labelSection}>
                    {label}
                    <img src={iconSrc || OlasioLogoSrc} alt="" />
                </div>
                <select value={value} onChange={e => onChange(e.target.value)}>
                    {invoiceOptions && invoiceOptions.length > 0 && (
                        <optgroup label="Invoice to">
                            {invoiceOptions.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </optgroup>
                    )}
                    <optgroup label="Pay by card">
                        <option value="cc">Credit card</option>
                    </optgroup>
                </select>
            </label>
        </div>
    );
};
