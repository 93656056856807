import './addressField.scss';
import { initPlacesLibrary } from '../../utils/googleMaps';
import styles from '../core/input.module.scss';
import React, { Component, createRef } from 'react';

// places library config
const options: google.maps.places.AutocompleteOptions = {
    componentRestrictions: { country: 'au' },
    types: ['address'], // return only geocoding results with precise address
    fields: ['address_components', 'geometry'], // return only required data to avoid extra costs
};
const addressComponentsFormat = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
};

// custom validation messages
enum Error {
    Empty = 'Please provide an address',
    NoStreetNumber = 'Please provide an address with street number',
    SelectionRequired = 'Please select an address from the dropdown list',
}

type Props = {
    value: string;
    onChange: (value: string) => void;
    onChangeAddress: (address: Address) => void;
    error: string;
    onError: (error: Error | null) => void;
    placeholder?: string;
    label?: string;
};

export class AddressField extends Component<Props> {
    ref = createRef<HTMLInputElement>();

    componentDidMount() {
        initPlacesLibrary().then(this.initAutocomplete);
    }

    componentWillUnmount() {
        // a new container instance is added every time autocomplete is initialised,
        // however there is no way to reference the matching container, so cleanup
        // might cause issues if multiple autocomplete exists
        const container = document.querySelector('.pac-container');
        if (container) {
            container.remove();
        }
    }

    initAutocomplete = () => {
        const input = this.ref.current;

        // invalidate empty input
        if (!this.props.value) {
           this.props.onError(null);
        }

        const autocomplete = new google.maps.places.Autocomplete(input as HTMLInputElement, options);

        autocomplete.addListener('place_changed', () => {
            const { address_components, geometry } = autocomplete.getPlace();
            const address: PlacesApiAddress = {}; // required address data stored here
            address_components?.forEach(comp => {
                const componentType = comp.types[0]; // street_number, route, etc.
                const requiredFormat = addressComponentsFormat[componentType]; // long_name, short_name
                if (requiredFormat) {
                    address[componentType] = comp[requiredFormat];
                }
            });
            address.lat = geometry?.location.lat();
            address.lng = geometry?.location.lng();

            // construct address string, the format matches what autocomplete
            // populates in the input element, this prevents suggestions from showing
            // up multiple times

            if(!address.street_number && !address.locality){
                this.props.onError(Error.SelectionRequired);
                return;
            }

            // invalidate address without street number
            if (!address.street_number) {
                //const invalidAddress = `${address.route}, ${address.locality} ${address.administrative_area_level_1}, ${address.country}`;
                //this.props.onChange(invalidAddress);
                this.props.onError(Error.NoStreetNumber);
                return;
            }

            const formattedAddress = `${address.street_number} ${address.route}, ${address.locality} ${address.administrative_area_level_1}, ${address.country}`;
            this.props.onChange(formattedAddress); // store string
            this.props.onChangeAddress({
                number: address.street_number,
                street: address.route,
                suburb: address.locality,
                postcode: address.postal_code,
                state: address.administrative_area_level_1,
                country: address.country,
                latitude: address.lat,
                longitude: address.lng,
            }); // store structured address
            this.props.onError(null);
        });
    };

    handleChange = (e: any) => {
        this.props.onChange(e.target.value);
        // any manual changes will invalidate the address
        this.props.onError(e.target.value ? Error.SelectionRequired : Error.Empty);
    };

    render() {
        return (
            <div className={styles.container}>
                {this.props.label && <label>{this.props.label}</label>}
                <input
                    ref={this.ref}
                    id="address"
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                />
                {this.props.error && <div className={styles.error}>{this.props.error}</div>}
            </div>
        );
    }
}

type PlacesApiAddress = {
    street_number?: string;
    route?: string; // street name
    locality?: string; // suburb
    administrative_area_level_1?: string; // state
    country?: string;
    postal_code?: string;
    lat?: number;
    lng?: number;
};

export type Address = {
    number: string;
    street?: string;
    suburb?: string;
    postcode?: string;
    state?: string;
    country?: string;
    latitude?: number;
    longitude?: number;
};
