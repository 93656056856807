import * as React from 'react';
import styles from './filter.module.scss';

export const Filter: React.FC<Props> = ({ options, label, onChange }) => {
    const [active, setActive] = React.useState(options[0]);

    return <div className={styles.filter}>
        <h5>{label}</h5>
        <ul>
            {options.map(o => <li
                className={o === active ? styles.active : ''}
                onClick={() => {
                    setActive(o);
                    onChange(o);
                }}
                key={o}>{o}</li>)}
        </ul>
    </div>
}

interface Props {
    options: string[];
    label: string;
    onChange: (option: string) => void;
}
