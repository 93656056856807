import { AppThunkAction } from "..";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { OrganisationStateKnownAction } from ".";
import { Organisation } from "../../models/organisation";
import { UserStateKnownAction } from "../users";

type KnownAction = OrganisationStateKnownAction | UserStateKnownAction | CallHistoryMethodAction;

export const organisationActionCreators = {
    requestOrganisation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user = getState().userState.user;

        if (user && user.token) {
            const alreadyLoading = getState().orgState.loadingOrganisation && getState().orgState.organisation != undefined;
            dispatch({
                type: 'REQUEST_ORGANISATION'
            });
            if (!alreadyLoading) {
                const url = `/v1/organisations`;
                fetch(url, {
                    headers: {
                        'authorization': `bearer ${user.token}`
                    }
                })
                .then(response => {
                    if (response.ok) {
                        return response.json() as Promise<Organisation>;
                    }
                    else if (response.status === 401 || response.status === 403) {
                        dispatch({
                            type: 'LOG_OUT'
                        });
                        dispatch(push('/login'));
                        return Promise.resolve(undefined);
                    }
                    else
                        return response.text().then(text => { throw text });
                })
                .then(organisation => {
                    if (organisation) {
                        dispatch({
                            type: 'RECEIVE_ORGANISATION',
                            organisation: organisation
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    alert(err);
                });
            }
        }
        else {
            dispatch(push('/login'));
        }
    },
}
