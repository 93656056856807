import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { TaskDescriptionField } from '../../models/organisation';
import styles from './taskDescription.module.scss';

type Props = {
    taskName: string;
    taskSettingsId: number;
    fields?: TaskDescriptionField[]; // if empty, fall back to single 'Description' field
};

const FieldPrefix = 'task';

export const TaskDescription = ({ taskName, taskSettingsId, fields }: Props) => {
    const { formState, register } = useFormContext();

    const renderField = (label: string, required?: boolean) => {
        const error = formState.errors[`${FieldPrefix}-${taskSettingsId}`]?.[label];

        return (
            <label>
                <div className={styles.errorLabel}>
                    <span>{label}</span>
                    {error && <span className={styles.error}>Required*</span>}
                </div>
                <input
                    name={`${FieldPrefix}-${taskSettingsId}.${label}`}
                    ref={register({ required })}
                    className={error ? styles.error : ''}
                />
            </label>
        );
    };

    return (
        <div className={styles.container}>
            <h3>{taskName}</h3>
            <div className={styles.fields}>
                {fields && fields.length > 0
                    ? fields
                          .sort((a, b) => a.ordinal - b.ordinal)
                          .map(field => <Fragment key={field.id}>{renderField(field.label, true)}</Fragment>)
                    : renderField('Description', true)}
            </div>
        </div>
    );
};
