import * as React from 'react';
import { Link } from 'react-router-dom';
import './slimCentre.scss';

export const SlimCentre: React.FC<SlimCentreProps> = (props) => {
    return <div className='fluid-container slim-centre'>
        <div className='row'>
            <div className='col-lg-3 col-md-2 col-sm-1 hidden-xs'></div>
            <div className='col-lg-6 col-md-8 col-sm-10 col-xs-12'>
                <div className='slim-centre-header'>
                    <Link to={props.backUrl ? props.backUrl : '/tasks'}>
                        {props.backText ? props.backText : 'Dashboard'}
                    </Link>
                </div>
                {props.children}
            </div>
            <div className='col-lg-3 col-md-2 col-sm-1 hidden-xs'></div>
        </div>
    </div>
}

interface SlimCentreProps {
    backText?: string;
    backUrl?: string;
}
