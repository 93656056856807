import React from 'react';
import styles from './input.module.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  errorDesc?: string;
};

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, errorDesc, ...attr } = props;

  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <input {...attr} ref={ref} />
      {errorDesc && <label className={styles.error}>{errorDesc}</label>}
    </div>
  );
});

export const CustomInput: React.FunctionComponent<{ label?: string }> = ({ label, children }) => {
  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <div>
        {children}
      </div>
    </div>
  );
}
