import React from "react";
import cn from "classnames";
import styles from "./priceTile.module.scss";

type Props = {
  priceToAdd: number;
  pricePerUse: number;
  onAdd: () => void;
  borderless?: boolean;
};

export const PriceTile = ({ priceToAdd, pricePerUse, onAdd, borderless }: Props) => {
  return (
    <div className={cn(styles.tile, { [styles.borderless]: borderless })}>
      <div className={styles.price}>
        {priceToAdd ? (
          <div>
            <span>${priceToAdd.toFixed(2)}</span> to add
          </div>
        ) : (
          <div>Free to add</div>
        )}
        {pricePerUse ? (
          <div>
            <span>${pricePerUse.toFixed(2)}</span>/use
          </div>
        ) : (
          <div>Free to use</div>
        )}
      </div>
      <button className={styles.add} onClick={onAdd}><img src='/images/plus.svg' alt='+' /> Add</button>
    </div>
  );
};
