// https://stackoverflow.com/a/901144
// TODO: should use URLSearchParams with fallback/polyfill
export const getUrlParamByName = (name: string, url: string = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
