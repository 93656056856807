export const _reportHost = process.env.NODE_ENV === 'development' ?
    'http://reports.olasio.csstg.com.au' :
    'https://reports.olasio.com';

export const _braintreePaymentGatewayAuthorization = process.env.NODE_ENV === 'development' ?
    'sandbox_mfjpg8gy_45hxtwjtrzmr2nxb' :
    'production_rz53b99f_4fcxfz52rt8yrrnj';

export const _supportEmail = 'rbisquad@carsales.com.au';
export const _identityId = process.env.NODE_ENV === 'development' ? 280 : 56;
export const _vehicleId = process.env.NODE_ENV === 'development' ? 281 : 55;
