import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as MarketplaceApi from '../../api/marketplace';
import { userActionCreators } from '../users/actionCreators';
import { MarketplaceTask, MarketplaceTaskDetails } from '../../models/marketplaceTask';
import { AppThunk } from '..';

export type MarketplaceState = {
    isLoading: boolean;
    error: string | null;
    tasks: MarketplaceTask[];
    taskDetails: MarketplaceTaskDetails | null;
    taskSuggestions: MarketplaceTask[];
    addingTaskIds: number[];
    removingTaskIds: number[];
    removedTask?: number;
    addedTask?: number;
    addError: string | null;
    tags: string[];
    tagName: string | null;
};

type AddRemoveErrorPayload = {
    taskId: number;
    error: string;
};

const initialState: MarketplaceState = {
    isLoading: false,
    error: null,
    tasks: [],
    taskDetails: null,
    taskSuggestions: [],
    addingTaskIds: [],
    removingTaskIds: [],
    addError: null,
    tags: [],
    tagName: null
};

const marketplaceSlice = createSlice({
    name: 'marketplace',
    initialState,
    reducers: {
        getMarketplaceTasksStart(state) {
            state.isLoading = true;
            state.error = null;
            state.tasks = [];
        },
        getMarketplaceTasksSuccess(state, action: PayloadAction<MarketplaceTask[]>) {
            state.isLoading = false;
            state.tasks = action.payload;
        },
        getMarketplaceTasksFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getMarketplaceTaskDetailsStart(state) {
            state.isLoading = true;
            state.error = null;
            state.taskDetails = null;
        },
        getMarketplaceTaskDetailsSuccess(state, action: PayloadAction<MarketplaceTaskDetails>) {
            state.isLoading = false;
            state.taskDetails = action.payload;
        },
        getMarketplaceTaskDetailsFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getMarketplaceTaskSuggestionsSuccess(state, action: PayloadAction<MarketplaceTask[]>) {
            state.taskSuggestions = action.payload;
        },

        addMarketplaceTaskStart(state, action: PayloadAction<number>) {
            const taskId = action.payload;
            state.addingTaskIds.push(taskId);
            state.addError = null;
        },
        addMarketplaceTaskSuccess(state, action: PayloadAction<number>) {
            const taskId = action.payload;
            // remove from adding list
            state.addingTaskIds = state.addingTaskIds.filter(id => id !== taskId);
            // update task
            const addedUtc = new Date().toISOString(); // inaccurate
            const task = state.tasks.find(task => task.id === taskId);
            if (task) {
                task.addedUtc = addedUtc;
            }
            const suggestedTask = state.taskSuggestions.find(task => task.id === taskId);
            if (suggestedTask) {
                suggestedTask.addedUtc = addedUtc;
            }
            if (state.taskDetails?.id === taskId) {
                state.taskDetails.addedUtc = addedUtc;
            }
            state.addedTask = taskId;
        },
        addMarketplaceTaskFailure(state, action: PayloadAction<AddRemoveErrorPayload>) {
            const { taskId, error } = action.payload;
            state.addingTaskIds = state.addingTaskIds.filter(id => id !== taskId);
            state.addError = error;
        },
        
        removeMarketplaceTaskStart(state, action: PayloadAction<number>) {
            const taskId = action.payload;
            state.removingTaskIds.push(taskId);
            state.addError = null;
        },
        removeMarketplaceTaskSuccess(state, action: PayloadAction<number>) {
            const taskId = action.payload;
            const task = state.tasks.find(task => task.id === taskId);
            state.removingTaskIds = state.removingTaskIds.filter(id => id !== taskId);

            if (task) {
                task.addedUtc = undefined;
            }
            const suggestedTask = state.taskSuggestions.find(task => task.id === taskId);
            if (suggestedTask) {
                suggestedTask.addedUtc = undefined;
            }
            if (state.taskDetails?.id === taskId) {
                state.taskDetails.addedUtc = undefined;
            }
            state.removedTask = taskId;
        },
        removeMarketplaceTaskFailure(state, action: PayloadAction<AddRemoveErrorPayload>) {
            const { taskId, error } = action.payload;
            state.removingTaskIds = state.removingTaskIds.filter(id => id !== taskId);
            state.addError = error;
        },
        confirmMarketplaceAdd(state, action: PayloadAction<number>) {
            if (state.addedTask === action.payload)
                state.addedTask = undefined;
        },
        confirmMarketplaceRemove(state, action: PayloadAction<number>) {
            if (state.removedTask === action.payload)
                state.removedTask = undefined;
        },

        getMarketplaceTagsStart(state) {
            state.isLoading = true;
            state.error = null;
            state.tags = [];
        },
        getMarketplaceTagsSuccess(state, action: PayloadAction<string[]>) {
            state.isLoading = false;
            state.tags = action.payload;
        },
        getMarketplaceTagsFailure(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        addTagStart(state, action: PayloadAction<string>) {
            state.error = null;
            state.tagName = null;
        },
        addTagSuccess(state, action: PayloadAction<string>) {
            state.tagName = action.payload;
        },
        addTagFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
        }
    },
});

const {
    getMarketplaceTasksStart,
    getMarketplaceTasksSuccess,
    getMarketplaceTasksFailure,
    getMarketplaceTaskDetailsStart,
    getMarketplaceTaskDetailsSuccess,
    getMarketplaceTaskDetailsFailure,
    getMarketplaceTaskSuggestionsSuccess,
    addMarketplaceTaskStart,
    addMarketplaceTaskSuccess,
    addMarketplaceTaskFailure,
    removeMarketplaceTaskStart,
    removeMarketplaceTaskSuccess,
    removeMarketplaceTaskFailure,
    getMarketplaceTagsStart,
    getMarketplaceTagsSuccess,
    getMarketplaceTagsFailure,
    confirmMarketplaceAdd,
    confirmMarketplaceRemove,
    addTagStart,
    addTagSuccess,
} = marketplaceSlice.actions;

export const getMarketplaceTasks = (): AppThunk => async (dispatch, getState) => {
    dispatch(getMarketplaceTasksStart());

    const token = getState().userState.user?.token;
    if (!token) {
        // TODO: public view
        dispatch(getMarketplaceTasksFailure('Not logged in'));
        return;
    }

    try {
        const res = await MarketplaceApi.getMarketplaceTasks(token);
        dispatch(getMarketplaceTasksSuccess(res.data));
    } catch (e) {
        dispatch(getMarketplaceTasksFailure('Error'));
    }
};

export const getMarketplaceTasksPublic = (): AppThunk => async (dispatch, getState) => {
    dispatch(getMarketplaceTasksStart());

    try {
        const res = await MarketplaceApi.getMarketplaceTasks();
        dispatch(getMarketplaceTasksSuccess(res.data));
    } catch (e) {
        dispatch(getMarketplaceTasksFailure('Error'));
    }
}

export const getMarketplaceTags = (): AppThunk => async (dispatch, getState) => {
    dispatch(getMarketplaceTagsStart());

    const token = getState().userState.user?.token;
    if (!token) {
        dispatch(getMarketplaceTagsFailure('Not logged in'));
        return;
    }

    try {
        const res = await MarketplaceApi.getMarketplaceTags(token);
        dispatch(getMarketplaceTagsSuccess(res.data));
    } catch (e) {
        dispatch(getMarketplaceTagsFailure('Error'));
    }
};

export const getMarketplaceTaskDetails = (taskId: number): AppThunk => async (dispatch, getState) => {
    dispatch(getMarketplaceTaskDetailsStart());

    const token = getState().userState.user?.token;
    if (!token) {
        dispatch(userActionCreators.logout());
        return;
    }

    try {
        const res = await MarketplaceApi.getMarketplaceTaskDetails(token, taskId);
        dispatch(getMarketplaceTaskDetailsSuccess(res.data));
    } catch (e: any) {
        if (!e?.response) {
            dispatch(getMarketplaceTaskDetailsFailure('Oops, something went wrong. Please try again.'));
            return;
        }
        if (e.response.status === 401) {
            dispatch(userActionCreators.logout());
            return;
        }
        dispatch(getMarketplaceTaskDetailsFailure(e.response.statusText));
    }
};

export const getMarketplaceTaskSuggestions = (taskId: number): AppThunk => async (dispatch, getState) => {
    // dispatch(getMarketplaceTaskDetailsStart());

    const token = getState().userState.user?.token;
    if (!token) {
        dispatch(userActionCreators.logout());
        return;
    }

    try {
        const res = await MarketplaceApi.getMarketplaceTaskSuggestions(token, taskId);
        dispatch(getMarketplaceTaskSuggestionsSuccess(res.data));
    } catch (e: any) {
        if (!e?.response) {
            dispatch(getMarketplaceTaskDetailsFailure('Oops, something went wrong. Please try again.'));
            return;
        }
        if (e.response.status === 401) {
            dispatch(userActionCreators.logout());
            return;
        }
        // dispatch(getMarketplaceTaskDetailsFailure(e.response.statusText));
    }
};

export const addMarketplaceTask = (taskId: number): AppThunk => async (dispatch, getState) => {
    if (getState().marketplaceState.addingTaskIds.indexOf(taskId) !== -1) {
        console.log('already being added bruh');
        return Promise.reject();
    }
    dispatch(addMarketplaceTaskStart(taskId));

    const token = getState().userState.user?.token;
    if (!token) {
        dispatch(userActionCreators.logout());
        return Promise.reject();
    }

    try {
        await MarketplaceApi.addMarketplaceTask(token, taskId);
        dispatch(addMarketplaceTaskSuccess(taskId));
        return Promise.resolve();
    } catch (e: any) {
        if (!e?.response) {
            dispatch(getMarketplaceTaskDetailsFailure('Oops, something went wrong. Please try again.'));
            return Promise.reject();
        }
        if (e.response.status === 401) {
            dispatch(userActionCreators.logout());
            return Promise.reject();
        }
        dispatch(addMarketplaceTaskFailure({ taskId, error: 'Failed to add task' }));
        return Promise.reject();
    }
};

export const removeMarketplaceTask = (taskId: number): AppThunk => async (dispatch, getState) => {
    dispatch(removeMarketplaceTaskStart(taskId));

    const token = getState().userState.user?.token;
    if (!token) {
        return;
    }

    try {
        const res = await MarketplaceApi.removeMarketplaceTask(token, taskId);
        dispatch(removeMarketplaceTaskSuccess(taskId));
    } catch (e: any) {
        if (!e?.response) {
            dispatch(getMarketplaceTaskDetailsFailure('Oops, something went wrong. Please try again.'));
            return;
        }
        if (e.response.status === 401) {
            dispatch(userActionCreators.logout());
            return;
        }
        dispatch(removeMarketplaceTaskFailure({ taskId, error: 'Failed to remomve task' }));
    }
};

export const addTag = (name: string): AppThunk => async (dispatch, getState) => {
    dispatch(addTagStart(name));
    console.log("addTag",addTag);

    const token = getState().userState.user?.token;
    if (!token) {
        dispatch(userActionCreators.logout());
        return;
    }

    try {
        await MarketplaceApi.addTag(token, name);
        dispatch(addTagSuccess(name));
    } catch (e: any) {
        if (!e?.response) {
            dispatch(getMarketplaceTaskDetailsFailure('Oops, something went wrong. Please try again.'));
            return;
        }
        if (e.response.status === 401) {
            dispatch(userActionCreators.logout());
            return;
        }
    }
};

export const confirmAdd = (taskId: number): AppThunk => async (dispatch, getState) => {
    dispatch(confirmMarketplaceAdd(taskId));
};

export const confirmRemove = (taskId: number): AppThunk => async (dispatch, getState) => {
    dispatch(confirmMarketplaceRemove(taskId));
};

export default marketplaceSlice.reducer;
