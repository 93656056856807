import * as React from 'react';
import './radioButton.scss';

export const RadioButton: React.FC<RadioProps> = (props) => {
    return <label className='radio'>
        <input type='radio' name={props.name} value={props.value} onChange={props.onChange ? (e) => props.onChange!(e.target.value) : undefined} />
        <div className='checker'></div>
        <span>{props.label}</span>
    </label>
}

interface RadioProps {
    name: string;
    value?: string;
    label: string;
    onChange?: (value: string) => void;
}
