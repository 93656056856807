export const zeroLeft = (input: number, digits: number = 2) => {
    let zeroPad = '';
    if (input >= Math.pow(10, digits)) return `${input}`;
    for (let i = 0; i < digits; i++) zeroPad += '0';

    return (zeroPad + input).slice(-1 * digits);
}

export const getPaymentDescription = (payBy: string) => {
    switch (payBy) {
        case "creditcard":
            return 'Paid by credit card';
        default:
            return 'Invoiced to';
    }
}

export const cardNumberWithSpace = (cardNumber: string) => {
    let result = cardNumber;
    if (cardNumber.length >= 16) {
        result = `${cardNumber.substring(0,4)} ${cardNumber.substring(4,8)} ${cardNumber.substring(8,12)} ${cardNumber.substring(12,16)}`
    }
    return result
}

export const creditCardStatus = (status: string) => {
    switch (status) {
        case "Processed":
            return 'Paid';
        default:
            return status;
    }
}

export const braintreeTransactionStatus = (status: string) => {
    switch (status) {
        case "AUTHORIZING":
        case "AUTHORIZED":
            return 'Pending';
        case "SUBMITTED_FOR_SETTLEMENT":
        case "SETTLEMENT_PENDING":
        case "SETTLING":
        case "SETTLED":
        case "SETTLEMENT_CONFIRMED":
            return 'Paid';
        case "AUTHORIZATION_EXPIRED":
        case "GATEWAY_REJECTED":
        case "PROCESSOR_DECLINED":
        case "FAILED":
        case "UNRECOGNIZED":
        case "SETTLEMENT_DECLINED":
            return 'Failed';
        case "VOIDED":
            return 'Voided';
        default:
            return status;   
    }
}