import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import styles from './orderSuccess.module.scss';
import { ApplicationState } from '../store';
import { Loading } from '../components/loading';
import { organisationActionCreators } from '../store/organisation/actionCreators';
import { userActionCreators } from '../store/users/actionCreators';
import { _supportEmail } from '../settings';
import checkIcon from '../assets/check-lg.svg';
import { getPaymentDescription } from '../helpers/format';

export const OrderSuccess = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.userState.user);
    const order = useSelector((state: ApplicationState) => state.orderState.inProgressOrder);
    const org = useSelector((state: ApplicationState) => state.orgState.organisation);
    const loadingOrg = useSelector((state: ApplicationState) => state.orgState.loadingOrganisation);
    const createdOrder = useSelector((state: ApplicationState) => state.orderState.createdOrders?.[0]);

    useEffect(() => {
        if (!user) dispatch(userActionCreators.logout());
        if (!order) dispatch(userActionCreators.returnHome());
        if (!org && !loadingOrg) {
            dispatch(organisationActionCreators.requestOrganisation());
        }
    }, [org, order, user]);

    if (!org || loadingOrg || !order) return <Loading text="Loading..." />;

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <img src={checkIcon} alt="" />
                    <div>
                        <h2>Order confirmed</h2>
                        <p>Your smart task has been sent successfully.</p>
                    </div>
                </div>
                <hr />
                <p className={styles.ref}>Reference number: {createdOrder?.referenceId}</p>
                <div className={styles.block}>
                    <h3>Recipient details</h3>
                    <div className={styles.section}>
                        <div>
                            <p>{order.recipients[0].name}</p>
                        </div>
                        <div>
                            <p>{order.recipients[0].phone}</p>
                            <p>{order.recipients[0].email}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.block}>
                    <h3>Task details</h3>
                    {order.taskItems.map((item, i) => (
                        <React.Fragment key={item.id}>
                            <div className={styles.section}>
                                <div className={styles.left}>
                                    <p>{item.description}</p>
                                </div>
                                <div className={styles.right}>
                                    <p>{order.recipients[0].tasks.find(task => task.id === item.id)?.description}</p>
                                    <small>
                                        {item.paymentMethod === 'cc'
                                            ? getPaymentDescription('creditcard')
                                            : `${getPaymentDescription('invoice')} ${
                                                  org.invoiceOptions.find(option => option.id === item.paymentMethod)!
                                                      .name
                                              }`}
                                    </small>
                                </div>
                            </div>
                            {i !== order.taskItems.length - 1 && <hr />}
                        </React.Fragment>
                    ))}
                </div>
                <div className={styles.invoice}>
                        <strong>
                            <a href={`/invoice/${createdOrder?.invoiceId}`} target='_blank'>View invoice</a>
                        </strong>
                </div>
                <div className={styles.moreInfo}>
                    <small>
                        Something wrong with the details above? Contact our{' '}
                        <strong>
                            <a href={`mailto:${_supportEmail}`}>support team</a>
                        </strong>
                        .
                    </small>
                </div>

                <footer className={styles.nav}>
                    <button className={`button default ${styles.next}`} onClick={() => dispatch(push('/new-task'))}>
                        Order another task
                    </button>
                    <button className={`button boring ${styles.back}`} onClick={() => dispatch(push('/tasks'))}>
                        Go to dashboard
                    </button>
                </footer>
            </div>
        </div>
    );
};
