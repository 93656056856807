import React from 'react';
import cn from 'classnames';
import styles from './ccPaymentBlock.module.scss';
import { TaskItem } from '../../models/inProgressOrder';
import { PaymentBlock } from './paymentBlock';

type Props = {
    items: TaskItem[];
    ccNumber: string;
    month: string;
    year: string;
    cvc: string;
    name: string;
    onCcNumberChange: (val: string) => void;
    onMonthChange: (val: string) => void;
    onYearChange: (val: string) => void;
    onCvcChange: (val: string) => void;
    onNameChange: (val: string) => void;
};

const months = Array.from(Array(12).keys()).map(key => key + 1);

const thisYear = new Date().getFullYear();
const years = [...((new Array(24).keys() as unknown) as Array<any>)].map((x, i) => thisYear + i);

export const CcPaymentBlock = ({
    items,
    ccNumber,
    month,
    year,
    cvc,
    name,
    onCcNumberChange,
    onMonthChange,
    onYearChange,
    onCvcChange,
    onNameChange,
}: Props) => {
    const ccType = getCCType(ccNumber);

    const creditCardOnChange = (ev: React.FormEvent<HTMLInputElement>) => {
        let target = ev.target as HTMLInputElement;
        let value = target.value;
        let newVal = value.replace(/[^\d]/g, '');
        let length = value.length;
        let rem = length % 4;
        let sections = (length - rem) / 4;

        for (let i = 0; i < sections; i++) {
            let arr = newVal.split('');
            let index = (i + 1) * 4 + i;
            arr.splice(index, 0, ' ');
            newVal = arr.join('');
        }
        newVal = newVal.trim();
        if (newVal.length > 23) {
            newVal = newVal.substr(0, 23);
        }
        target.value = newVal;

        onCcNumberChange(newVal);
    };

    return (
        <PaymentBlock items={items}>
            <div className={styles.header}>
                <h4>Payment</h4>
                <div className={styles.icons}>
                    <img
                        src="/images/thumbnail_visa.svg"
                        alt="Visa"
                        className={cn({ [styles.inactive]: ccType && ccType !== 'visa' })}
                    />
                    <img
                        src="/images/thumbnail_master.svg"
                        alt="Mastercard"
                        className={cn({ [styles.inactive]: ccType && ccType !== 'master' })}
                    />
                    <img
                        src="/images/thumbnail_amex.svg"
                        alt="Amex"
                        className={cn({ [styles.inactive]: ccType && ccType !== 'amex' })}
                    />
                </div>
            </div>
            <hr />
            <form className={cn('form', styles.form)}>
                <label>
                    Credit card number
                    <input
                        placeholder="Enter your credit card number..."
                        value={ccNumber}
                        onChange={creditCardOnChange}
                    />
                </label>
                <div className={styles.triple}>
                    <label>
                        Month
                        <div className="select">
                            <select value={month} onChange={e => onMonthChange(e.target.value)}>
                                <option disabled value="">
                                    MM
                                </option>
                                {months.map(n => (
                                    <option key={n} value={n}>
                                        {('0' + n).slice(-2) /* append leading 0 */}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label>
                        Year
                        <div className="select">
                            <select value={year} onChange={e => onYearChange(e.target.value)}>
                                <option disabled value="">
                                    YYYY
                                </option>
                                {years.map(n => (
                                    <option key={n} value={n}>
                                        {n}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label>
                        CVC
                        <input
                            maxLength={4}
                            placeholder="CVC number..."
                            value={cvc}
                            onChange={e => onCvcChange(e.target.value)}
                        />
                    </label>
                </div>
                <label>
                    Name on card
                    <input
                        placeholder="Enter your name as it appears on the card..."
                        value={name}
                        onChange={e => onNameChange(e.target.value)}
                    />
                </label>
            </form>
        </PaymentBlock>
    );
};

const getCCType = (number: string) => {
    // strip it
    number = number.replace(/[^\d]/g, '');
    if (number[0] === '3') return 'amex'; // Actually Travel & Entertainment
    if (number[0] === '4') return 'visa'; // Visa, Switch & Electron
    if (number[0] === '5') return 'master'; // Mastercard & Bankcard
    if (number[0] === '6') return 'discover'; // Actually Merchandising & Finance
    if (number[0] === '7') return 'petroleum';
    if (number[0] === '8') return 'telecommunications';
    return '';
};
