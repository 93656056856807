import React, { useEffect, useState } from 'react';
import styles from './onboarding.module.scss';
import { OnboardingTaskStep } from '../components/marketplace/onboardingTasksStep';
import {OnboardingIndustrySelectionStep } from '../components/marketplace/onboardingIndustrySelectionStep';
import { addTag } from '../store/marketplace';
import { useDispatch, useSelector } from 'react-redux';
import { addMarketplaceTask } from '../api/marketplace';
import { ApplicationState } from '../store';
import { userActionCreators } from '../store/users/actionCreators';
import { push } from 'connected-react-router';

enum Step {
    Industry,
    Tasks,
}

export const Onboarding = () => {
    const [step, setStep] = useState(Step.Industry);
    const [loading, setLoading] = useState(false);
    const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedTagsText, setselectedTagsText] = useState<string>();
    const Other = 'other';
    const dispatch = useDispatch();

    const token = useSelector((state: ApplicationState) => state.userState.user?.token);

    const handleNext = () => {
        if (step === Step.Industry) {
            if (selectedTags && selectedTags[0] === Other && selectedTagsText){
                dispatch(addTag(selectedTagsText));
            }
            setStep(Step.Tasks);
        } else if (step === Step.Tasks) {
            handleAddTasks();
        }
    };

    const handleAddTasks = async () => {
        if (!token) {
            dispatch(userActionCreators.logout());
            return;
        }

        const addTasksPromise = selectedTaskIds.map(async (id) => await addMarketplaceTask(token, id));

        setLoading(true);

        Promise.all(addTasksPromise)
            .then(values => {
                dispatch(push('/tasks?onboarding'));
            }).catch(err => {
                setLoading(false);
            });
    }

    const handleTaskSelect = (taskId: number) => {
        if (selectedTaskIds.includes(taskId)) {
            setSelectedTaskIds(selectedTaskIds.filter(id => id !== taskId));
        } else {
            setSelectedTaskIds([...selectedTaskIds, taskId]);
        }
    };

    const handleTagSelect = (tag: string, tagText: string) => {
        setSelectedTags([tag]);
        setselectedTagsText(tagText);
};

    const renderStep = () => {
        switch (step) {
            case Step.Industry:
                return <OnboardingIndustrySelectionStep selectedTags={selectedTags} onSelect={handleTagSelect} />;
            case Step.Tasks:
                return <OnboardingTaskStep selectedTaskIds={selectedTaskIds} selectedTags={selectedTags} onSelect={handleTaskSelect} />;
        }
    };

    const getButtonText = () => {
        if (step === Step.Tasks && loading) return 'Adding tasks...';
        if (step === Step.Industry) return 'Continue';
        return 'Confirm';
    }

    return (
        <div className={styles.page}>
            <div className={styles.main}>
                <a className={styles.skipBtnMobile} href="/tasks">
                    Skip for now
                </a>
                {renderStep()}
                </div>
                <div className={styles.footer}>
                    <div className={styles.footerContent}>
                        <a className={styles.skipBtn} href="/tasks">
                            Skip for now
                        </a>
                        <div className={styles.action}>
                            {step === Step.Tasks && (
                                <button className={styles.prevBtn} onClick={() => setStep(Step.Industry)}>
                                    Previous
                                </button>
                            )}
                            <button className={styles.nextBtn} onClick={handleNext} disabled={loading}>
                                {getButtonText()}
                            </button>
                        </div>
                    </div>
                </div>
        </div>
    );
};
