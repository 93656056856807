import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import styles from './marketplaceHome.module.scss';
import '../components/pagination.scss';
import { TaskTile } from '../components/marketplace/taskTile';
import { Filter } from '../components/marketplace/filter';
import { FullscreenFilter } from '../components/marketplace/fullscreenFilter';
import { getMarketplaceTasksPublic } from '../store/marketplace';
import { MarketplaceTask } from '../models/marketplaceTask';
import { Loading } from '../components/loading';

export const MarketplaceHomePublic = () => {
    const tasks = useSelector((state: ApplicationState) => state.marketplaceState.tasks);

    const [tagFilter, setTagFilter] = useState(AllTagsFilter);
    const [showMobileFilter, setShowMobileFilter] = useState(false);

    const dispatch = useDispatch();

    // get tasks list
    useEffect(() => {
        dispatch(getMarketplaceTasksPublic());
    }, [dispatch]);

    // get tags list
    const tags = getTagsFromTasks(tasks);

    // filter tasks
    const visibleTasks = (!tasks || tasks.length === 0) ? undefined : tasks
        .filter(task => {
            if (tagFilter === AllTagsFilter) return true;
            return task.tags.includes(tagFilter);
        });

    if (showMobileFilter) {
        return (
            <FullscreenFilter
                onClose={() => setShowMobileFilter(false)}
                filters={[
                    {
                        label: 'Industry',
                        options: [AllTagsFilter, ...tags],
                        selected: tagFilter,
                        onChange: option => setTagFilter(option),
                    }
                ]}
                resultCount={visibleTasks?.length || 0}
            />
        );
    }

    return (
        <>
            <div className={styles.banner}>
                <h1>Smart Task Store</h1>
                <button className={styles.filterBtn} onClick={() => setShowMobileFilter(true)}>
                    <img src="/images/filter.svg" alt="" />
                    Filters
                </button>
            </div>
            <div className={styles.body}>
                <div className={styles.filter}>
                    <Filter
                        label="Industry"
                        onChange={option => setTagFilter(option)}
                        options={[AllTagsFilter, ...tags]}
                    />
                </div>
                <div className={styles.tasks}>
                    {(visibleTasks) ? visibleTasks.map(task =>
                        <div key={task.id} className={styles.tile}>
                            <TaskTile task={task} anon />
                        </div>
                    ) : <div className={styles.loadingContainer}><Loading text='Loading...' /></div>}
                </div>
            </div>
        </>
    );
}

const AllTagsFilter = 'All industries';

const getTagsFromTasks = (tasks: MarketplaceTask[]) => {
    const tags = tasks.flatMap(task => task.tags);
    const uniqueTags = Array.from(new Set(tags));
    return uniqueTags;
};
