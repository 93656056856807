import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import cn from 'classnames';
import { TaskDescription } from '../components/newtask/taskDescription';
import { orderActionCreators } from '../store/orders/actionCreators';
import { EmailRegex } from '../helpers/regex';
import { ApplicationState } from '../store';
import styles from './orderSingle.module.scss';
import chevronLeft from '../assets/chevron-left.svg';

/**
 * ideas/todos:
 * - trim whitespace before submitting
 * - show error messages below inputs (will be needed if task descriptions have dynamic regex)
 */

type FormData = {
    name: string;
    phone: string;
    email: string;
    externalReference: string;
    reason: string;
    notifyMe: boolean;
};

export const OrderSinglePage = () => {
    const dispatch = useDispatch();
    const inProgressOrder = useSelector((state: ApplicationState) => state.orderState.inProgressOrder);
    const recipient = inProgressOrder?.recipients?.[0];
    const taskItems = useSelector((state: ApplicationState) => state.orderState.inProgressOrder?.taskItems);

    useEffect(() => {
        if (!inProgressOrder) {
            dispatch(orderActionCreators.startNewOrder());
        }
    }, [inProgressOrder, dispatch]);

    const { register, formState, handleSubmit, setValue } = useForm<FormData>();

    // task description fields
    const methods = useForm();

    const prevClick = () => dispatch(orderActionCreators.backToOrderStepOne());

    const nextClick = async () => {
        let staticData: FormData | undefined;
        let dynamicData: any | undefined; // can this be typed better?

        await handleSubmit(data => {
            staticData = data;
        })();
        await methods.handleSubmit(data => {
            dynamicData = data;
        })();

        if (staticData && dynamicData && taskItems && inProgressOrder?.taskItems) {
            dispatch(orderActionCreators.saveAndProgressSingleOrder({
                ...inProgressOrder,
                recipients: [
                    {
                        name: staticData.name,
                        phone: staticData.phone,
                        email: staticData.email,
                        externalReference: staticData.externalReference,
                        tasks: taskItems.map(item => ({
                            id: item.id,
                            description: Object.entries(dynamicData[`task-${item.id}`]).map(([k, v]) => v as string).join(' '),
                            descriptions: Object.entries(dynamicData[`task-${item.id}`]).map(([k, v]) => ({
                                label: k,
                                value: v as string,
                            }))
                        }))
                    }
                ],
                reason: staticData.reason,
                notifyMe: staticData.notifyMe,
            }));
        }
    };

    const renderErrorMessage = () => {
        const errors = Object.values(formState.errors);
        if (errors.length === 0) {
            return null;
        }
        return <p className={styles.errorMessage}>{errors.length > 1 ? 'Please fix the highlighted fields to continue' : errors[0]?.message!}</p>
    }

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.section}>
                    <h2>Recipient details</h2>
                    <p>Please provide details of the person who will be performing the Source Task.</p>
                    <div className={styles.split}>

                        <label>
                            <div className={styles.errorLabel}>
                                <span>Name</span>
                                {formState.errors.name && <span className={styles.error}>Required*</span>}
                            </div>
                            <input
                                name="name"
                                ref={register({ required: 'Name is required' })}
                                placeholder="Enter applicant's name..."
                                className={formState.errors.name ? styles.error : ''}
                            />
                        </label>
                        <label>
                            <div className={styles.errorLabel}>
                                <span>Mobile</span>
                                {formState.errors.phone && <span className={styles.error}>Required*</span>}
                            </div>
                            <input
                                type="tel"
                                name="phone"
                                ref={register({ required: 'Phone number is required' })}
                                placeholder="Enter contact number..."
                                className={formState.errors.phone ? styles.error : ''}
                                onChange={e => setValue('phone', e.target.value.replace(/[^\d\s+]/, ''), { shouldValidate: true })}
                            />
                        </label>
                    </div>
                    <label>
                        <div className={styles.errorLabel}>
                            <span>Email</span>
                            {formState.errors.email && <span className={styles.error}>Required*</span>}
                        </div>
                        <input
                            type="email"
                            name="email"
                            ref={register({
                                required: 'Email address is required',
                                pattern: {
                                    value: EmailRegex,
                                    message: 'Email address must be valid',
                                },
                            })}
                            placeholder="Enter email address..."
                            className={cn(styles.last, formState.errors.email ? styles.error : '')}
                        />
                    </label>
                </div>
                <hr />
                <div className={styles.section}>
                    <label>
                        <div className={styles.errorLabel}>
                            <span>External reference</span>
                            {formState.errors.externalReference && <span className={styles.error}>Required*</span>}
                        </div>
                        <input
                            name="externalReference"
                            ref={register({ required: 'Your reference required' })}
                            placeholder="Your own reference number that will be attached to this order"
                            className={cn(styles.last, formState.errors.externalReference ? styles.error : '')}
                        />
                    </label>
                </div>
                <hr />
                <div className={styles.section}>
                    <h2>Task details</h2>
                    <p>Please provide details for the tasks included.</p>
                    <FormProvider {...methods}>
                        <div className={styles.taskDescriptions}>
                            {taskItems && taskItems.map(task => (
                                <div key={task.id}>
                                    <TaskDescription taskName={task.description} taskSettingsId={task.id} fields={task.taskDescriptionFields} />
                                </div>
                            ))}
                        </div>
                    </FormProvider>
                </div>
                <hr />
                <div className={styles.section}>
                    <div className={styles.reasonHeading}>
                        <h2>Message to recipient</h2>
                        {formState.errors.reason && <span className={styles.error}>Required*</span>}
                    </div>
                    <p className={styles.reason}>Please provide a message or reason for this request.</p>
                    <textarea
                        name="reason"
                        ref={register({ required: 'Message to recipient is required' })}
                        placeholder="Enter your message for the recipient here..."
                        className={formState.errors.reason ? styles.error : ''}
                        rows={4}
                    />
                </div>
                <hr />
                <div className={styles.checkboxSection}>
                    <label>
                        <input type="checkbox" name="notifyMe" ref={register} />
                        I would like to receive notification once the report is ready
                    </label>
                </div>
                {renderErrorMessage()}
                <div className={styles.nav}>
                    <button className={styles.next} onClick={() => nextClick()}>Next</button>
                    <button className={styles.back} onClick={() => prevClick()}><img src={chevronLeft} alt="" />Back</button>
                </div>
            </div>
        </div>
    );
}
