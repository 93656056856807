import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import styles from './onboardingIndustrySelectionStep.module.scss';
import { getMarketplaceTags } from '../../store/marketplace';
import checkIcon from '../../assets/check-sm.svg';

type Props = {
    selectedTags: string[];
    onSelect: (tag: string, tagText: string) => void;
};

export const OnboardingIndustrySelectionStep = ({ selectedTags, onSelect }: Props) => {
    const tags = useSelector((state: ApplicationState) => state.marketplaceState.tags);

    const [selection, setSelection] = useState('');
    const Other = 'other';
    const dispatch = useDispatch();
    const tagRef = React.createRef<HTMLInputElement>();

    useEffect(() => {
        dispatch(getMarketplaceTags());
    }, [dispatch]);

    if (tags.length === 0) {
        return null;
    }

    return (
        <>
                <div className={styles.headingblock}>
                    <h1 className={styles.heading}>Welcome to Olasio!</h1>
                    <p className={styles.text}>Please tell us which industry describes your organisation.</p>
                </div>
                <div className={styles.body}>
                    <div className={styles.tags}>
                    {tags ? tags.map((tag, i) =>
                        <div
                        key={i} className={`${styles.tile} ${selection === tag ? styles.active : ''}`}
                        onClick={() => {setSelection(tag); onSelect(tag, tagRef.current ? tagRef.current?.value : '');}}
                        >
                            {tag}
                            {selection === tag ?
                            <img src={checkIcon} alt='Selected'></img>
                        : ''}
                        </div>
                    ) : 'Loading...'}
                    <div className={`${styles.tile} ${selection === Other ? styles.active : ''}`}
                    onClick={() => {setSelection(Other); onSelect(Other, tagRef.current ? tagRef.current?.value : '');}}>
                            Other industry
                            {selection === Other ?
                            <img src={checkIcon} alt='Selected'></img>
                        : ''}
                        </div>
                    </div>
                </div>
                {selection === Other ?
                <div className={styles.other}>
                    <div className={styles.othertitle}>Please specify the industry of your business</div>
                    <input
                        className='search-field'
                        type='search'
                        placeholder='Please specify'
                        ref={tagRef}
                        onChange={() => {onSelect(Other, tagRef.current ? tagRef.current?.value : '');}}
                    />
                </div> : ''}
        </>
    );
}
